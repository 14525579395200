import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { moveSelection } from "./moveSelection";
import { logError } from "../../log";
import { requestMoveSelection, selectionMoved } from "../../sagas/actions";

/**
 * Handle requests to move the selection within the focused app based on the
 * given instruction.
 */
export const manageMoveSelection = function* (): SagaIterator<void> {
  yield takeEvery(requestMoveSelection, function* ({ payload: instruction }) {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);
    const pressKeys = ipc?.system?.controls?.pressKeys?.v1;
    const getPlatform = ipc?.system?.process?.getPlatform?.v1;

    if (!ipc || !pressKeys || !getPlatform) {
      yield call(logError, "Ipc not detected");
      yield put(selectionMoved({ success: false }));
      return;
    }

    const nodePlatform: SagaReturnType<typeof getPlatform> = yield call(
      getPlatform
    );
    const platform =
      nodePlatform === "darwin"
        ? "mac"
        : nodePlatform === "win32"
        ? "win"
        : null;

    if (platform !== "mac" && platform !== "win") {
      yield call(logError, "Unsupported platform", nodePlatform);
      yield put(selectionMoved({ success: false }));
      return;
    }

    try {
      yield call(moveSelection, { instruction, platform, pressKeys });
    } catch (error) {
      yield call(
        logError,
        "Unable to move selection:",
        error instanceof Error ? error.message : JSON.stringify(error)
      );
      yield put(selectionMoved({ success: false }));
      return;
    }

    yield put(selectionMoved({ success: true }));
  });
};
