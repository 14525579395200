import type { Hotkey } from "../types/Hotkey";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { requestToggleDictation } from "@talktype/actions";
import { InteractionMethod } from "@talktype/analytics";
import {
  requestToggleInlineStyle,
  requestHistory,
  requestToggleBlock,
  requestAddDocument,
} from "@talktype/editor/src/sagas/actions";
import { openDrawer } from "@talktype/preferences/src/reducers";

import { openPreferences } from "../reducer";

const addDocumentHotkey: Hotkey = {
  type: "add-document",
  match: [
    { metaKey: true, key: "n" },
    { ctrlKey: true, key: "n" },
  ],
  action: requestAddDocument({ interactionMethod: InteractionMethod.hotkey }),
  payload: null,
};

const dictationHotkeys: Hotkey[] = [
  {
    type: "toggle-dictation",
    match: [
      { metaKey: true, shiftKey: true, key: "d" },
      { ctrlKey: true, shiftKey: true, key: "d" },
    ],
    action: requestToggleDictation({
      interactionMethod: InteractionMethod.hotkey,
    }),
    payload: null,
  },
];

const blockHotkeys: Hotkey[] = [
  // Toggle header
  {
    type: "block",
    match: [
      { metaKey: true, key: "h" },
      { ctrlKey: true, key: "h" },
    ],
    action: requestToggleBlock("heading"),
    payload: "heading",
  },
];

const inlineStylingHotkeys: Hotkey[] = [
  // Toggle bold
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "b" },
      { ctrlKey: true, key: "b" },
    ],
    action: requestToggleInlineStyle("bold"),
    payload: "bold",
  },
  // Toggle italic
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "i" },
      { ctrlKey: true, key: "i" },
    ],
    action: requestToggleInlineStyle("italic"),
    payload: "italic",
  },
  // Toggle underline
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "u" },
      { ctrlKey: true, key: "u" },
    ],
    action: requestToggleInlineStyle("underline"),
    payload: "underline",
  },
];

const historyHotkeys: Hotkey[] = [
  // Redo
  {
    type: "history",
    match: [
      { metaKey: true, shiftKey: true, key: "z" },
      { ctrlKey: true, shiftKey: true, key: "z" },
    ],
    action: requestHistory("redo"),
    payload: "redo",
  },
  // Undo
  {
    type: "history",
    match: [
      { metaKey: true, key: "z" },
      { ctrlKey: true, key: "z" },
    ],
    action: requestHistory("undo"),
    payload: "undo",
  },
];

const quickNavHotkeys: Hotkey[] = [
  // Open voice commands
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit1" }],
    action: openDrawer({ drawerPage: "voiceCommands" }),
    payload: "voiceCommands",
  },
  // Open custom words
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit2" }],
    action: openDrawer({ drawerPage: "customWords" }),
    payload: "customWords",
  },
  // Open shortcuts
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit3" }],
    action: openDrawer({ drawerPage: "shortcuts" }),
    payload: "shortcuts",
  },
];

const preferencesHotKey: Hotkey = {
  type: "open-preferences",
  match: [{ metaKey: true, key: "," }],
  action: openPreferences(),
  payload: null,
};

export const supportedHotkeys: Hotkey[] = [
  ...(getAppContext() === "desktop" ? [addDocumentHotkey] : []),
  ...(getPlatform() === "mac" ? [preferencesHotKey] : []),
  ...dictationHotkeys,
  ...blockHotkeys,
  ...inlineStylingHotkeys,
  ...historyHotkeys,
  ...quickNavHotkeys,
];
