import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { CaretDoubleLeft } from "@phosphor-icons/react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { ToggleableButton } from "../../ToggleableButton";

const messages = { visible: ["Close library"] };

export type LibraryCloseButtonProps = {
  onClick: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const LibraryCloseButton = ({
  onClick,
}: LibraryCloseButtonProps): ReactElement => (
  <ToggleableButton
    buttonProps={{
      onClick,
      Icon: CaretDoubleLeft,
      active: false,
      highlight: false,
      className: createSelectorClassName(
        "library-drawer-close-button",
        "interactive"
      ),
    }}
    tooltipProps={{ messages, placement: { x: "center", y: "bottom" } }}
  />
);
