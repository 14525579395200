import type {
  ListingSubSection,
  Section,
  SubSection,
} from "../../listingStructure";

import { createSelector } from "@reduxjs/toolkit";

import { selectSearchResults } from "./selectSearchResults";
import { selectSearchTerm } from "./selectSearchTerm";
import { hasListings, listingStructure } from "../../listingStructure";

const totalListingSubSection = (
  subtotal: number,
  item: ListingSubSection
): number => subtotal + item.listings.length;

const totalSubSection = (subtotal: number, item: SubSection): number =>
  subtotal +
  (hasListings(item)
    ? item.listings.length
    : item.subsections.reduce(totalListingSubSection, 0));

const totalSection = (total: number, section: Section): number =>
  total + section.subsections.reduce(totalSubSection, 0);

/**
 * Select Listing Display
 *
 * Selects the information about how the listing is displayed in the UI,
 * including:
 *
 * - The listing itself
 * - Whether the user is currently searching
 * - The number of results
 * - The search term
 */
export const selectListingDisplay = createSelector(
  [selectSearchResults, selectSearchTerm],
  (results, searchTerm) => {
    const notSearching = searchTerm.length < 3 && results === null;

    return {
      listing: notSearching ? listingStructure : results,
      isSearching: !notSearching,
      resultCount: results === null ? 0 : results.reduce(totalSection, 0),
      searchTerm,
    };
  }
);
