import type {
  DispatchProps,
  StateProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { MainMenuProps } from "@talktype/ui/src/MainMenu";

import { connect } from "react-redux";

import { requestInputDevices } from "@carescribe/audio/src/sagas/actions";
import { noOp } from "@carescribe/utilities/src/fp";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectChameleonIsEnabled } from "@talktype/feature-flags/src/reducers/selectors";
import { selectOpenPreferences } from "@talktype/hotkeys/src/reducer";
import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { MainMenu as Component } from "@talktype/ui/src/MainMenu";
import { selectMe } from "@talktype/user/src/reducer/selectors/selectMe";
import { selectTalkTypeDashboardUrl } from "@talktype/user/src/reducer/selectors/selectTalkTypeDashboardUrl";
import { requestLogout } from "@talktype/user/src/sagas/actions";

import { AutoDateFormattingField } from "./AutoDateFormattingField";
import { DictationLanguageField } from "./DictationLanguageField";
import { FilterProfanityField } from "./FilterProfanityField";
import { MicrophoneField } from "./MicrophoneField";
import { NumberFormattingField } from "./NumberFormattingField";
import { PinTalkTypeToFrontField } from "./PinTalkTypeToFrontField";
import { ThemeField } from "./ThemeField";
import { VersionDescription } from "./VersionDescription";

const preferencesFields = (
  <>
    <ThemeField />
    <DictationLanguageField />
    <MicrophoneField />
    <AutoDateFormattingField />
    <NumberFormattingField />
    <FilterProfanityField />
    {getAppContext() === "desktop" && <PinTalkTypeToFrontField />}
    <VersionDescription />
  </>
);

const mapStateToProps = (state: RootState): StateProps<MainMenuProps> => ({
  canSubmitFeedback: selectChameleonIsEnabled(state),
  dashboardUrl: selectTalkTypeDashboardUrl(state),
  platform: selectPlatform(state),
  preferencesFields,
  user: selectMe(state),
  triggerPreferences: selectOpenPreferences(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<MainMenuProps> => ({
  logOut: () => dispatch(requestLogout()),
  onOpen: () => dispatch(requestInputDevices()),

  /**
   * A hook for Chameleon to latch onto, so doesn't need
   * to dispatch anything
   */
  submitFeedback: noOp,
});

export const MainMenu = connect(mapStateToProps, mapDispatchToProps)(Component);
