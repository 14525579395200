import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { setSelectedInputDeviceId } from "@carescribe/audio/src/reducer";
import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { requestTrackEvent } from "@talktype/analytics";

import { requestSetSelectedInputDeviceId } from "./actions";

export const setUpMicrophoneSelection = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSetSelectedInputDeviceId,
    function* ({ payload: deviceId }) {
      yield put(broadcast(setSelectedInputDeviceId(deviceId)));

      yield put(
        requestTrackEvent({
          name: "Preferences Updated",
          data: { preferencesProperty: "microphone" },
        })
      );
    }
  );
};
