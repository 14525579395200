import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, race, take } from "redux-saga/effects";

import { transcriberSocketClosed } from "@carescribe/transcriber-connection/src";

import { startDictating, stopDictating } from "@talktype/editor";
import {
  addCustomWord,
  editCustomWord,
  deleteCustomWord,
  setDictationLanguage,
  setAutoPunctuation,
} from "@talktype/preferences";

/**
 * Reconnect on Preferences Change
 *
 * Requests the connection to restart when there are changes to settings that
 * need to be communicated to the transcriber.
 */
export const reconnectOnPreferencesChange = function* (): SagaIterator<void> {
  const settingsChanges = [
    addCustomWord,
    editCustomWord,
    deleteCustomWord,
    setDictationLanguage,
    setAutoPunctuation,
  ];

  yield takeEvery(startDictating, function* () {
    // Race settings changes and disconnection. If the user disconnects, we
    // don't need to do any thing special
    const [stoppedDictating, _]: [
      SagaReturnType<typeof stopDictating> | undefined,
      SagaReturnType<(typeof settingsChanges)[number]> | undefined
    ] = yield race([take(stopDictating), take(settingsChanges)]);

    if (stoppedDictating) {
      // The user stopped dictating, so cease waiting for settings changes and
      // wait for the user to start dictating again
      return;
    }

    // The user has updated a setting while connected, so request for the
    // dictation session to reconnect
    yield put(stopDictating());

    // Verify that the socket has closed. This allows all cleanup to take place
    // and ensures that we don't attempt to have 2 sockets open at once
    yield take(transcriberSocketClosed);

    // Reconnect to the transcriber. This prompts the takeEvery above to
    // restart, so we only need to consider the process of a single change
    yield put(startDictating());
  });
};
