import type { SagaIterator, Task } from "redux-saga";

import {
  all,
  cancel,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  setUpPingPong,
  transcriberSocketInterrupted,
  transcriberSocketMaintained,
} from "@carescribe/transcriber-connection/src";
import { toSeconds } from "@carescribe/utilities/src/timing";

import {
  requestStartMonitoringConnection,
  requestStopMonitoringConnection,
  poorConnectionDetected,
  connectionRestored,
  startedMonitoringConnection,
} from "./actions";

const pingFrequency = toSeconds(0.5);
// In practice this ends up being about 3 seconds after the first failure
const poorConnectionPingFailCriteria = 5;

/**
 * Monitor Connection
 *
 * Sets up a ping pong task that dispatches transcriberSocketInterrupted and
 * transcriberSocketMaintained actions. Responds to those actions by updating
 * state with information about the socket's connection status.
 */
export const monitorConnection = function* (): SagaIterator<void> {
  yield takeEvery(
    transcriberSocketInterrupted,
    function* ({ payload: { fails } }) {
      if (fails < poorConnectionPingFailCriteria) {
        return;
      }

      yield put(poorConnectionDetected());
    }
  );

  yield takeEvery(transcriberSocketMaintained, function* () {
    yield put(connectionRestored());
  });

  yield takeLatest(
    requestStartMonitoringConnection,
    function* (): SagaIterator<void> {
      const task: Task = yield fork(setUpPingPong, {
        frequency: pingFrequency,
        allowedFails: 4,
      });

      yield put(startedMonitoringConnection());

      yield take(requestStopMonitoringConnection);

      yield all([cancel(task), put(connectionRestored())]);
    }
  );
};
