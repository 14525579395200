import type { TimingsTrackingEventData } from "../../types";

import { createSelector } from "@reduxjs/toolkit";

import { formatSecondsInReadableDivisions as format } from "@carescribe/utilities/src/formatSecondsInReadableDivisions";
import { roundToPlaces } from "@carescribe/utilities/src/roundToPlaces";
import { millisecondsToSeconds } from "@carescribe/utilities/src/timing";

import { selectSecondsSinceLastConnection } from "./selectSecondsSinceLastConnection";
import { selectSecondsSinceLastDisconnection } from "./selectSecondsSinceLastDisconnection";
import { selectTotalSecondsSpentDictating } from "./selectTotalSecondsSpentDictating";

export const selectDictationTimings = createSelector(
  [
    selectSecondsSinceLastConnection,
    selectSecondsSinceLastDisconnection,
    selectTotalSecondsSpentDictating,
  ],
  (connection, disconnection, total): TimingsTrackingEventData => {
    const now = millisecondsToSeconds(performance.now());

    return {
      secondsSinceBoot: roundToPlaces(now.magnitude, 2),
      readableTimeSinceBoot: format(now),

      secondsSinceLastConnection: roundToPlaces(connection.magnitude, 2),
      readableTimeSinceLastConnection: format(connection),

      secondsSinceLastDisconnection: roundToPlaces(disconnection.magnitude, 2),
      readableTimeSinceLastDisconnection: format(disconnection),

      totalSecondsSpentDictating: roundToPlaces(total.magnitude, 2),
      readableTotalTimeSpentDictating: format(total),
    };
  }
);
