import type { ReactElement } from "react";

export const SystemPreview = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 112 80">
    <g clipPath="url(#clip0_5346_461)">
      <rect width="112" height="80" fill="#F8F9FC" rx="8"></rect>
      <path
        fill="#F8F9FC"
        d="M0 0h112v8.096H0zM0 8.096h31.036v98.506H0z"
      ></path>
      <path
        fill="#EEEFFF"
        d="M6.072 18.892a4.723 4.723 0 0 1 4.723-4.723h9.446a4.723 4.723 0 0 1 4.723 4.723v6.746a4.723 4.723 0 0 1-4.723 4.723h-9.446a4.723 4.723 0 0 1-4.723-4.723z"
      ></path>
      <path
        stroke="#7A7CFF"
        strokeWidth="1.349"
        d="M6.072 18.892a4.723 4.723 0 0 1 4.723-4.723h9.446a4.723 4.723 0 0 1 4.723 4.723v6.746a4.723 4.723 0 0 1-4.723 4.723h-9.446a4.723 4.723 0 0 1-4.723-4.723z"
      ></path>
      <path
        fill="#596977"
        d="M9.446 19.566c0-1.118.906-2.024 2.024-2.024h8.097a2.024 2.024 0 1 1 0 4.048H11.47a2.024 2.024 0 0 1-2.024-2.024"
        opacity="0.75"
      ></path>
      <path
        fill="#74828D"
        d="M9.446 25.639c0-.746.604-1.35 1.35-1.35h9.445a1.35 1.35 0 0 1 0 2.699h-9.446a1.35 1.35 0 0 1-1.349-1.35"
        opacity="0.75"
      ></path>
      <path
        fill="#fff"
        d="M5.398 39.133a5.4 5.4 0 0 1 5.397-5.398h9.446a5.4 5.4 0 0 1 5.398 5.398v6.746a5.4 5.4 0 0 1-5.398 5.398h-9.446a5.4 5.4 0 0 1-5.397-5.398z"
      ></path>
      <path
        fill="#596977"
        d="M9.446 39.807c0-1.118.906-2.024 2.024-2.024h8.096a2.024 2.024 0 0 1 0 4.048H11.47a2.024 2.024 0 0 1-2.024-2.024"
        opacity="0.75"
      ></path>
      <path
        fill="#74828D"
        d="M9.446 45.88c0-.746.604-1.35 1.35-1.35h9.445a1.35 1.35 0 0 1 0 2.699h-9.446a1.35 1.35 0 0 1-1.35-1.35"
        opacity="0.75"
      ></path>
      <g filter="url(#filter0_dd_5346_461)">
        <path
          fill="#fff"
          d="M31.036 12.145a4.05 4.05 0 0 1 4.048-4.049H112V89.06H31.036z"
        ></path>
        <mask id="path-12-inside-1_5346_461" fill="#fff">
          <path d="M31.036 8.096H112V25.64H31.036z"></path>
        </mask>
        <path
          fill="#EAECEE"
          d="M110.651 8.096V25.64h2.698V8.096z"
          mask="url(#path-12-inside-1_5346_461)"
        ></path>
        <path
          fill="#EAECEE"
          d="M39.133 16.868a3.374 3.374 0 0 1 3.373-3.374h58.024a3.374 3.374 0 0 1 0 6.747H42.506a3.374 3.374 0 0 1-3.373-3.373"
          opacity="0.5"
        ></path>
        <path
          fill="#596977"
          d="M39.133 27.663c0-1.118.906-2.024 2.024-2.024h17.542a2.024 2.024 0 1 1 0 4.048H41.157a2.024 2.024 0 0 1-2.024-2.024"
          opacity="0.5"
        ></path>
        <path
          fill="#74828D"
          d="M39.133 33.735c0-.745.604-1.35 1.349-1.35h62.072a1.35 1.35 0 1 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 39.133c0-.746.604-1.35 1.349-1.35h51.277a1.35 1.35 0 0 1 0 2.699H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 48.578c0-.745.604-1.35 1.349-1.35h62.072a1.35 1.35 0 1 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 53.976c0-.745.604-1.35 1.349-1.35h39.325a1.35 1.35 0 0 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35"
          opacity="0.5"
        ></path>
      </g>
      <rect
        width="9.542"
        height="9.542"
        x="65.364"
        y="65.11"
        fill="#595BFF"
        rx="4.771"
      ></rect>
      <rect
        width="8"
        height="8"
        x="66.135"
        y="65.881"
        fill="#595BFF"
        rx="4"
      ></rect>
    </g>
    <rect
      width="110.651"
      height="78.651"
      x="0.675"
      y="0.675"
      stroke="#EAECEE"
      strokeWidth="1.349"
      rx="7.325"
    ></rect>
    <mask
      id="mask0_5346_461"
      width="112"
      height="80"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill="#D9D9D9" d="M0 80 112 0v80z"></path>
    </mask>
    <g mask="url(#mask0_5346_461)">
      <g clipPath="url(#clip1_5346_461)">
        <path
          fill="#21303C"
          d="M0 8a8 8 0 0 1 8-8h96a8 8 0 0 1 8 8v64a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8z"
        ></path>
        <path
          fill="#21303C"
          d="M0 0h112v8.096H0zM0 8.096h31.036v98.506H0z"
        ></path>
        <path
          fill="#31328C"
          d="M6.072 18.892a4.723 4.723 0 0 1 4.723-4.723h9.446a4.723 4.723 0 0 1 4.723 4.723v6.746a4.723 4.723 0 0 1-4.723 4.723h-9.446a4.723 4.723 0 0 1-4.723-4.723z"
        ></path>
        <path
          stroke="#595BFF"
          strokeWidth="1.349"
          d="M6.072 18.892a4.723 4.723 0 0 1 4.723-4.723h9.446a4.723 4.723 0 0 1 4.723 4.723v6.746a4.723 4.723 0 0 1-4.723 4.723h-9.446a4.723 4.723 0 0 1-4.723-4.723z"
        ></path>
        <path
          fill="#BFC5CA"
          d="M9.446 19.566c0-1.118.906-2.024 2.024-2.024h8.097a2.024 2.024 0 1 1 0 4.048H11.47a2.024 2.024 0 0 1-2.024-2.024"
          opacity="0.75"
        ></path>
        <path
          fill="#9FA9B1"
          d="M9.446 25.639c0-.746.604-1.35 1.35-1.35h9.445a1.35 1.35 0 0 1 0 2.699h-9.446a1.35 1.35 0 0 1-1.349-1.35"
          opacity="0.75"
        ></path>
        <path
          fill="#1A252F"
          d="M5.398 39.133a5.4 5.4 0 0 1 5.397-5.398h9.446a5.4 5.4 0 0 1 5.398 5.398v6.746a5.4 5.4 0 0 1-5.398 5.398h-9.446a5.4 5.4 0 0 1-5.397-5.398z"
        ></path>
        <path
          fill="#BFC5CA"
          d="M9.446 39.807c0-1.118.906-2.024 2.024-2.024h8.096a2.024 2.024 0 0 1 0 4.048H11.47a2.024 2.024 0 0 1-2.024-2.024"
          opacity="0.75"
        ></path>
        <path
          fill="#9FA9B1"
          d="M9.446 45.88c0-.746.604-1.35 1.35-1.35h9.445a1.35 1.35 0 0 1 0 2.699h-9.446a1.35 1.35 0 0 1-1.35-1.35"
          opacity="0.75"
        ></path>
        <g filter="url(#filter1_dd_5346_461)">
          <path
            fill="#1A252F"
            d="M31.036 12.145a4.05 4.05 0 0 1 4.048-4.049H112V89.06H31.036z"
          ></path>
          <mask id="path-33-inside-2_5346_461" fill="#fff">
            <path d="M31.036 8.096H112V25.64H31.036z"></path>
          </mask>
          <path
            fill="#2B3E4D"
            d="M110.651 8.096V25.64h2.698V8.096z"
            mask="url(#path-33-inside-2_5346_461)"
          ></path>
          <path
            fill="#2F4455"
            d="M39.133 16.868a3.374 3.374 0 0 1 3.373-3.374h58.024a3.374 3.374 0 0 1 0 6.747H42.506a3.374 3.374 0 0 1-3.373-3.373"
            opacity="0.5"
          ></path>
          <path
            fill="#BFC5CA"
            d="M39.133 27.663c0-1.118.906-2.024 2.024-2.024h17.542a2.024 2.024 0 1 1 0 4.048H41.157a2.024 2.024 0 0 1-2.024-2.024"
            opacity="0.5"
          ></path>
          <path
            fill="#9FA9B1"
            d="M39.133 33.735c0-.745.604-1.35 1.349-1.35h62.072a1.35 1.35 0 1 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 39.133c0-.746.604-1.35 1.349-1.35h51.277a1.35 1.35 0 0 1 0 2.699H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 48.578c0-.745.604-1.35 1.349-1.35h62.072a1.35 1.35 0 1 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35M39.133 53.976c0-.745.604-1.35 1.349-1.35h39.325a1.35 1.35 0 0 1 0 2.7H40.482a1.35 1.35 0 0 1-1.35-1.35"
            opacity="0.5"
          ></path>
        </g>
        <rect
          width="9.542"
          height="9.542"
          x="65.364"
          y="65.11"
          fill="#595BFF"
          rx="4.771"
        ></rect>
        <rect
          width="8"
          height="8"
          x="66.135"
          y="65.881"
          fill="#595BFF"
          rx="4"
        ></rect>
      </g>
      <path
        stroke="#2B3E4D"
        strokeWidth="1.349"
        d="M.675 8A7.325 7.325 0 0 1 8 .675h96A7.325 7.325 0 0 1 111.325 8v64A7.325 7.325 0 0 1 104 79.325H8A7.325 7.325 0 0 1 .675 72z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_5346_461">
        <rect width="112" height="80" fill="#fff" rx="8"></rect>
      </clipPath>
      <clipPath id="clip1_5346_461">
        <path
          fill="#fff"
          d="M0 8a8 8 0 0 1 8-8h96a8 8 0 0 1 8 8v64a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8z"
        ></path>
      </clipPath>
      <filter
        id="filter0_dd_5346_461"
        width="102.964"
        height="102.964"
        x="20.036"
        y="1.096"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5346_461"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="3"
          result="effect2_dropShadow_5346_461"
        ></feMorphology>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="4"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_5346_461"
          result="effect2_dropShadow_5346_461"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_5346_461"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_dd_5346_461"
        width="102.964"
        height="102.964"
        x="20.036"
        y="1.096"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="1"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5346_461"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="3"
          result="effect2_dropShadow_5346_461"
        ></feMorphology>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="4"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_5346_461"
          result="effect2_dropShadow_5346_461"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_5346_461"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);
