import type { Placement } from "@carescribe/ui/src/utils/anchor";
import type { RefObject } from "react";

import {
  useLayoutEffect,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { v4 as uuid } from "uuid";

import { anchor } from "@carescribe/ui/src/utils/anchor";
import { keepInViewport } from "@carescribe/ui/src/utils/keepInViewport";

export type PopOverButtonProps = {
  "aria-controls": string;
  popovertarget: string;
  popovertargetaction: string;
};

type UsePopoverReturn<
  ContainerElement extends Element = Element,
  PopoverElement extends HTMLElement = HTMLElement
> = {
  containerProps: {
    ref: RefObject<ContainerElement>;
    tabIndex: number;
  };
  buttonProps: PopOverButtonProps;
  popoverProps: { ref: RefObject<PopoverElement> };
  isOpen: boolean;
};

/**
 * This hook makes it easy to control an element that is designed to be shown
 * and hidden by clicking or pressing a button.
 *
 * @returns
 *
 * `containerProps`
 * - `ref`: a reference to attach to the container element
 * - `tabIndex`: ensures compatibility with Safari
 *   - Safari ignores `onBlur` events on non-focusable elements. This means
 *     `tabIndex` must be set to either -1 or 0.
 *
 * `buttonProps`
 * - `aria-controls`: the id of the popover element
 * - `popovertarget`: the id of the popover element
 * - `popovertargetaction`: the action to perform on the popover element
 *
 * `popoverProps`
 * - `ref`: a reference to attach to the popover element
 *
 * `isOpen`: a boolean indicating whether the popover is open
 *
 * Reducing jankiness the first time the popover is opened:
 *
 * Popovers are hidden by default using `display: none`. This can result in
 * janky positioning on the first open as it is not possible to adjust the
 * position of the popover prior to it being rendered.
 *
 * Use `visibility: hidden` instead.
 *
 * ```css
 * .popover:not(:popover-open) {
 *   display: initial;
 *   visibility: hidden;
 * }
 * ```
 *
 * @example
 * ```tsx
 * const { containerProps, buttonProps, popoverProps, isOpen } =
 * usePopover({placement: {x: "center", y: "top"}});
 *
 * return (
 *   <ul {...containerProps}>
 *    <li>
 *     <button {...buttonProps}>Toggle Popover</button>
 *     <div {...popoverProps}>
 *       <ul>...</ul>
 *     </div>
 *    </li>
 *   </ul>
 * );
 * ```
 */
export const usePopover = <
  ContainerElement extends Element = HTMLDivElement,
  PopoverElement extends HTMLElement = HTMLDivElement
>({
  placement,
}: {
  placement: Placement;
}): UsePopoverReturn<ContainerElement, PopoverElement> => {
  const id = useRef(uuid());
  const containerRef = useRef<ContainerElement>(null);
  const popoverRef = useRef<PopoverElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const positionTooltip = useCallback((): void => {
    anchor({ anchorRef: containerRef, anchoreeRef: popoverRef, placement });
    keepInViewport(popoverRef);
  }, [placement]);

  const containerProps = useMemo(
    () => ({ ref: containerRef, tabIndex: -1 }),
    []
  );
  const popoverProps = useMemo(
    () => ({
      id: id.current,
      ref: popoverRef,
      popover: "auto",
    }),
    []
  );
  const buttonProps = useMemo(
    () => ({
      "aria-controls": id.current,
      popovertarget: id.current,
      popovertargetaction: "toggle",
    }),
    []
  );

  useEffect(() => {
    const popover = popoverRef.current;

    if (!popover) {
      return;
    }

    const onToggle = (event: Event): void => {
      if (event instanceof ToggleEvent) {
        setIsOpen(event.newState === "open");
        /**
         * For some reason, relying only oon positioning on `beforetoggle`
         * is not sufficient as it can lead to clipping in mobile.
         */
        positionTooltip();
      }
    };

    popover.addEventListener("beforetoggle", positionTooltip);
    popover.addEventListener("toggle", onToggle);
    return () => {
      popover.removeEventListener("beforetoggle", positionTooltip);
      popover.removeEventListener("toggle", onToggle);
    };
  }, [positionTooltip]);

  useLayoutEffect(() => {
    window.addEventListener("resize", positionTooltip);
    return () => {
      window.removeEventListener("resize", positionTooltip);
    };
  }, [positionTooltip]);

  return {
    containerProps,
    popoverProps,
    buttonProps,
    isOpen,
  };
};
