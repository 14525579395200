import type { QuickNavItem } from "@talktype/types";
import type { ReactElement } from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./quickNav.module.scss";
import { ToggleableButton } from "../ToggleableButton";

export type QuickNavProps = {
  items: QuickNavItem[];
};

export const QuickNav = ({ items }: QuickNavProps): ReactElement => (
  <div
    className={classNames(
      styles.container,
      createSelectorClassName("quick-nav", "section")
    )}
  >
    {items.map(({ id, selectorId, messages, Icon, active, onClick }) => (
      <ToggleableButton
        key={id}
        buttonProps={{
          Icon,
          active,
          highlight: false,
          className: createSelectorClassName(selectorId, "interactive"),
          onClick,
        }}
        tooltipProps={{
          messages: { visible: messages({ platform: getPlatform() }) },
          placement: { x: "center", y: "bottom" },
        }}
      />
    ))}
  </div>
);
