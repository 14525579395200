import type { VoiceCommandsEmptyProps } from "./VoiceCommandsEmpty";
import type { VoiceCommandsPopulatedProps } from "./VoiceCommandsPopulated";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { messages } from "./messages";
import { VoiceCommandsEmpty } from "./VoiceCommandsEmpty";
import { VoiceCommandsPopulated } from "./VoiceCommandsPopulated";
import { DrawerHeader } from "../DrawerHeader";

export type VoiceCommandsProps = VoiceCommandsPopulatedProps &
  VoiceCommandsEmptyProps & {
    searchBar: ReactNode;
    onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
  };

export const VoiceCommands = ({
  searchTerm,
  listing,
  searchBar,
  onClose,
  ...props
}: VoiceCommandsProps): ReactElement => (
  <>
    <DrawerHeader title={messages.title} onClose={onClose}>
      {searchBar}
    </DrawerHeader>
    {listing && listing.length > 0 ? (
      <VoiceCommandsPopulated {...props} listing={listing} />
    ) : (
      <VoiceCommandsEmpty searchTerm={searchTerm} />
    )}
  </>
);
