import type { ReactElement, ReactNode } from "react";

import { BookBookmark } from "@phosphor-icons/react";

import { messages } from "./messages";
import { EmptyPageHelp } from "../EmptyPageHelp";
import { FeaturedIcon } from "../FeaturedIcon";

export type CustomWordsEmptyProps = { children: ReactNode };

export const CustomWordsEmpty = ({
  children,
}: CustomWordsEmptyProps): ReactElement => (
  <EmptyPageHelp
    title={messages.title}
    body={messages.body}
    decoration={
      <FeaturedIcon style="brand" weight="light" size="md">
        <BookBookmark />
      </FeaturedIcon>
    }
  >
    {children}
  </EmptyPageHelp>
);
