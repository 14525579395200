import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  clearStream,
  requestBroadcastStream,
  broadcastedStream,
  setStream,
} from "./actions";

export const setUpTrackStream = function* (): SagaIterator<void> {
  let stream: MediaStream | null = null;

  yield takeEvery(setStream, function* ({ payload: newStream }) {
    stream = newStream;
  });

  yield takeEvery(clearStream, function* () {
    stream = null;
  });

  yield takeEvery(requestBroadcastStream, function* () {
    yield put(broadcastedStream(stream));
  });
};
