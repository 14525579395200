import type { ReactElement, ReactNode } from "react";

import { X } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./toast.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { StandardButton } from "../StandardButton";

export type ToastProps = {
  colour: "brand" | "alert" | "destructive";
  hierarchy: "primary" | "secondary";
  icon: ReactNode;
  title: { text: string; visible: boolean };
  description: {
    text: { id: string; content: string; isDataSensitive: boolean }[];
    visible: boolean;
  };
  onClose: (() => void) | null;
};

export const Toast = ({
  colour,
  hierarchy,
  icon,
  title,
  description,
  onClose,
}: ToastProps): ReactElement => (
  <div
    className={classNames(
      styles.container,
      styles[colour],
      styles[hierarchy],
      createSelectorClassName("toast", "element")
    )}
    role="alert"
  >
    {onClose && (
      <StandardButton
        colour={colour}
        hierarchy={hierarchy === "primary" ? "link-invert" : "link"}
        size="xs"
        style="icon-only"
        label={messages.close}
        mainIcon={<X />}
        elementProps={{
          onClick: onClose,
          className: classNames(
            styles.closeButton,
            createSelectorClassName("close", "interactive")
          ),
        }}
      />
    )}

    <div className={styles.titleContainer}>
      <span aria-hidden className={styles.icon}>
        {icon}
      </span>
      {title.visible ? (
        <span className={styles.title}>{title.text}</span>
      ) : (
        <ScreenReaderOnly tag="span">{title.text}</ScreenReaderOnly>
      )}
    </div>

    {description.visible ? (
      <span>
        {description.text.map(({ id, content, isDataSensitive }) => (
          <span
            key={id}
            className={styles.description}
            data-hj-suppress={isDataSensitive}
          >
            {content}
          </span>
        ))}
      </span>
    ) : (
      <ScreenReaderOnly tag="span" data-hj-suppress="true">
        {description.text.map(({ content }) => content).join(" ")}
      </ScreenReaderOnly>
    )}
  </div>
);
