import type { SagaIterator } from "redux-saga";

import { debounce, put } from "redux-saga/effects";

import { editorScrollPositionChange } from "./actions";
import { setScrollPositionForCurrentDocument } from "../reducers";

export const setUpChangeCurrentDocument = function* (): SagaIterator<void> {
  yield debounce(
    200,
    editorScrollPositionChange,
    function* ({ payload: scrollTop }) {
      yield put(setScrollPositionForCurrentDocument(scrollTop));
    }
  );
};
