import type { PayloadAction } from "@reduxjs/toolkit";
import type { InteractionMethod } from "@talktype/analytics";
import type { Preferences } from "@talktype/types";

type OpenLibraryDrawer = (
  state: Preferences,
  action: PayloadAction<{ interactionMethod: InteractionMethod }>
) => void;

export const openLibraryDrawer: OpenLibraryDrawer = (state) => {
  state.isLibraryDrawerOpen = true;
};
