import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./regularLayout.module.scss";

export type RegularLayoutProps = {
  isRounded: boolean;
  prompt: ReactNode;
  windowControlsHeight: "none" | "regular" | "large";
  windowControlsBar: ReactNode;
  header: ReactNode;
  formattingToolbar: ReactNode;
  editorContainer: ReactNode;
  dictationControls: ReactNode;
  drawer: ReactNode;
  library: ReactNode;
  notifications: ReactNode;
  mainMenu: ReactNode;
  withDrawer: boolean;
};

export const RegularLayout = ({
  isRounded,
  prompt,
  windowControlsHeight,
  windowControlsBar,
  header,
  editorContainer,
  formattingToolbar,
  dictationControls,
  drawer,
  library,
  mainMenu,
  notifications,
  withDrawer,
}: RegularLayoutProps): ReactElement => (
  <main
    className={classNames(
      styles.outerContainer,
      createSelectorClassName("dictate-to-talktype", "section")
    )}
    tabIndex={-1}
  >
    {windowControlsBar}

    {prompt}

    <div
      className={classNames(
        styles.innerContainer,
        styles[windowControlsHeight]
      )}
    >
      {mainMenu}

      {library}

      <div
        className={classNames(styles.workspace, [isRounded, styles.rounded])}
      >
        {header}
        <div
          className={classNames(styles.contentArea, [
            withDrawer,
            styles.withDrawer,
          ])}
        >
          {drawer}

          <div className={styles.editorWorkspace}>
            <div className={styles.formattingBar}>{formattingToolbar}</div>

            {editorContainer}

            <div>
              <div className={styles.notifications}>{notifications}</div>
              <div className={styles.dictationControls}>
                {dictationControls}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);
