import type { Icon } from "@phosphor-icons/react";
import type { VoiceCommandListingViewEventTrackingRequest } from "@talktype/analytics/src/types/EventTrackingRequest/VoiceCommandListingViewEventTrackingRequest";
import type {
  ListingStructure,
  ListingSubSection,
  SubSectionWithListings,
} from "@talktype/listing/src/listingStructure";
import type { ReactElement } from "react";

import {
  Compass,
  Laptop,
  Keyboard,
  PencilCircle,
  Files,
} from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { hasListings } from "@talktype/listing/src/listingStructure";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import styles from "./voiceCommandsPopulated.module.scss";
import { Expander } from "../../Expander";
import { Listing } from "../../Listing";

export type VoiceCommandsPopulatedProps = {
  listing: ListingStructure | null;
  isSearching: boolean;
  onClickExpander: (
    data: VoiceCommandListingViewEventTrackingRequest["data"]
  ) => void;
};

const icons: Record<string, Icon> = {
  Keyboard,
  PencilCircle,
  Compass,
  Laptop,
  Files,
};

const getIcon = (icon: string): ReactElement | null => {
  const Component = icon in icons ? icons[icon] : null;

  if (!Component) {
    return null;
  }

  return <Component className={styles.icon} weight="duotone" aria-hidden />;
};

const Item = ({
  item,
}: {
  item: ListingSubSection | SubSectionWithListings;
}): ReactElement => (
  <>
    {item.subtitle && (
      <p className={styles.explainer} aria-hidden>
        {item.subtitle}
      </p>
    )}

    <ul className={styles.list}>
      {item.listings.map((listing) => (
        <li key={listing.id} className={styles.listItem}>
          <Listing
            style="outlined"
            input={listing.input}
            output={listing.output}
          />
        </li>
      ))}
    </ul>
  </>
);

export const VoiceCommandsPopulated = ({
  listing,
  isSearching,
  onClickExpander,
}: VoiceCommandsPopulatedProps): ReactElement => (
  <ul
    className={classNames(
      styles.container,
      createSelectorClassName("voice-commands", "section")
    )}
  >
    {listing &&
      listing.map((section) => (
        <li
          key={section.id}
          className={classNames(
            styles.section,
            createSelectorClassName("section", "section")
          )}
        >
          <h3
            className={classNames(
              styles.title,
              createSelectorClassName("title", "element")
            )}
          >
            {getIcon(section.icon)}
            {section.title}
          </h3>

          <ul className={styles.subsectionList}>
            {section.subsections.map((item) => (
              <li key={item.id} className={styles.subsectionItem}>
                <Expander
                  startExpanded={isSearching}
                  onClick={(event, action): void =>
                    onClickExpander({
                      sectionName: `${section.title} | ${item.title}`,
                      action,
                      interactionMethod: getInteractionMethodFromEvent(event),
                    })
                  }
                  className={styles.button}
                  buttonContents={item.title}
                >
                  <div className={styles.commandList}>
                    {hasListings(item) ? (
                      <Item item={item} />
                    ) : (
                      item.subsections.map((listingSubsection) => (
                        <Item
                          key={listingSubsection.id}
                          item={listingSubsection}
                        />
                      ))
                    )}
                  </div>
                </Expander>
              </li>
            ))}
          </ul>
        </li>
      ))}
  </ul>
);
