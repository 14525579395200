import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

import { setDocumentHistory } from "../../reducers";
import { createEditorHistoryChangeChannel } from "../../utils/createEditorHistoryChangeChannel";
import { editorLoaded } from "../actions";

export const persistHistory = function* (): SagaIterator<void> {
  yield takeLatest(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      const { documentUUID: id } = editor;

      if (!id) {
        return;
      }

      const historyChangeEventChannel: SagaReturnType<
        typeof createEditorHistoryChangeChannel
      > = yield call(createEditorHistoryChangeChannel, editor);

      yield takeEvery(historyChangeEventChannel, function* () {
        /**
         * Saving Slate editor's history without creating a deep copy
         * does not tend to go well. Leading to:
         *
         * - Redux errors relating to Immer's auto-freezing
         * See: https://github.com/reduxjs/redux-toolkit/discussions/1189
         *
         * - SerializableCheck errors
         */
        const history = safeStructuredClone(editor.history);
        yield put(setDocumentHistory({ id, history }));
      });
    }
  );
};
