import type { RefObject } from "react";

export const showPopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.showPopover();
  } catch (error) {
    console.warn("Error showing tooltip", error);
  }
};

export const hidePopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.hidePopover();
  } catch (error) {
    console.warn("Error hiding tooltip", error);
  }
};
