import type { Layout } from "../types/Layout";
import type { SagaIterator } from "redux-saga";

import { call, takeEvery } from "redux-saga/effects";

import { requestLoadWindowLayout } from "./actions";

export const updateHTMLElementClass = (className: Layout): void => {
  document.documentElement.classList.remove("regular", "compact");
  document.documentElement.classList.add(className);
};

/**
 * Applies a class to the root DOM element based on the current window layout:
 *
 * - `compact`
 * - `regular`
 *
 * This allows us to apply styles to elements based on the current layout.
 */
export const manageWindowLayoutClass = function* (): SagaIterator<void> {
  yield takeEvery(requestLoadWindowLayout, function* ({ payload: layout }) {
    yield call(updateHTMLElementClass, layout);
  });
};
