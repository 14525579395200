import type { ReactElement } from "react";

import { MicrophoneSlash } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

export type FailedSystemMicrophoneAccessToastProps = {
  onClick: () => void;
  dismiss: () => void;
};

export const FailedSystemMicrophoneAccessToast = (
  toast: FailedSystemMicrophoneAccessToastProps
): ReactElement => (
  <Alert
    colour="destructive"
    hierarchy="primary"
    format="stacked"
    icon={<MicrophoneSlash />}
    onClose={toast.dismiss}
    label={{ text: "Open system preferences", onClick: toast.onClick }}
    primaryText="Microphone permission required"
    secondaryText="To give microphone access to TalkType, please check your system preferences."
  />
);
