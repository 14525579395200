import type { FormattingButtonProps } from "..";
import type { Item } from "../config";

import { DotsThree } from "@phosphor-icons/react";
import { type MouseEventHandler, type ReactElement } from "react";

import { usePopover } from "@carescribe/ui/src/hooks/usePopover";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./submenu.module.scss";
import { SubmenuItem } from "./SubmenuItem";
import { ToggleableButton } from "../../ToggleableButton";

type SubmenuProps = {
  items: Item[];
  buttonProps: FormattingButtonProps;
  handleOpenSubmenu: MouseEventHandler<HTMLButtonElement>;
};

export const Submenu = ({
  items,
  buttonProps: externalButtonProps,
  handleOpenSubmenu,
}: SubmenuProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps, isOpen } = usePopover({
    placement: { x: "center", y: "bottom" },
  });

  /**
   * Using pointer down instead of click as the click handler interferes with
   * the popover's `popovertargetaction` mechanism.
   */
  const handlePointerDown: MouseEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    if (!isOpen) {
      handleOpenSubmenu(event);
    }
  };

  return (
    <div {...containerProps} className={styles.container}>
      <ToggleableButton
        buttonProps={{
          ...buttonProps,
          Icon: DotsThree,
          className: createSelectorClassName("submenu", "interactive"),
          active: false,
          disabled: false,
          highlight: false,
          onPointerDown: handlePointerDown,
        }}
        tooltipProps={{
          messages: { visible: [messages.tooltip] },
          placement: { x: "center", y: "bottom" },
          disabled: isOpen,
        }}
      />

      <div {...popoverProps} className={styles.popover}>
        {items.map((item) => (
          <div className={styles.item} key={item.id}>
            <SubmenuItem item={item} {...externalButtonProps} />
          </div>
        ))}
      </div>
    </div>
  );
};
