import type { CombinedState } from "../CombinedState";

import { sliceName } from "../";

/**
 * Selects the system from state.
 */
export const selectSystem = (
  state: CombinedState
): CombinedState[typeof sliceName] => state[sliceName];
