import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  audioConnected,
  mediaStreamError,
} from "@carescribe/audio/src/sagas/actions";

import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";

const FAILED_MICROPHONE_ACCESS_TOAST_ID = "failed_microphone_access";

/**
 * Manages the failed microphone access notification.
 *
 * Shows the notification when the microphone stream errors and stops dictating.
 * Dismisses the notification when dictating starts.
 */
export const setUpFailedBrowserMicrophoneAccessNotification =
  function* (): SagaIterator<void> {
    yield takeEvery(mediaStreamError, function* () {
      yield put(
        addToast({
          id: FAILED_MICROPHONE_ACCESS_TOAST_ID,
          type: "failed_browser_microphone_access",
          dismissConfig: { after: null, notBefore: null },
          order: 0,
        })
      );
    });

    yield takeEvery(audioConnected, function* () {
      yield put(dismissToast(FAILED_MICROPHONE_ACCESS_TOAST_ID));
    });
  };
