import type { MeResponse } from "../../types/MeResponse";
import type { CrucialNetworkError } from "@talktype/types/src/CrucialNetworkError";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, call, put, takeEvery } from "redux-saga/effects";
import { match, P } from "ts-pattern";

import { crucialNetworkRequestFailed } from "@talktype/actions";

import { ME_URL } from "../../config/web";
import {
  validUnauthorizedResponse,
  type UnauthorisedErrorResponse,
} from "../../guards/isUnauthorisedResponse";
import { setMe } from "../../reducer";
import { selectMe } from "../../reducer/selectors/selectMe";
import { validMeResponse } from "../../types/MeResponse";
import { logError, logWarning } from "../../utils/log";
import { requestGetMe } from "../actions";
import { authedRequest } from "../utils/authedRequest";

const handleError = ({ error }: { error: CrucialNetworkError }) =>
  function* (): SagaIterator<void> {
    yield put(crucialNetworkRequestFailed(error));
    yield put(setMe(null));
  };

/**
 * Fallback to the stored me if the network request fails.
 */
const handleNetworkError = () =>
  function* (): SagaIterator<void> {
    const me: SagaReturnType<typeof selectMe> = yield select(selectMe);
    yield put(setMe(me));
  };

const handleMeResponse = ({ data }: { data: MeResponse }) =>
  function* (): SagaIterator<void> {
    yield put(
      setMe({
        createdAt: data.created_at,
        email: data.email,
        id: data.id,
        updatedAt: data.updated_at,
        uuid: data.uuid,
        initials: data.simple_initials,
        fullName: data.full_name,
        releaseToggles: data.release_toggles,
        organisation: data.organisation
          ? {
              name: data.organisation.name,
              ssoEnabled: data.organisation.has_sso_configuration,
            }
          : null,
      })
    );
  };

const handleUnauthorized = ({ data }: { data: UnauthorisedErrorResponse }) =>
  function* (): SagaIterator<void> {
    yield put(setMe(null));
    yield put(crucialNetworkRequestFailed("permission-error"));
    yield call(logWarning, data.error, data.status);
  };

const handleUnknown = ({ data }: { data: unknown }) =>
  function* (): SagaIterator<void> {
    yield put(setMe(null));
    yield put(crucialNetworkRequestFailed("me-format-error"));
    yield call(logError, "Unknown ME response format:", data);
  };

/**
 * Set Up Get Me
 *
 * Get the current user's information when requested.
 */
export const setUpGetMe = function* (): SagaIterator<void> {
  yield takeEvery(requestGetMe, function* () {
    const response: SagaReturnType<typeof authedRequest> = yield call(
      authedRequest,
      { pathname: ME_URL }
    );

    yield call(
      match(response)
        .with({ error: "network-error" }, handleNetworkError)
        .with({ error: P.string }, handleError)
        .with({ data: validMeResponse }, handleMeResponse)
        .with({ data: validUnauthorizedResponse }, handleUnauthorized)
        .otherwise(handleUnknown)
    );
  });
};
