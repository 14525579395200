import { Input } from "@talktype/ui/src/Representation/Input";

export const messages = {
  punctuation: {
    title: "Punctuation & special characters",
    basics: { title: "Basics" },
    advanced: { title: "Advanced" },
    brackets: { title: "Brackets" },
    mathematics: { title: "Mathematics" },
    currency: { title: "Currency" },
  },

  textEditing: {
    title: "Text editing",
    formatting: { title: "Formatting" },
    selecting: { title: "Selecting" },
    deleting: { title: "Deleting" },
    lineBreaks: { title: "Line breaks" },
  },

  navigation: {
    title: "Navigation",
    textNavigation: { title: "Text navigation" },
    keyStrokes: { title: "Keystrokes" },
  },

  library: {
    title: "Library",
    documentManagement: { title: "Document management" },
  },

  system: {
    title: "System",
    appCommands: { title: "App commands" },
  },

  replacementPrompt: "While dictating, saying...",
  insertionPrompt: "While dictating, say...",
  commandPrompt: (
    <>
      Say <Input style="outlined">TalkType</Input> then...
    </>
  ),
};
