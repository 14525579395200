import type { ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import style from "./userIcon.module.scss";

type UserIconProps = {
  initials: string;
  size: "small" | "large";
  background: "neutral" | "none";
};

export const UserIcon = ({
  initials,
  size,
  background,
}: UserIconProps): ReactElement => (
  <span
    data-hj-suppress
    aria-hidden
    className={classNames(style.userIcon, style[size], style[background])}
  >
    {initials}
  </span>
);
