import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "pkce",
  initialState,
  reducers,
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const { setPkceCodePair, setTokens, setPkce, addAdditionalParams } =
  slice.actions;
