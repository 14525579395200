import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./drawer.module.scss";

export type DrawerProps = {
  border: "left" | "none";
  children: ReactNode;
  isOpen: boolean;
  label: string;
  selector: string;
  width: "regular" | "large";
};

export const Drawer = ({
  border,
  children,
  isOpen,
  label,
  selector,
  width,
}: DrawerProps): ReactElement => (
  <aside
    className={classNames(
      styles.drawer,
      [isOpen, styles.open],
      styles[width],
      styles[border],
      selector
    )}
    aria-hidden={!isOpen}
    aria-label={label}
  >
    <div className={styles.body}>{children}</div>
  </aside>
);
