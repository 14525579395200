import type { RootState } from "@talktype/store";
import type { RegularLayoutProps } from "@talktype/ui/src/RegularLayout";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectDrawerIsOpen } from "@talktype/preferences/src/reducers/";
import { Header } from "@talktype/ui/src/Header";
import { RegularLayout as Component } from "@talktype/ui/src/RegularLayout";

import { AppControls } from "./AppControls";
import { DictationControls } from "./DictationControls";
import { Drawer } from "./Drawer";
import { EditorContainer } from "./Editor/Container";
import { FormattingToolbar } from "./FormattingToolbar";
import { LibraryDrawer } from "./LibraryDrawer";
import { MainMenu } from "./MainMenu";
import { Notifications } from "./Notifications";
import { PWAInstallPrompt } from "./PWAInstallPrompt";
import { WindowControlsBar } from "./WindowControlsBar";
import { WindowsControls } from "./WindowsControls";
import { selectWindowControlsBarHeight } from "src/versioned/3.4.0/selectWindowControlsBarHeight";

const library = <LibraryDrawer />;
const drawer = <Drawer />;
const prompt = <PWAInstallPrompt />;
const mainMenu = <MainMenu />;
const windowControlsBar = (
  <WindowControlsBar
    colour="neutral"
    controls={<WindowsControls colour="neutral" />}
  />
);
const header = (
  <Header style="default">
    <AppControls />
  </Header>
);
const formattingToolbar = <FormattingToolbar />;
const editorContainer = <EditorContainer />;
const dictationControls = <DictationControls />;
const notifications = <Notifications />;

const mapStateToProps = (state: RootState): RegularLayoutProps => ({
  isRounded: getAppContext() === "desktop",
  library,
  drawer,
  withDrawer: selectDrawerIsOpen(state),
  prompt,
  mainMenu,
  windowControlsHeight: selectWindowControlsBarHeight(state),
  windowControlsBar,
  header,
  formattingToolbar,
  editorContainer,
  dictationControls,
  notifications,
});

export const RegularLayout = connect(mapStateToProps)(Component);
