import type { ReactElement } from "react";

import { SignIn } from "@phosphor-icons/react";

import { Toast } from "../Toast";

export type SSOLoginToastProps = {
  organisation: string;
};

export const SSOLoginToast = ({
  organisation,
}: SSOLoginToastProps): ReactElement => {
  const text = `Logged in with ${organisation}`;

  return (
    <Toast
      colour="brand"
      hierarchy="secondary"
      icon={<SignIn />}
      title={{
        text,
        visible: true,
      }}
      onClose={null}
      description={{ text: [], visible: false }}
    />
  );
};
