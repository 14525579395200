import type { ThemeConfig } from "../../types";
import type { ReactElement } from "react";

import { ThemeOption } from "./ThemeOption";
import style from "./themeSelector.module.scss";

export type ThemeSelectorProps<Theme extends string> = {
  selectedTheme: Theme;
  setTheme: (theme: Theme) => void;
  themes: ThemeConfig<Theme>[];
};

export const ThemeSelector = <Theme extends string>({
  selectedTheme,
  setTheme,
  themes,
}: ThemeSelectorProps<Theme>): ReactElement => (
  <div className={style.themeList}>
    {themes.map((config) => (
      <ThemeOption<Theme>
        key={config.id}
        isSelected={config.id === selectedTheme}
        theme={config}
        onSelectOption={(): void => setTheme(config.id)}
      />
    ))}
  </div>
);
