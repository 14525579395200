import type { ReactElement } from "react";
import type { RenderElementProps } from "slate-react";

import { assertUnreachable } from "@carescribe/utilities/src/types";

import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";

export const RenderElement = ({
  element,
  children,
  attributes,
}: RenderElementProps): ReactElement => {
  switch (element.type) {
    case "heading":
    case "title":
      return <Heading attributes={attributes}>{children}</Heading>;

    case "paragraph":
      return <Paragraph attributes={attributes}>{children}</Paragraph>;

    default:
      return assertUnreachable(element);
  }
};
