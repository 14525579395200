import type {
  StateProps,
  DispatchProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationButtonProps } from "@talktype/ui/src/DictationButton";
import type { MouseEvent, KeyboardEvent } from "react";

import { connect } from "react-redux";

import { requestToggleDictation } from "@talktype/actions";
import { selectDictationStatus } from "@talktype/editor/src/reducers";
import { DictationButton as Component } from "@talktype/ui/src/DictationButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { MiniSoundEqualiser } from "./MiniSoundEqualiser";

const miniSoundEqualiser = <MiniSoundEqualiser />;

const mapStateToProps = (
  state: RootState
): Omit<StateProps<DictationButtonProps>, "disableTooltip"> => ({
  status: selectDictationStatus(state),
  miniSoundEqualiser,
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<DictationButtonProps> => ({
  onClick: (event: MouseEvent | KeyboardEvent) =>
    dispatch(
      requestToggleDictation({
        event,
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const DictationButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
