import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { setLastConnectionTimestampReducer } from "./reducers/setLastConnectionTimestampReducer";
import { setLastDisconnectionTimestampReducer } from "./reducers/setLastDisconnectionTimestampReducer";

const slice = createSlice({
  name: "timings",
  initialState,
  reducers: {
    setLastConnectionTimestamp: setLastConnectionTimestampReducer,
    setLastDisconnectionTimestamp: setLastDisconnectionTimestampReducer,
  },
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const {
  actions: { setLastConnectionTimestamp, setLastDisconnectionTimestamp },
} = slice;
