import type { ReactElement } from "react";

/**
 * This is the same spinner as the one from Phosphor, except each of the spokes
 * is its own path, allowing us to animate each spoke individually.
 */
export const Spinner = ({
  className,
}: {
  className?: string;
}): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.70711 15.2929C8.89464 15.4804 9 15.7348 9 16C9 16.2652 8.89464 16.5196 8.70711 16.7071C8.51957 16.8946 8.26522 17 8 17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16C3 15.7348 3.10536 15.4804 3.29289 15.2929C3.48043 15.1054 3.73478 15 4 15H8C8.26522 15 8.51957 15.1054 8.70711 15.2929Z"
      fill="currentColor"
    />
    <path
      d="M7.5152 7C7.78057 7 8.03506 7.10542 8.2227 7.29306L11.0502 10.1218C11.2298 10.3109 11.3285 10.5626 11.3251 10.8234C11.3218 11.0841 11.2167 11.3333 11.0323 11.5177C10.8479 11.7021 10.5988 11.8072 10.338 11.8105C10.0773 11.8138 9.82552 11.7152 9.63645 11.5356L6.8077 8.70806C6.62006 8.52042 6.51465 8.26592 6.51465 8.00056C6.51465 7.73519 6.62006 7.4807 6.8077 7.29306C6.99535 7.10542 7.24984 7 7.5152 7Z"
      fill="currentColor"
    />
    <path
      d="M17 8V4C17 3.73478 16.8946 3.48043 16.7071 3.29289C16.5196 3.10536 16.2652 3 16 3C15.7348 3 15.4804 3.10536 15.2929 3.29289C15.1054 3.48043 15 3.73478 15 4V8C15 8.26522 15.1054 8.51957 15.2929 8.70711C15.4804 8.89464 15.7348 9 16 9C16.2652 9 16.5196 8.89464 16.7071 8.70711C16.8946 8.51957 17 8.26522 17 8Z"
      fill="currentColor"
    />
    <path
      d="M23.1218 7.27502L20.2931 10.1025C20.1054 10.2902 20 10.5447 20 10.81C20 11.0754 20.1054 11.3299 20.2931 11.5175C20.4807 11.7052 20.7352 11.8106 21.0006 11.8106C21.2659 11.8106 21.5204 11.7052 21.7081 11.5175L24.5356 8.68877C24.7152 8.49971 24.8138 8.24796 24.8105 7.9872C24.8072 7.72644 24.7021 7.47729 24.5177 7.29289C24.3333 7.10849 24.0841 7.00342 23.8234 7.00008C23.5626 6.99674 23.3109 7.0954 23.1218 7.27502Z"
      fill="currentColor"
    />
    <path
      d="M24 15H28C28.2652 15 28.5196 15.1054 28.7071 15.2929C28.8946 15.4804 29 15.7348 29 16C29 16.2652 28.8946 16.5196 28.7071 16.7071C28.5196 16.8946 28.2652 17 28 17H24C23.7348 17 23.4804 16.8946 23.2929 16.7071C23.1054 16.5196 23 16.2652 23 16C23 15.7348 23.1054 15.4804 23.2929 15.2929C23.4804 15.1054 23.7348 15 24 15Z"
      fill="currentColor"
    />
    <path
      d="M20.9872 20.675C21.248 20.6717 21.4997 20.7703 21.6888 20.9499L24.5175 23.7774C24.7052 23.9651 24.8106 24.2196 24.8106 24.4849C24.8106 24.7503 24.7052 25.0048 24.5175 25.1924C24.3299 25.3801 24.0754 25.4855 23.81 25.4855C23.5447 25.4855 23.2902 25.3801 23.1025 25.1924L20.275 22.3637C20.0954 22.1746 19.9967 21.9229 20.0001 21.6621C20.0034 21.4014 20.1085 21.1522 20.2929 20.9678C20.4773 20.7834 20.7264 20.6783 20.9872 20.675Z"
      fill="currentColor"
    />
    <path
      d="M15.2929 23.2929C15.4804 23.1054 15.7348 23 16 23C16.2652 23 16.5196 23.1054 16.7071 23.2929C16.8946 23.4804 17 23.7348 17 24V28C17 28.2652 16.8946 28.5196 16.7071 28.7071C16.5196 28.8946 16.2652 29 16 29C15.7348 29 15.4804 28.8946 15.2929 28.7071C15.1054 28.5196 15 28.2652 15 28V24C15 23.7348 15.1054 23.4804 15.2929 23.2929Z"
      fill="currentColor"
    />
    <path
      d="M9.63627 20.95L6.80752 23.7775C6.61988 23.9652 6.51447 24.2196 6.51447 24.485C6.51447 24.7504 6.61988 25.0049 6.80752 25.1925C6.99516 25.3801 7.24966 25.4856 7.51502 25.4856C7.78039 25.4856 8.03488 25.3801 8.22252 25.1925L11.05 22.3638C11.2296 22.1747 11.3283 21.9229 11.325 21.6622C11.3216 21.4014 11.2166 21.1523 11.0321 20.9679C10.8477 20.7835 10.5986 20.6784 10.3378 20.6751C10.0771 20.6717 9.82533 20.7704 9.63627 20.95Z"
      fill="currentColor"
    />
  </svg>
);
