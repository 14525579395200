import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

/**
 * Subscribes to a stream's audio volume.
 *
 * @returns a method to unsubscribe.
 */
export const subscribeToVolume = ({
  stream,
  monitorInterval,
  onVolumeChange,
}: {
  stream: MediaStream;
  monitorInterval: number;
  onVolumeChange: (volume: number) => void;
}): (() => void) => {
  const audioContext = new AudioContext();
  const analyserNode = audioContext.createAnalyser();
  analyserNode.smoothingTimeConstant = 1;

  const gainNode = audioContext.createGain();
  const pcmData = new Float32Array(analyserNode.fftSize);

  const mediaStreamAudioSourceNode =
    audioContext.createMediaStreamSource(stream);

  mediaStreamAudioSourceNode.connect(gainNode).connect(analyserNode);

  let timeoutId: ReturnType<typeof setTimeout> | string = "";

  const updateVolume = (): void => {
    analyserNode.getFloatTimeDomainData(pcmData);

    let sumSquares = 0.0;
    for (const amplitude of pcmData) {
      sumSquares += amplitude * amplitude;
    }
    const rms = Math.sqrt(sumSquares / pcmData.length) * 1000;
    const volumeLevel = Math.round(rms);
    onVolumeChange(volumeLevel);

    timeoutId = setTimeout(updateVolume, monitorInterval);
  };

  timeoutId = setTimeout(updateVolume, monitorInterval);

  return () => {
    clearTimeout(timeoutId);
    if (audioContext.state !== "closed") {
      audioContext.close();
    }
  };
};

/**
 * Creates an event channel that subscribes to a MediaStream's audio
 * volume.
 */
export const createVolumeEventChannel = ({
  stream,
  monitorInterval,
}: {
  stream: MediaStream;
  monitorInterval: number;
}): EventChannel<number> =>
  eventChannel((emit) => {
    const unsubscribe = subscribeToVolume({
      stream,
      monitorInterval,
      onVolumeChange: emit,
    });

    return unsubscribe;
  });
