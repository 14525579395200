import type { EditorType } from "@talktype/editor/src/editor";
import type {
  DocumentFontFamily,
  DocumentFontSize,
  Heading,
  Leaf,
  Paragraph,
} from "@talktype/types";
import type {
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
} from "react";

import { useEffect, useMemo, useCallback } from "react";
import { Editable, Slate } from "slate-react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { RenderElement } from "@talktype/ui/src/Editor/RenderElement";
import { RenderLeaf } from "@talktype/ui/src/Editor/RenderLeaf";

import styles from "./editor.module.scss";
import { messages } from "./messages";

export type EditorProps = {
  documentUUID: EditorType["documentUUID"];
  initialValue: (Paragraph | Heading | Leaf)[];
  inProgress: boolean;
  style: { fontFamily: DocumentFontFamily; fontSize: DocumentFontSize };
  createEditor: (documentUUID: EditorType["documentUUID"]) => EditorType;
  onSelectionChange: (
    change: Pick<EditorType, "documentUUID" | "selection">
  ) => void;
  onChange: (operations: EditorType["operations"]) => void;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  onCopy: (event: ClipboardEvent) => void;
  onCut: (event: ClipboardEvent) => void;
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp: (event: KeyboardEvent<HTMLDivElement>) => void;
  onLoad: (editor: EditorType) => void;
  onPaste: (event: ClipboardEvent<HTMLDivElement>) => void;
  onUnload: () => void;
};

export const Editor = ({
  documentUUID,
  initialValue,
  inProgress,
  style,
  createEditor,
  onChange,
  onClick,
  onCopy,
  onCut,
  onKeyDown,
  onKeyUp,
  onLoad,
  onPaste,
  onUnload,
  onSelectionChange,
}: EditorProps): ReactElement => {
  const editor = useMemo(
    () => createEditor(documentUUID),
    [createEditor, documentUUID]
  );

  useEffect(() => {
    onLoad(editor);
    return onUnload;
  }, [editor, onLoad, onUnload]);

  const handleOnChange = useCallback(() => {
    onChange(editor.operations);
  }, [editor.operations, onChange]);

  const handleSelectionChange = useCallback(
    (selection: EditorType["selection"]): void => {
      onSelectionChange({
        documentUUID,
        selection,
      });
    },
    [onSelectionChange, documentUUID]
  );

  return (
    <Slate
      key={documentUUID}
      editor={editor}
      initialValue={initialValue}
      onChange={handleOnChange}
      onSelectionChange={handleSelectionChange}
    >
      <style>
        {`.${styles.editor} {
          --editor-font-family: "${style.fontFamily}";
          --editor-font-size: ${style.fontSize};
          }`}
      </style>
      <Editable
        tabIndex={0}
        data-hj-suppress
        aria-label={messages.label}
        className={classNames(
          styles.editor,
          createSelectorClassName("editor", "interactive"),
          [inProgress, styles.inProgress]
        )}
        renderElement={RenderElement}
        renderLeaf={RenderLeaf}
        onMouseDown={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onCut={onCut}
        onCopy={onCopy}
        onPaste={onPaste}
      />
    </Slate>
  );
};
