import { createSelector } from "@reduxjs/toolkit";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectParsedAppVersion } from "@talktype/system/src/reducers/system/selectors/selectParsedAppVersion";
import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";

/**
 * Older macOS versions of TalkType had traffic lights configured to render
 * lower, meaning that the app controls needed to be larger to accommodate
 * them.
 */
export const selectWindowControlsBarHeight = createSelector(
  selectPlatform,
  selectParsedAppVersion,
  (platform, version) => {
    if (getAppContext() !== "desktop") {
      return "none";
    }

    if (version === null) {
      return "large";
    }

    const supportsRegularHeight =
      platform === "win" || (version?.major >= 3 && version?.minor >= 4);

    return supportsRegularHeight ? "regular" : "large";
  }
);
