import type { ReactElement } from "react";

import styles from "./miniSoundEqualiser.module.scss";

export type MiniSoundEqualiserProps = {
  bars: { id: string; height: string }[];
};

export const MiniSoundEqualiser = ({
  bars,
}: MiniSoundEqualiserProps): ReactElement => (
  <div className={styles.container}>
    {bars.map((bar) => (
      <div key={bar.id} className={styles.bar} style={{ height: bar.height }} />
    ))}
  </div>
);
