import type { Toast as ToastType } from "@talktype/types/src/Toast";
import type { ReactElement } from "react";

import { assertUnreachable } from "@carescribe/utilities/src/types";

import { DictationToast } from "@talktype/ui/src/DictationToast";
import { FailedBrowserMicrophoneAccessToast } from "@talktype/ui/src/FailedBrowserMicrophoneAccessToast";
import { FailedConnectionToast } from "@talktype/ui/src/FailedConnectionToast";
import { InvalidInstallationToast } from "@talktype/ui/src/InvalidInstallationToast";
import { PoorConnectionToast } from "@talktype/ui/src/PoorConnectionToast";
import { SSOLoginToast } from "@talktype/ui/src/SSOLoginToast";

import { FailedSystemMicrophoneAccessToast } from "./FailedSystemMicrophoneAccessToast";
import { UpdateAvailableToast } from "./UpdateAvailableToast";
import { UpdateErrorToast } from "./UpdateErrorToast";

export const Toast = (toast: ToastType): ReactElement => {
  switch (toast.type) {
    case "dictation":
      return <DictationToast {...toast} />;
    case "poor_connection":
      return <PoorConnectionToast />;
    case "update_available":
      return <UpdateAvailableToast {...toast} />;
    case "update_error":
      return <UpdateErrorToast {...toast} />;
    case "invalid_installation":
      return <InvalidInstallationToast {...toast} />;
    case "failed_connection":
      return <FailedConnectionToast {...toast} />;
    case "failed_browser_microphone_access":
      return <FailedBrowserMicrophoneAccessToast {...toast} />;
    case "failed_system_microphone_access":
      return <FailedSystemMicrophoneAccessToast {...toast} />;
    case "sso_login":
      return <SSOLoginToast {...toast} />;
    default:
      return assertUnreachable(toast);
  }
};
