import type {
  ListingStructure,
  ListingSubSection,
  Section,
  SubSection,
} from "../../listingStructure";
import type { Listing } from "@talktype/types/src/Listing";

import { createSelector } from "@reduxjs/toolkit";

import { escapeStringRegexp } from "@carescribe/utilities/src/escapeStringRegexp";

import { selectSearchTerm } from "./selectSearchTerm";
import { hasListings, listingStructure } from "../../listingStructure";

const LIMIT_FOR_SECTION = 4;
const LIMIT_FOR_SUBSECTION = 3;
const LIMIT_FOR_FUZZY_MATCH = 4;
const LIMIT_FOR_EXACT_MATCH = 2;

const inputsMatch = (inputs: string[], searchTerm: string): boolean =>
  searchTerm.length >= LIMIT_FOR_EXACT_MATCH &&
  inputs.some((input) => input.toLowerCase().includes(searchTerm));

const matchersMatch = (matchers: string[], searchTerm: string): boolean =>
  searchTerm.length >= LIMIT_FOR_EXACT_MATCH &&
  matchers.some((matcher) => matcher.toLowerCase().includes(searchTerm));

const outputMatches = (
  output: { value: string; pronunciation: string } | null,
  searchTerm: string
): boolean =>
  (output && output.value.toLowerCase().includes(searchTerm)) ?? false;

const listingMatchesTerm = (listing: Listing, searchTerm: string): boolean =>
  inputsMatch(listing.input, searchTerm) ||
  matchersMatch(listing.matchers, searchTerm) ||
  outputMatches(listing.output ?? null, searchTerm) ||
  (searchTerm.length > LIMIT_FOR_FUZZY_MATCH &&
    detectFuzzyMatch(listing, searchTerm));

/**
 * Detect Fuzzy Match
 *
 * Verify whether a listing contains a fuzzy match for a search term.
 */
export const detectFuzzyMatch = (
  listing: Listing,
  searchTerm: string
): boolean => {
  const terms = [
    ...listing.matchers,
    ...listing.input,
    ...(listing.output ? [listing.output.value] : []),
  ];

  const regex = new RegExp(
    searchTerm.replace(
      /./g,
      (character) => `(${escapeStringRegexp(character)}.*?)`
    ),
    "i"
  );

  return terms.some((value) => {
    const hasMatch = regex.test(value);
    if (!hasMatch) {
      return false;
    }

    const matches = value.split(regex).filter((value) => value.length === 1);

    return matches.length > LIMIT_FOR_FUZZY_MATCH;
  });
};

/**
 * Search Listing
 *
 * Selects the search results based on the search term.
 *
 * Matches section titles and matchers if the term is long enough.
 * Matches matchers fuzzily if the term is long enough.
 * Matches output at any length.
 */
export const searchListing = (searchTerm: string): ListingStructure | null => {
  if (searchTerm === "") {
    return null;
  }

  const matchesTitle = (
    searchTermMinimumLength: number,
    section: { title: string }
  ): boolean =>
    searchTerm.length >= searchTermMinimumLength &&
    section.title.toLowerCase().includes(searchTerm);

  const filterListings = (listings: Listing[]): Listing[] =>
    listings.filter((listing) => listingMatchesTerm(listing, searchTerm));

  const filterListingSubSections = (
    subsections: ListingSubSection[],
    subsection: ListingSubSection
  ): ListingSubSection[] => {
    const filteredListings = filterListings(subsection.listings);
    return filteredListings.length === 0
      ? subsections
      : subsections.concat({
          ...subsection,
          listings: filteredListings,
        });
  };

  const filterSubSections = (
    results: SubSection[],
    subsection: SubSection
  ): SubSection[] => {
    if (matchesTitle(LIMIT_FOR_SUBSECTION, subsection)) {
      return results.concat(subsection);
    }

    if (hasListings(subsection)) {
      const filteredListings = filterListings(subsection.listings);

      return filteredListings.length === 0
        ? results
        : results.concat({
            ...subsection,
            listings: filteredListings,
          });
    }

    const filteredSubSections = subsection.subsections.reduce(
      filterListingSubSections,
      []
    );

    return filteredSubSections.length === 0
      ? results
      : results.concat({
          ...subsection,
          subsections: filteredSubSections,
        });
  };

  const filterSections = (
    results: ListingStructure,
    section: Section
  ): ListingStructure => {
    if (matchesTitle(LIMIT_FOR_SECTION, section)) {
      return results.concat(section);
    }

    const subsections = section.subsections.reduce(filterSubSections, []);

    return subsections.length === 0
      ? results
      : results.concat({
          ...section,
          subsections: section.subsections.reduce(filterSubSections, []),
        });
  };

  return listingStructure.reduce(filterSections, []);
};

export const selectSearchResults = createSelector(
  [selectSearchTerm],
  searchListing
);
