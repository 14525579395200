import type { CommandCreator } from "../../../types";

import { put } from "redux-saga/effects";

import { InteractionMethod } from "@talktype/analytics";
import { requestAddDocument } from "@talktype/editor/src/sagas/actions";

export const newDocument: CommandCreator<void> = () =>
  function* () {
    yield put(
      requestAddDocument({ interactionMethod: InteractionMethod.voice })
    );

    return true;
  };
