import type { Descendant, Editor } from "slate";

import { isMatching } from "ts-pattern";

import { documentIsEmpty as slateDocumentIsEmpty } from "@carescribe/slate";
import { headDef } from "@carescribe/utilities/src/fp";

import { validHeading } from "@talktype/store/src/persistence/versioned/v1/guards";

import { isEmptyText } from "../guards/isText";

const isEmptyHeading = (maybeHeading: Descendant | null): boolean =>
  // needs to be a heading
  isMatching(validHeading, maybeHeading) &&
  // needs to have empty text
  isEmptyText(headDef(maybeHeading.children, null));

const isNewDocument = ({ children }: Pick<Editor, "children">): boolean => {
  if (children.length !== 1) {
    return false;
  }

  return isEmptyHeading(headDef(children, null));
};

export const documentIsEmpty = (document: Pick<Editor, "children">): boolean =>
  isNewDocument(document) || slateDocumentIsEmpty(document);
