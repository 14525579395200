import type { SagaIterator } from "redux-saga";

import { all, call } from "redux-saga/effects";

import { enablePIIDataScrubbing } from "@carescribe/slate";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpBlocks } from "./blocks/setUpBlocks";
import { setUpClear } from "./clear/setUpClear";
import { setUpCopy } from "./copy/setUpCopy";
import { setUpCursor } from "./cursor";
import { setUpDeletion } from "./deletion";
import { setUpFocus } from "./focus";
import { setUpFormattingBar } from "./formattingBar";
import { setUpHistory } from "./history";
import { setUpInlineStyling } from "./inlineStyling";
import { setUpInsertLineBreak } from "./insertion/setUpInsertLineBreak";
import { setUpPressEnter } from "./insertion/setUpPressEnter";
import { setUpLibrary } from "./library/setUpLibrary";
import { manageDictationModeButtons } from "./manageDictationModeButtons";
import { setUpNormalizers } from "./normalizers";
import { setUpPersistentDocument } from "./persistentDocument";
import { setUpResults } from "./results";
import { setUpScroll } from "./scroll";
import { setUpSelection } from "./selection";
import { setUpChangeCurrentDocument } from "./setUpChangeCurrentDocument";
import { setUpInitialState } from "./setUpInitialState";
import { trackClipboardEvents } from "./trackClipboardEvents";
import { trackSplitEvents } from "./trackSplitEvents";
import { trackTextEvents } from "./trackTextEvents";
import { trackEditor } from "./utils/trackEditor";

export const setUpEditor = function* (): SagaIterator<void> {
  yield call(setUpInitialState);

  yield all(
    [
      enablePIIDataScrubbing,
      manageDictationModeButtons,
      setUpBlocks,
      setUpChangeCurrentDocument,
      setUpClear,
      setUpCopy,
      setUpCursor,
      setUpDeletion,
      setUpFocus,
      setUpFormattingBar,
      setUpHistory,
      setUpInlineStyling,
      setUpInsertLineBreak,
      setUpLibrary,
      setUpNormalizers,
      setUpPersistentDocument,
      setUpPressEnter,
      setUpResults,
      setUpScroll,
      setUpSelection,
      trackClipboardEvents,
      trackEditor,
      trackSplitEvents,
      trackTextEvents,
    ].map(retry)
  );
};
