import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, take, call, select } from "redux-saga/effects";

import { isSafari } from "@carescribe/utilities/src/browser";

import {
  requestInputDevices,
  requestPromptMicrophonePermissions,
  promptedMicrophonePermissions,
  requestedInputDevices,
} from "./actions";
import { selectPermissionState } from "../reducer";

/**
 * Handles request for the browser to prompt the user for microphone
 * permissions, concluding when the user has granted or denied permissions.
 *
 * Prompting is skipped for Safari in cases where permissions have already
 * been granted. This is to avoid flashing of the recording indicator found in
 * the macOS menu bar.
 */
export const setUpRequestMicrophonePermissions =
  function* (): SagaIterator<void> {
    yield takeEvery(
      requestPromptMicrophonePermissions,
      function* (): SagaIterator<void> {
        const permissionState: SagaReturnType<typeof selectPermissionState> =
          yield select(selectPermissionState);

        const isSafariBrowser: SagaReturnType<typeof isSafari> = yield call(
          isSafari
        );

        const skipPrompt = isSafariBrowser && permissionState === "granted";
        if (skipPrompt) {
          yield put(promptedMicrophonePermissions());
          return;
        }

        yield put(requestInputDevices());
        yield take(requestedInputDevices);

        yield put(promptedMicrophonePermissions());
      }
    );
  };
