import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, call } from "redux-saga/effects";

import {
  transcriberSocketClosed,
  transcriberSocketConnected,
} from "@carescribe/transcriber-connection/src";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { requestConnectAudio, requestDisconnectAudio } from "./actions";

export const SEND_MEDIA_FREQUENCY = toSeconds(0.05); // 50ms

/**
 * Higher-order function to take a socket and return a listener function
 * that can be given to a MediaRecorder so that it streams down the socket
 */
export const mediaRecorderToSocket =
  (socket: WebSocket) =>
  ({ data }: { data: Blob }): void => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(data);
    }
  };

/**
 * Set Up Send Audio Over Socket
 *
 * - Listens for every time a new transcriber socket is connected and
 *   configures the audio saga to pass audio data into it
 * - Requests the audio to disconnect when the socket closes
 */
export const setUpSendAudioOverSocket = function* (): SagaIterator<void> {
  yield takeEvery(transcriberSocketConnected, function* ({ payload: socket }) {
    const listener: SagaReturnType<typeof mediaRecorderToSocket> = yield call(
      mediaRecorderToSocket,
      socket
    );
    yield put(requestConnectAudio(listener));
  });

  yield takeEvery(transcriberSocketClosed, function* () {
    yield put(requestDisconnectAudio());
  });
};
