import type { SagaIterator, Task } from "redux-saga";

import {
  put,
  delay,
  race,
  take,
  fork,
  cancel,
  takeEvery,
} from "redux-saga/effects";

import {
  requestCloseCurrentWebSocket,
  requestNewTranscriberSocket,
  transcriberSocketClosedEnableAutoReconnection,
  transcriberSocketCreated,
  wsError,
} from "@carescribe/transcriber-connection/src";
import {
  secondsToMilliseconds,
  toSeconds,
} from "@carescribe/utilities/src/timing";

import {
  requestStartAutoReconnect,
  requestStopAutoReconnect,
  startedAutoReconnect,
} from "./actions";

const RECONNECTION_TIMEOUT = toSeconds(1);

/**
 * Auto Reconnect
 *
 * For each socket that is created, listens for errors or reconnection prompts
 * and requests a new socket. Configured to wait for RECONNECTION_TIMEOUT
 * seconds in order to avoid very fast reconnection attempts
 */
export const autoReconnect = function* (config: {
  url: string;
}): SagaIterator<void> {
  yield takeEvery(transcriberSocketCreated, function* (): SagaIterator<void> {
    yield race([
      // Wait for there to be an error
      take(wsError),
      // Or for the transcriber to signal that a reconnect is needed
      take(transcriberSocketClosedEnableAutoReconnection),
    ]);

    const [reconnect]: [true | undefined] = yield race([
      delay(secondsToMilliseconds(RECONNECTION_TIMEOUT)),
      take(requestCloseCurrentWebSocket),
    ]);

    if (reconnect) {
      yield put(requestNewTranscriberSocket(config));
    }
  });
};

/**
 * Set Up Auto Reconnect
 *
 * Responds to requests to start auto reconnection attempts and cancels the task
 * in response to requests to stop attempting to connect.
 */
export const setUpAutoReconnect = function* (): SagaIterator<void> {
  yield takeEvery(
    requestStartAutoReconnect,
    function* ({ payload: url }): SagaIterator<void> {
      const task: Task = yield fork(autoReconnect, url);

      yield put(startedAutoReconnect());

      yield take(requestStopAutoReconnect);
      yield cancel(task);
    }
  );
};
