import type { Placement } from "@carescribe/ui/src/utils/anchor";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { DotsThree, TrashSimple } from "@phosphor-icons/react";
import { useCallback, useMemo } from "react";

import { usePopover } from "@carescribe/ui/src/hooks/usePopover";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./libraryRowMenu.module.scss";
import { useIsSmallDevice } from "../../hooks/useIsSmallDevice";
import { IconContainer } from "../../IconContainer";
import { ListingButton } from "../../ListingButton";
import { ToggleableButton } from "../../ToggleableButton";

const messages = { visible: ["More"] };

type LibraryRowMenuProps = {
  className?: string;
  tabIndex: number;
  onClickDelete: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const LibraryRowMenu = ({
  className,
  tabIndex,
  onClickDelete,
}: LibraryRowMenuProps): ReactElement => {
  const isSmallDevice = useIsSmallDevice();

  const placement: Placement = useMemo(
    () =>
      isSmallDevice ? { x: "left", y: "center" } : { x: "right", y: "center" },
    [isSmallDevice]
  );

  const { containerProps, buttonProps, popoverProps, isOpen } = usePopover({
    placement,
  });

  const handleClickDelete = useCallback(
    (event: InteractionEvent<HTMLButtonElement>) => {
      onClickDelete(event);
      popoverProps.ref.current?.hidePopover();
    },
    [onClickDelete, popoverProps]
  );

  return (
    <div
      {...containerProps}
      className={classNames(styles.container, className)}
    >
      <ToggleableButton
        buttonProps={{
          ...buttonProps,
          Icon: DotsThree,
          className: createSelectorClassName(
            "library-list-item-menu-button",
            "interactive"
          ),
          active: false,
          highlight: false,
          tabIndex,
        }}
        tooltipProps={{
          messages,
          placement,
          disabled: isOpen,
        }}
      />
      <div {...popoverProps} className={styles.popover}>
        <div className={styles.popoverContents}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <ListingButton
                style="destructive"
                className={createSelectorClassName(
                  "library-delete-document-button",
                  "interactive"
                )}
                onClick={handleClickDelete}
              >
                <span className={styles.listingText}>
                  <IconContainer className={styles.icon}>
                    <TrashSimple />
                  </IconContainer>
                  Delete
                </span>
              </ListingButton>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
