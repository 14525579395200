import type { CommandCreator } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select } from "redux-saga/effects";

import { InteractionMethod } from "@talktype/analytics";
import { selectCurrentDocumentId } from "@talktype/editor/src/reducers";
import { requestDeleteDocument } from "@talktype/editor/src/sagas/actions";

export const deleteDocument: CommandCreator<void> = () =>
  function* () {
    const id: SagaReturnType<typeof selectCurrentDocumentId> = yield select(
      selectCurrentDocumentId
    );

    if (id === null) {
      return false;
    }

    yield put(
      requestDeleteDocument({ id, interactionMethod: InteractionMethod.voice })
    );

    return true;
  };
