import type { SagaIterator } from "redux-saga";

import { all, call, take } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getAppVersion } from "./getAppVersion";
import { getBuildIdFromDOM } from "./getBuildIdFromDOM";
import { getPlatformFromDesktop } from "./getPlatformFromDesktop";
import { listenWindowIsMaximisedChanges } from "./listenWindowIsMaximisedChanges";
import { manageActiveAppTracking } from "./manageActiveAppTracking";
import { manageMotion } from "./manageMotion";
import { manageWindowControls } from "./manageWindowControls";
import { manageWindowLayout } from "./manageWindowLayout";
import { manageWindowLayoutChanges } from "./manageWindowLayoutChanges";
import { manageWindowLayoutClass } from "./manageWindowLayoutClass";
import { registerDictationHotkey } from "./registerDictationHotkey";
import { registerDictationTrayItem } from "./registerDictationTrayItem";
import { registerTray } from "./registerTray";
import { setUpFailedBrowserMicrophoneAccessNotification } from "./setUpFailedBrowserMicrophoneAccessNotification";
import { setUpFocusTalkType } from "./setUpFocusTalkType";
import { setUpOpenHelpCentre } from "./setUpOpenHelpCentre";
import { setUpOpenMicrophonePrivacySettings } from "./setUpOpenMicrophonePrivacySettings";
import { setUpQuitTalkType } from "./setUpQuitTalkType";
import { setBuildId } from "../reducers/system";
import { pinWindowToFront } from "../sagas/pinWindowToFront";

export const setUpSystem = function* (): SagaIterator<void> {
  /**
   * Important to get app version early on to determine which layouts are
   * supported. Otherwise, can result in unpleasant flashing of the default
   * layout.
   */
  yield call(getAppVersion);
  yield all(
    [
      getBuildIdFromDOM,
      getPlatformFromDesktop,
      listenWindowIsMaximisedChanges,
      manageActiveAppTracking,
      manageMotion,
      manageWindowControls,
      manageWindowLayout,
      manageWindowLayoutChanges,
      manageWindowLayoutClass,
      pinWindowToFront,
      registerDictationHotkey,
      registerDictationTrayItem,
      registerTray,
      setUpFailedBrowserMicrophoneAccessNotification,
      setUpFocusTalkType,
      setUpOpenHelpCentre,
      setUpOpenMicrophonePrivacySettings,
      setUpQuitTalkType,
    ].map(retry)
  );
  yield take(setBuildId.type);
};
