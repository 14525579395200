import type {
  DispatchProps,
  StateProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { LibraryProps } from "@talktype/ui/src/Library";

import { connect } from "react-redux";

import {
  selectPreviewableDocuments,
  selectCurrentDocumentId,
  setCurrentDocumentId,
} from "@talktype/editor/src/reducers";
import { requestDeleteDocument } from "@talktype/editor/src/sagas/actions";
import { Library as Component } from "@talktype/ui/src/Library";
import { LibraryHeader } from "@talktype/ui/src/Library/LibraryHeader";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { LibraryCloseButton } from "./LibraryCloseButton";

const header = (
  <LibraryHeader>
    <LibraryCloseButton />
  </LibraryHeader>
);

const mapStateToProps = (state: RootState): StateProps<LibraryProps> => ({
  header,
  documents: selectPreviewableDocuments(state),
  currentDocumentId: selectCurrentDocumentId(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<LibraryProps> => ({
  onChangeDocument: (id) => dispatch(setCurrentDocumentId(id)),
  onDeleteDocument: (id, event) =>
    dispatch(
      requestDeleteDocument({
        id,
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const Library = connect(mapStateToProps, mapDispatchToProps)(Component);
