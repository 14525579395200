import type { Frequencies } from "../types";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

const targetFrequencies = new Map<keyof Frequencies, number>([
  ["subBass", 2],
  ["bass", 5],
  ["lowMids", 10],
  ["mids", 20],
  ["highMids", 40],
  ["presence", 50],
]);

/**
 * Subscribes to a stream's audio frequency levels.
 *
 * @returns a method to unsubscribe.
 */
export const subscribeToFrequencies = ({
  stream,
  monitorInterval,
  onFrequenciesChange,
}: {
  stream: MediaStream;
  monitorInterval: number;
  onFrequenciesChange: (frequencies: Frequencies) => void;
}): (() => void) => {
  const audioContext = new AudioContext();
  const analyserNode = audioContext.createAnalyser();
  const mediaStreamAudioSourceNode =
    audioContext.createMediaStreamSource(stream);

  mediaStreamAudioSourceNode.connect(analyserNode);

  analyserNode.fftSize = 128;
  const frequencyData = new Uint8Array(analyserNode.frequencyBinCount);

  const average = (array: Uint8Array): number =>
    array.reduce((sum, value) => sum + value, 0) / array.length;

  let timeoutId: ReturnType<typeof setTimeout> | string = "";

  const updateFrequencies = (): void => {
    analyserNode.getByteFrequencyData(frequencyData);

    const frequencies: Frequencies = {
      subBass: 0,
      bass: 0,
      lowMids: 0,
      mids: 0,
      highMids: 0,
      presence: 0,
    };
    let start = 0;

    targetFrequencies.forEach((end, label) => {
      frequencies[label] = average(frequencyData.slice(start, end));
      start = end;
    });

    onFrequenciesChange(frequencies);

    timeoutId = setTimeout(updateFrequencies, monitorInterval);
  };

  timeoutId = setTimeout(updateFrequencies, monitorInterval);

  return (): void => {
    clearTimeout(timeoutId);
    if (audioContext.state !== "closed") {
      audioContext.close();
    }
  };
};

/**
 * Creates an event channel that subscribes to a MediaStream's audio
 * frequency levels.
 */
export const createFrequenciesEventChannel = ({
  stream,
  monitorInterval,
}: {
  stream: MediaStream;
  monitorInterval: number;
}): EventChannel<Frequencies> =>
  eventChannel((emit) => {
    const unsubscribe = subscribeToFrequencies({
      stream,
      monitorInterval,
      onFrequenciesChange: emit,
    });

    return unsubscribe;
  });
