import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { finaliseWhenActiveAppChanges } from "./finaliseWhenActiveAppChanges";
import { manageDictateToAppPrecedingText } from "./manageDictateToAppPrecedingText";
import { manageMoveSelection } from "./manageMoveSelection";
import { manageSendResultToApp } from "./manageSendResultToApp";
import { resetDictateToApp } from "./resetDictateToApp";

export const setUpDictateAnywhere = function* (): SagaIterator<void> {
  yield all(
    [
      finaliseWhenActiveAppChanges,
      manageDictateToAppPrecedingText,
      manageMoveSelection,
      manageSendResultToApp,
      resetDictateToApp,
    ].map(retry)
  );
};
