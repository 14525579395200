import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { scan } from "react-scan";

import { setUpMixpanelWithSessionReplay } from "@carescribe/analytics/src/setUpMixpanel";
import { setUpSegment } from "@carescribe/analytics/src/setUpSegment";

import { Provider } from "@talktype/store";

import { App } from "./components/App";
import {
  SEGMENT_WRITE_KEY,
  MIXPANEL_TOKEN,
  MIXPANEL_RECORDING_PERCENTAGE,
} from "./env";
import { rootSaga } from "./sagas";

import "@talktype/ui/src/styles/index.scss";

if (process.env.NODE_ENV === "development") {
  scan({ enabled: !("Cypress" in window) });
}

if (SEGMENT_WRITE_KEY) {
  const segment = setUpSegment(SEGMENT_WRITE_KEY);

  if (MIXPANEL_TOKEN) {
    setUpMixpanelWithSessionReplay(
      {
        token: MIXPANEL_TOKEN,
        recordSessionPercent: MIXPANEL_RECORDING_PERCENTAGE,
      },
      segment
    );
  }
}

const container = document.getElementById("root");
if (!container) {
  throw new Error("No root element found");
}

const root = createRoot(container);

root.render(
  <Provider rootSaga={rootSaga}>
    {/**
     * N.B. wrapping the Provider can have unintended effects, such as
     * effects in sagas running twice in development, causing some features
     * to break!
     */}
    <StrictMode>
      <App />
    </StrictMode>
  </Provider>
);
