import {
  type ReactElement,
  type ReactNode,
  type RefObject,
  type KeyboardEvent,
  useCallback,
} from "react";

import { isSafari } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./modal.module.scss";

export type ModalProps = {
  className?: string;
  children: ReactNode;
  modalRef: RefObject<HTMLDialogElement>;
  onClose?: () => void;
  disableEscapeKeyDismiss?: boolean;
};

export const Modal = ({
  children,
  onClose,
  className,
  modalRef,
  disableEscapeKeyDismiss = false,
}: ModalProps): ReactElement => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDialogElement>): void => {
      if (disableEscapeKeyDismiss && event.key === "Escape") {
        event.preventDefault();
      }
    },
    [disableEscapeKeyDismiss]
  );

  return (
    <dialog
      onClose={onClose}
      onKeyDown={onKeyDown}
      ref={modalRef}
      className={classNames(
        styles.modal,
        [!isSafari(), styles.enableExitTransition],
        className
      )}
    >
      <div>{children}</div>
    </dialog>
  );
};
