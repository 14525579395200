import type { CommandConfig } from "../../../types";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { pressKeys } from "../../../sagas/commands/insertion/pressKeys/pressKeys";
import { newDocument as performCommand } from "../../../sagas/commands/library/newDocument";
import { createCommandDefinition } from "../utils/createCommandDefinition";

export const newDocument: CommandConfig = createCommandDefinition({
  id: "newDocument",
  matchers: ["new document", "create document", "create a document"],
  deadline: toSeconds(0.5),
  messages: {
    successful: "Created a new document",
    failed: "Sorry, couldn't create a new document",
  },
  performCommand: {
    talktype: performCommand(),
    app: pressKeys([
      {
        key: "n",
        ctrlKey: getPlatform() === "win",
        metaKey: getPlatform() === "mac",
      },
    ]),
  },
  input: ["New document"],
  track: null,
});
