import type { RootState } from "./types";

import { combineReducers } from "@reduxjs/toolkit";

import { reducer as audio } from "@carescribe/audio/src/reducer";
import { reducer as pkce } from "@carescribe/pkce/src/reducer";

import { reducer as analytics } from "@talktype/analytics/src/reducer";
import { reducer as editor } from "@talktype/editor";
import { reducer as featureFlags } from "@talktype/feature-flags/src/reducers";
import { reducer as hotkeys } from "@talktype/hotkeys/src/reducer";
import { reducer as listing } from "@talktype/listing/src/reducer";
import { reducer as preferences } from "@talktype/preferences";
import { reducer as pwa } from "@talktype/pwa/src/state";
import { reducer as results } from "@talktype/results/src/state";
import { reducer as system } from "@talktype/system";
import { reducer as toasts } from "@talktype/toasts/src/reducer";
import { reducer as user } from "@talktype/user/src/reducer";

import { replaceState } from "./actions";

export const reducer: typeof reducers = (state, action) => {
  if (replaceState.match(action)) {
    return {
      ...state,
      ...action.payload,
    } as RootState;
  }

  return reducers(state, action);
};

export const getInitialState = (): RootState =>
  // eslint-disable-next-line no-undefined
  reducer(undefined, { type: "@@INIT" });

export const reducers = combineReducers({
  ...audio,
  ...analytics,
  ...editor,
  ...featureFlags,
  ...hotkeys,
  ...listing,
  ...pkce,
  ...preferences,
  ...pwa,
  ...results,
  ...system,
  ...toasts,
  ...user,
});
