import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";

import { requestTrackEvent } from "@talktype/analytics";
import { editorLoaded } from "@talktype/editor";

import { addDocument } from "../../reducers";
import { createDocument } from "../../utils/createDocument";
import { requestAddDocument } from "../actions";

export const setUpAddDocument = function* (): SagaIterator<void> {
  yield takeEvery(
    requestAddDocument,
    function* ({ payload: { interactionMethod } }) {
      const newDocument: SagaReturnType<typeof createDocument> = yield call(
        createDocument
      );
      yield put(addDocument(newDocument));
      yield put(
        requestTrackEvent({
          name: "Document Added",
          data: { interactionMethod },
        })
      );

      // Must wait for the editor to load up before focusing
      const { payload: editor }: SagaReturnType<typeof editorLoaded> =
        yield take(editorLoaded);

      if (editor) {
        yield call(focusEditor, editor);
      }
    }
  );
};
