import type { AppDispatch } from "@talktype/store";
import type { FailedSystemMicrophoneAccessToastProps } from "@talktype/ui/src/FailedSystemMicrophoneAccessToast";

import { connect } from "react-redux";

import { requestOpenMicrophonePrivacySettings } from "@talktype/system";
import { FailedSystemMicrophoneAccessToast as Component } from "@talktype/ui/src/FailedSystemMicrophoneAccessToast";

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<FailedSystemMicrophoneAccessToastProps, "onClick"> => ({
  onClick: () => dispatch(requestOpenMicrophonePrivacySettings()),
});

export const FailedSystemMicrophoneAccessToast = connect(
  null,
  mapDispatchToProps
)(Component);
