import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { openLibraryDrawer, closeLibraryDrawer } from "../reducers/preferences";

export const trackLibraryDrawer = function* (): SagaIterator<void> {
  yield takeEvery(
    openLibraryDrawer,
    function* ({ payload: { interactionMethod } }) {
      yield put(
        requestTrackEvent({
          name: "Library Drawer Interaction",
          data: {
            drawerInteractionType: "opened",
            drawerPageInteractionMethod: interactionMethod,
          },
        })
      );
    }
  );

  yield takeEvery(
    closeLibraryDrawer,
    function* ({ payload: { interactionMethod } }) {
      yield put(
        requestTrackEvent({
          name: "Library Drawer Interaction",
          data: {
            drawerInteractionType: "closed",
            drawerPageInteractionMethod: interactionMethod,
          },
        })
      );
    }
  );
};
