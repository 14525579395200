import type { CombinedState } from "../types/CombinedState";

import { createSelector } from "@reduxjs/toolkit";

import { sliceName } from ".";

export const selectPWAState = (
  state: CombinedState
): CombinedState[typeof sliceName] => state[sliceName];

export const selectCustomPromptDismissedAt = createSelector(
  selectPWAState,
  (state) => state.customPromptDismissedAt
);

export const selectSupportsNativeInstall = createSelector(
  selectPWAState,
  (state) => state.supportsNativeInstall
);

export const selectInstallTarget = createSelector(
  selectPWAState,
  (state) => state.installTarget
);

export const selectIsInstalling = createSelector(
  selectPWAState,
  (state) => state.nativePromptResult?.outcome === "accepted"
);

export const selectIsRunningInPWA = createSelector(
  selectPWAState,
  (state) => state.isRunningInPWA
);
