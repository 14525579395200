import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, take, put } from "redux-saga/effects";

import { clearStream, setStream } from "./actions";
import { setVolume } from "../reducer";
import { createVolumeEventChannel } from "../utils/createVolumeEventChannel";

const monitorInterval = 100;

/**
 * Tracks a stream's audio volume. Clears the volume when the stream closes.
 */
export const setUpTrackVolume = function* (): SagaIterator<void> {
  yield takeEvery(setStream, function* ({ payload: stream }) {
    const volumeChannel: SagaReturnType<typeof createVolumeEventChannel> =
      yield call(createVolumeEventChannel, { stream, monitorInterval });

    yield takeEvery(volumeChannel, function* (volume) {
      yield put(setVolume(volume));
    });

    yield take(clearStream);
    yield call(volumeChannel.close);
    yield put(setVolume(0));
  });
};
