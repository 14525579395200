import type { ListingState } from "../";

import { sliceName } from "../";

type CombinedState = {
  [sliceName]: ListingState;
};

export const selectListingState = (state: CombinedState): ListingState =>
  state[sliceName];
