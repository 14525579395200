import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import {
  all,
  call,
  cancel,
  fork,
  put,
  take,
  takeEvery,
} from "redux-saga/effects";

import {
  audioConnected,
  mediaStreamError,
  promptedMicrophonePermissions,
  requestPromptMicrophonePermissions,
} from "@carescribe/audio/src/sagas/actions";
import {
  requestCloseCurrentWebSocket,
  requestNewTranscriberSocket,
  transcriberSocketCreated,
} from "@carescribe/transcriber-connection/src";
import { assertUnreachable } from "@carescribe/utilities/src/types";

import { requestStopDictating } from "@talktype/actions";
import { startDictating, stopDictating } from "@talktype/editor";
import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";
import {
  receivedReadySignal,
  requestStartAutoReconnect,
  requestStartMonitoringConnection,
  requestStopAutoReconnect,
  requestStopMonitoringConnection,
  startedAutoReconnect,
  startedMonitoringConnection,
} from "@talktype/transcriber/src/sagas/actions";
import { constructTranscriberUrl } from "@talktype/transcriber/src/sagas/utils/constructTranscriberUrl";
import {
  gotDictationSession,
  requestDictationSession,
  requestLogout,
} from "@talktype/user/src/sagas/actions";

const CONNECTION_ERROR_TOAST_ID = "dictation_session_error";
const FAILED_BROWSER_MICROPHONE_ACCESS_TOAST_ID =
  "failed_browser_microphone_access";
const FAILED_SYSTEM_MICROPHONE_ACCESS_TOAST_ID =
  "failed_system_microphone_access";

/**
 * Connect on Dictation Start
 *
 * - Requests a new connection to the transcriber when the user starts dictating
 * - Requests the socket be closed when the user stops dictating
 */
export const connectOnDictationStart = function* (): SagaIterator<void> {
  yield takeEvery(startDictating, function* () {
    const task: Task = yield fork(function* () {
      /**
       * Super important that we first prompt for microphone permissions.
       *
       * Later on in the flow we check for microphone permissions. In the case
       * where the permissions have not yet been granted, an error is shown
       * guiding the user to their system settings to grant permissions.
       *
       * On macOS, only applications which have previously requested microphone
       * permissions will appear in the list. Meaning, if we don't prompt here,
       * the user will not be able to grant permissions to the application.
       */
      yield put(requestPromptMicrophonePermissions());
      yield take(promptedMicrophonePermissions);

      yield put(requestDictationSession());
      const {
        payload: dictationSession,
      }: SagaReturnType<typeof gotDictationSession> = yield take(
        gotDictationSession
      );

      if (dictationSession.error) {
        switch (dictationSession.error) {
          case "connection-error":
            yield put(
              addToast({
                id: CONNECTION_ERROR_TOAST_ID,
                type: "failed_connection",
                dismissConfig: { after: null, notBefore: null },
                order: 0,
              })
            );
            break;
          case "failed-browser-microphone-access":
            yield put(
              addToast({
                id: FAILED_BROWSER_MICROPHONE_ACCESS_TOAST_ID,
                type: "failed_browser_microphone_access",
                dismissConfig: { after: null, notBefore: null },
                order: 0,
              })
            );
            break;
          case "failed-system-microphone-access":
            yield put(
              addToast({
                id: FAILED_SYSTEM_MICROPHONE_ACCESS_TOAST_ID,
                type: "failed_system_microphone_access",
                dismissConfig: { after: null, notBefore: null },
                order: 0,
              })
            );
            break;
          case "permission-error":
          case "unknown-error":
            yield put(requestLogout());
            return;
          default:
            yield call(assertUnreachable, dictationSession);
        }

        yield put(requestStopDictating());
        return;
      }

      yield put(dismissToast(CONNECTION_ERROR_TOAST_ID));
      yield put(dismissToast(FAILED_BROWSER_MICROPHONE_ACCESS_TOAST_ID));
      yield put(dismissToast(FAILED_SYSTEM_MICROPHONE_ACCESS_TOAST_ID));

      const url: SagaReturnType<typeof constructTranscriberUrl> = yield call(
        constructTranscriberUrl,
        dictationSession.url
      );

      yield all([
        put(requestStartAutoReconnect({ url })),
        put(requestNewTranscriberSocket({ url })),
        put(requestStartMonitoringConnection()),
      ]);

      yield all([
        take(startedAutoReconnect),
        take(startedMonitoringConnection),
        take(transcriberSocketCreated),
        take(audioConnected),
        take(receivedReadySignal),
      ]);
    });

    yield take(requestStopDictating);
    yield cancel(task);
  });

  yield takeEvery(mediaStreamError, function* () {
    yield put(requestStopDictating());
  });

  yield takeEvery(stopDictating, function* () {
    yield all([
      put(requestStopAutoReconnect()),
      put(requestCloseCurrentWebSocket()),
      put(requestStopMonitoringConnection()),
    ]);
  });
};
