import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { persistChildren } from "./persistChildren";
import { persistHistory } from "./persistHistory";
import { persistSelection } from "./persistSelection";
import { revalidateDocumentOnError } from "./revalidateDocumentOnError";

export const setUpPersistentDocument = function* (): SagaIterator<void> {
  yield all(
    [
      persistChildren,
      persistHistory,
      persistSelection,
      revalidateDocumentOnError,
    ].map(retry)
  );
};
