import type { StackedDropdownMenuRowProps } from "../StackedDropdownMenuRow";
import type { DictationMode, DictationStatus } from "@talktype/types";
import type { ReactElement, MouseEvent, KeyboardEvent } from "react";

import { Browser } from "@phosphor-icons/react";
import { useMemo } from "react";

import { usePopover } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";

import { MiniPlayerEnterToLeft } from "@talktype/icons/src/MiniPlayerEnterToLeft";

import styles from "./dictationModeMenu.module.scss";
import { messages } from "./messages";
import { DictationModeButton } from "../DictationModeButton";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { StackedDropdownMenuRow } from "../StackedDropdownMenuRow";

type ModeChangeHandler = (payload: {
  event: MouseEvent | KeyboardEvent;
}) => void;

export type DictationModeMenuProps = {
  mode: DictationMode;
  status: DictationStatus;
  disabled: boolean;
  handleChangeToTalkType: ModeChangeHandler;
  handleChangeToAnywhere: ModeChangeHandler;
};

export const DictationModeMenu = ({
  mode,
  status,
  disabled,
  handleChangeToTalkType,
  handleChangeToAnywhere,
}: DictationModeMenuProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps } = usePopover({
    placement: { x: "center", y: "top" },
  });

  const items: ({ id: DictationMode } & StackedDropdownMenuRowProps)[] =
    useMemo(
      () => [
        {
          id: "talktype",
          title: messages.talktype.title,
          description: messages.talktype.description,
          Icon: Browser,
          onClick: (event): void => {
            handleChangeToTalkType({ event });
            popoverProps.ref.current?.hidePopover();
          },
          onKeyDown: (event): void => {
            if (isKeyboardClick(event)) {
              handleChangeToTalkType({ event });
              popoverProps.ref.current?.hidePopover();
            }
          },
        },
        {
          id: "anywhere",
          title: messages.anywhere.title,
          description: messages.anywhere.description,
          Icon: MiniPlayerEnterToLeft,
          onClick: (event): void => {
            handleChangeToAnywhere({ event });
            popoverProps.ref.current?.hidePopover();
          },
          onKeyDown: (event): void => {
            if (isKeyboardClick(event)) {
              handleChangeToAnywhere({ event });
              popoverProps.ref.current?.hidePopover();
            }
          },
        },
      ],
      [popoverProps, handleChangeToTalkType, handleChangeToAnywhere]
    );

  return (
    <div {...containerProps} className={styles.container}>
      <DictationModeButton {...buttonProps} disabled={disabled}>
        <ScreenReaderOnly tag="span">
          {messages.changeDictationMode}
        </ScreenReaderOnly>
        <span aria-hidden>{messages[mode][status].title}</span>
      </DictationModeButton>
      <div
        {...popoverProps}
        className={classNames(
          styles.popover,
          createSelectorClassName("dictation-mode-menu", "section")
        )}
      >
        <ul className={styles.contents}>
          {items.map((item) => (
            <li key={item.id}>
              <StackedDropdownMenuRow {...item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
