import type {
  StateProps,
  DispatchProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { EditorProps } from "@talktype/ui/src/Editor/Editor";

import { connect } from "react-redux";

import {
  createEditor,
  editorChanged,
  editorClicked,
  editorCopy,
  editorCut,
  editorKeyDown,
  editorKeyUp,
  editorLoaded,
  editorPaste,
  editorUnloaded,
  selectEditorInitialValue,
  editorSelectionChanged,
  selectCurrentDocumentId,
} from "@talktype/editor";
import { selectDocumentStyle } from "@talktype/preferences";
import { selectInProgress } from "@talktype/results/src/state/selectors";
import { Editor as Component } from "@talktype/ui/src/Editor/Editor";

const mapStateToProps = (state: RootState): StateProps<EditorProps> => ({
  documentUUID: selectCurrentDocumentId(state),
  initialValue: selectEditorInitialValue(state),
  inProgress: selectInProgress(state),
  style: selectDocumentStyle(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<EditorProps> => ({
  createEditor,

  /**
   * Important for the below handlers to return void as otherwise Slate will
   * think its default behaviour is being overridden if these handlers
   * happen to return something.
   *
   * See {@link https://docs.slatejs.org/libraries/slate-react/event-handling}
   */

  onChange: (operations) => void dispatch(editorChanged(operations)),
  onClick: (event) => void dispatch(editorClicked(event)),
  onCopy: (event) => void dispatch(editorCopy(event)),
  onCut: (event) => void dispatch(editorCut(event)),
  onKeyDown: (event) => void dispatch(editorKeyDown(event)),
  onKeyUp: (event) => void dispatch(editorKeyUp(event)),
  onLoad: (editor) => void dispatch(editorLoaded(editor)),
  onPaste: (event) => void dispatch(editorPaste(event)),
  onUnload: () => void dispatch(editorUnloaded()),
  onSelectionChange: (change) => void dispatch(editorSelectionChanged(change)),
});

export const Editor = connect(mapStateToProps, mapDispatchToProps)(Component);
