import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, take, put } from "redux-saga/effects";

import { clearStream, setStream } from "./actions";
import { resetFrequencies, setFrequencies } from "../reducer";
import { createFrequenciesEventChannel } from "../utils/createFrequenciesEventChannel";

const monitorInterval = 25;

/**
 * Tracks a stream's audio frequencies. Clears the frequencies when the stream
 * closes.
 */
export const setUpTrackFrequencies = function* (): SagaIterator<void> {
  yield takeEvery(setStream, function* ({ payload: stream }) {
    const frequenciesChannel: SagaReturnType<
      typeof createFrequenciesEventChannel
    > = yield call(createFrequenciesEventChannel, { stream, monitorInterval });

    yield takeEvery(frequenciesChannel, function* (frequencies) {
      yield put(setFrequencies(frequencies));
    });

    yield take(clearStream);
    yield call(frequenciesChannel.close);
    yield put(resetFrequencies());
  });
};
