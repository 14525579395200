import type { InteractionEvent } from "@talktype/types";
import type { MouseEventHandler, ReactElement, ReactNode } from "react";

import { CaretDown } from "@phosphor-icons/react";
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./expander.module.scss";

export type ExpanderProps = {
  className?: string;
  buttonContents: ReactNode;
  children: ReactNode;
  onClick: (
    event: InteractionEvent<HTMLButtonElement>,
    action: "opened" | "closed"
  ) => void;
  startExpanded: boolean;
};

export const Expander = ({
  buttonContents,
  children,
  className,
  onClick,
  startExpanded,
}: ExpanderProps): ReactElement => {
  const [expanded, setExpanded] = useState(startExpanded);
  const id = useRef(uuid());

  useEffect(() => {
    setExpanded(startExpanded);
  }, [startExpanded]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event): void => {
    onClick(event, expanded ? "closed" : "opened");
    setExpanded(!expanded);
  };

  return (
    <>
      <button
        className={classNames(
          className,
          expanded ? styles.buttonExpanded : styles.buttonCollapsed,
          createSelectorClassName("expander", "interactive")
        )}
        onClick={handleClick}
        aria-expanded={expanded}
        aria-controls={id.current}
      >
        {buttonContents}

        <CaretDown className={expanded ? styles.arrowInverted : styles.arrow} />
      </button>

      <div
        id={id.current}
        className={classNames(
          expanded ? styles.expandedContent : styles.collapsedContent,
          createSelectorClassName("expander-content", "section")
        )}
      >
        {children}
      </div>
    </>
  );
};
