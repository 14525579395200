import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import {
  setCustomPromptDismissedAt as setCustomPromptDismissedAtReducer,
  setNativeInstallPromptResult as setNativeInstallPromptResultReducer,
  setSupportsNativeInstall as setSupportsNativeInstallReducer,
} from "./reducers";

const slice = createSlice({
  name: "pwa",
  initialState,
  reducers: {
    setCustomPromptDismissedAt: setCustomPromptDismissedAtReducer,
    setNativeInstallPromptResult: setNativeInstallPromptResultReducer,
    setSupportsNativeInstall: setSupportsNativeInstallReducer,
  },
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const {
  setCustomPromptDismissedAt,
  setNativeInstallPromptResult,
  setSupportsNativeInstall,
} = slice.actions;
