import type { EditorType } from "../editor";
import type { EventChannel } from "redux-saga";
import type { Operation } from "slate";

import { eventChannel } from "redux-saga";

import { noOp } from "@carescribe/utilities/src/fp";

export const createEditorChildrenChangeChannel = (
  editor: EditorType
): EventChannel<true> => {
  let { children: previousChildren } = editor;
  const { onChange: originalOnChange } = editor;

  return eventChannel((emit) => {
    editor.onChange = (options?: { operation?: Operation }): void => {
      const { children } = editor;

      const hasChanged = previousChildren !== children;

      if (hasChanged) {
        emit(true);
        previousChildren = children;
      }

      originalOnChange(options);
    };

    return noOp;
  });
};
