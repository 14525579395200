import type { DictationStatus } from "@talktype/types";
import type {
  KeyboardEventHandler,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
  ReactNode,
} from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";

import styles from "./dictationButton.module.scss";
import { messages } from "./messages";
import { StatusIndicator } from "./StatusIndicator";
import { Tooltip } from "../Tooltip";

export type DictationButtonProps = {
  status: DictationStatus;
  miniSoundEqualiser: ReactNode;
  disableTooltip?: boolean;
  onClick: (event: MouseEvent | KeyboardEvent) => void;
};

const style = {
  inactive: styles.notDictating,
  loading: styles.loading,
  active: styles.dictating,
};

export const DictationButton = ({
  status,
  miniSoundEqualiser,
  disableTooltip,
  onClick,
}: DictationButtonProps): ReactElement => {
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (isKeyboardClick(event)) {
      onClick(event);
    }
  };

  const platform = getPlatform();

  return (
    <Tooltip
      messages={{ visible: messages.tooltip({ platform, status }) }}
      placement={{ x: "center", y: "top" }}
      disabled={disableTooltip}
    >
      <button
        data-status={status}
        aria-label={messages.label({ status })}
        className={classNames(
          styles.button,
          style[status],
          createSelectorClassName("dictation-button", "interactive")
        )}
        onMouseDown={onClick}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.inner}>
          <StatusIndicator
            status={status}
            miniSoundEqualiser={miniSoundEqualiser}
          />
        </div>
      </button>
    </Tooltip>
  );
};
