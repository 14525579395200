import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { setDocumentSelection } from "../../reducers";
import { editorSelectionChanged } from "../actions";

export const persistSelection = function* (): SagaIterator<void> {
  yield takeEvery(
    editorSelectionChanged,
    function* ({ payload: { documentUUID: id, selection } }) {
      if (!id) {
        return;
      }

      yield put(setDocumentSelection({ id, selection }));
    }
  );
};
