import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./dictationControls.module.scss";

export type DictationControlsProps = {
  isDesktop: boolean;
  dictationButton: ReactNode;
  dictationModeMenu: ReactNode;
};

export const DictationControls = ({
  isDesktop,
  dictationButton,
  dictationModeMenu,
}: DictationControlsProps): ReactElement => (
  <div
    className={classNames(
      styles.wrapper,
      createSelectorClassName("dictation-controls", "section")
    )}
  >
    {dictationButton}
    {isDesktop && dictationModeMenu}
  </div>
);
