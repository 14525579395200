import type { MicrophoneListener } from "../types";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  requestBroadcastIsRecording,
  broadcastedIsRecording,
  trackStartedRecording,
  trackStoppedRecording,
} from "./actions";

export const setUpTrackIsRecording = function* (): SagaIterator<void> {
  let listener: MicrophoneListener | null = null;

  yield takeEvery(trackStartedRecording, function* ({ payload: newListener }) {
    listener = newListener;
  });

  yield takeEvery(trackStoppedRecording, function* () {
    listener = null;
  });

  yield takeEvery(requestBroadcastIsRecording, function* () {
    yield put(broadcastedIsRecording(listener));
  });
};
