import type { ReactElement, ButtonHTMLAttributes, Ref } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./listingButton.module.scss";

export type ListingButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonRef?: Ref<HTMLButtonElement>;
  style: "neutral" | "destructive";
};

export const ListingButton = ({
  children,
  className,
  style,
  ...props
}: ListingButtonProps): ReactElement => (
  <button
    {...props}
    className={classNames(
      createSelectorClassName("listing-button", "interactive"),
      styles.listingButton,
      style === "destructive" ? styles.destructive : "",
      className
    )}
  >
    {children}
  </button>
);
