import type {
  ReplacementInstruction,
  TranscriptTransformationSettings,
} from "@talktype/types";
import type { Listing } from "@talktype/types/src/Listing";

import { noOp } from "@carescribe/utilities/src/fp";

import { bulletPoint } from "@talktype/transcript-transformation/src/config/punctuation";

import { expectedAutoPunctuation } from "./expectedAutoPunctuation";

/**
 * Adds punctuation in order to account for marks that may be included in the
 * text due to the automatic punctuation sent back from the transcriber.
 *
 * @example
 * const newParagraphWithPunctuation = withPunctuation(["new paragraph"]);
 * // [
 * //   "new paragraph.",
 * //   "new paragraph,",
 * //   "new paragraph!",
 * //   "new paragraph?",
 * //   "new paragraph",
 * // ]
 */
const withPunctuation = (matchers: string[]): string[] =>
  matchers.flatMap((matcher) =>
    expectedAutoPunctuation.map((symbol) => matcher + symbol).concat(matcher)
  );

const bulletPointShortcut: ReplacementInstruction = {
  matchers: withPunctuation(bulletPoint.matchers),
  type: "punctuation",
  segments: [{ type: "linebreak" }, { type: "text", text: "bullet point" }],
  inBuilt: true,
};

export const newLineBreak: ReplacementInstruction & Listing = {
  id: "newLineBreak",
  input: ["New line", "Line break"],
  type: "custom-shortcut",
  segments: [{ type: "linebreak" }],
  inBuilt: true,
  matchers: withPunctuation(["new line", "line break"]),
};

export const newParagraph: ReplacementInstruction & Listing = {
  id: "newParagraph",
  input: ["New paragraph"],
  type: "custom-shortcut",
  segments: [{ type: "paragraph" }],
  inBuilt: true,
  matchers: withPunctuation(["new paragraph"]),
};

export const transformationSettings: TranscriptTransformationSettings = {
  dateFormat: "",
  filterProfanity: false,
  numberFormat: "automatic",
  customShortcuts: [newLineBreak, newParagraph, bulletPointShortcut],
  onReplace: noOp,
};
