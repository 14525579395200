import { createSelector } from "@reduxjs/toolkit";

import { selectAppVersion } from "./selectAppVersion";

export const selectParsedAppVersion = createSelector(
  selectAppVersion,
  (appVersion) => {
    if (!appVersion) {
      return null;
    }

    const [major = "0", minor = "0", patch = "0"] = appVersion.split(".");

    return {
      major: parseInt(major),
      minor: parseInt(minor),
      patch: parseInt(patch),
    };
  }
);
