import { createSelector, createSlice } from "@reduxjs/toolkit";

type State = {
  openPreferences: number;
};

type CombinedState = {
  [sliceName]: State;
};

const slice = createSlice({
  name: "hotkeys",
  initialState: {
    openPreferences: 0,
  },
  reducers: {
    openPreferences: (state) => {
      // just need a value that changes
      // a number will serve our purposes
      state.openPreferences += 1;
    },
  },
});

const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const { openPreferences } = slice.actions;

const selectHotKeys = (state: CombinedState): State => state[sliceName];

export const selectOpenPreferences = createSelector(
  [selectHotKeys],
  (hotkeys) => hotkeys.openPreferences
);
