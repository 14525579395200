import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";
import { isMatching } from "ts-pattern";

import { lineBreakCharacter } from "@carescribe/slate";

import { validLineBreak } from "@talktype/store/src/persistence/versioned/v1/guards";

import { editorLoaded } from "../actions";

/**
 * Convert Line Breaks to Text
 *
 * If a line break doesn't contain a new line character, it should be
 * converted to text.
 */
export const convertLineBreaksToText = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, ({ payload: editor }) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]): void => {
      if (
        isMatching(validLineBreak, node) &&
        !node.text.includes(lineBreakCharacter)
      ) {
        editor.setNodes({ type: "text", inProgress: false }, { at: path });
      }

      normalizeNode([node, path]);
    };
  });
};
