import type { ThemeConfig } from "../../../types";
import type { ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import style from "./themeOption.module.scss";

export type ThemeOptionProps<Theme extends string> = {
  isSelected: boolean;
  onSelectOption: () => void;
  theme: ThemeConfig<Theme>;
};

export const ThemeOption = <Theme extends string>({
  isSelected,
  onSelectOption,
  theme: { id, img, display },
}: ThemeOptionProps<Theme>): ReactElement => (
  <label
    className={classNames(style.optionContainer, [isSelected, style.selected])}
    htmlFor={id}
  >
    <input
      type="radio"
      role="radio"
      aria-checked={isSelected}
      onChange={onSelectOption}
      id={id}
      checked={isSelected}
      className={style.radioInput}
      value={id}
      name="theme"
    />
    <div aria-hidden className={style.previewImg}>
      {img}
    </div>
    {/**
     * In an ideal world we'd have a shared version of DSStaticPill
     * that we could use here. Unfortunately as is there are some colours
     * that aren't present in both systems
     */}
    <span className={style.pill}>{display}</span>
  </label>
);
