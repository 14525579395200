import type { UUID } from "@carescribe/types";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { useCallback, memo } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./libraryRow.module.scss";
import { messages } from "./messages";
import { LibraryRowMenu } from "../LibraryRowMenu";

export type LibraryRowProps = {
  id: string;
  title: string | null;
  preview: string | null;
  highlight: boolean;
  onChangeDocument: (documentUUID: UUID) => void;
  onDeleteDocument: (
    documentUUID: UUID,
    event: InteractionEvent<HTMLButtonElement>
  ) => void;
};

export const LibraryRow = memo(
  ({
    id,
    title,
    preview,
    highlight,
    onChangeDocument,
    onDeleteDocument,
  }: LibraryRowProps): ReactElement => {
    const onClickDelete = useCallback(
      (event: InteractionEvent<HTMLButtonElement>) =>
        onDeleteDocument(id, event),
      [id, onDeleteDocument]
    );

    return (
      <li
        className={classNames(
          styles.listItem,
          createSelectorClassName("library-list-item", "interactive")
        )}
      >
        <label className={styles.label} data-hj-suppress>
          <input
            onChange={(): void => onChangeDocument(id)}
            className={styles.input}
            value={id}
            checked={highlight}
            type="radio"
            name="document"
          />
          <h2 className={styles.title}>{title ?? messages.untitled}</h2>
          <p className={styles.preview}>
            {preview ?? messages.noAdditionalText}
          </p>
        </label>
        <LibraryRowMenu
          className={styles.menu}
          onClickDelete={onClickDelete}
          // Focus should jump first to the selected item's menu button
          tabIndex={highlight ? 0 : -1}
        />
      </li>
    );
  }
);

LibraryRow.displayName = "LibraryRow";
