import type { Void } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { PopOverButtonProps } from "@carescribe/ui";
import type { Me } from "@talktype/user/src/types/Me";
import type { ReactElement, ReactNode } from "react";

import {
  ChatCenteredText,
  Gear,
  Question,
  SignOut,
  UserSquare,
} from "@phosphor-icons/react";
import { useCallback, useEffect, useRef } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { UserIcon } from "@carescribe/ui/src/components/UserIcon";

import { helpSiteUrl } from "@talktype/config/src/helpSiteUrl";
import { MainMenuTrigger } from "@talktype/ui/src/MainMenuTrigger";
import { Menu } from "@talktype/ui/src/Menu";
import { Header } from "@talktype/ui/src/Menu/Item/Header";

import { messages } from "./messages";
import { Button } from "../Menu/Item/Button";
import { Link } from "../Menu/Item/Link";
import { PreferencesModal } from "../PreferencesModal";
import { Tooltip } from "../Tooltip";

export type MainMenuProps = {
  canSubmitFeedback: boolean;
  dashboardUrl: string;
  platform: Platform;
  preferencesFields: ReactNode;
  user: Me | null;
  logOut: Void;
  onOpen: Void;
  submitFeedback: Void;
  triggerPreferences: number;
};

export const MainMenu = ({
  canSubmitFeedback,
  dashboardUrl,
  platform,
  preferencesFields,
  user,
  logOut,
  onOpen,
  submitFeedback,
  triggerPreferences,
}: MainMenuProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = useCallback((): void => {
    modalRef.current?.showModal();
    onOpen();
  }, [modalRef, onOpen]);

  useEffect(() => {
    if (triggerPreferences > 0) {
      showModal();
    }
  }, [showModal, triggerPreferences]);

  const renderTrigger = useCallback(
    (buttonProps: PopOverButtonProps): ReactNode => (
      <Tooltip
        messages={{
          visible: [
            user?.fullName ?? "Open menu",
            ...(user?.organisation?.name ? [user.organisation.name] : []),
          ],
        }}
        placement={{ x: "center", y: "bottom" }}
      >
        <MainMenuTrigger
          initials={user ? user.initials : "TT"}
          {...buttonProps}
        />
      </Tooltip>
    ),
    [user]
  );

  const items: ReactNode[] = [
    ...(user
      ? [
          <Header
            key="user"
            label={user.fullName}
            Icon={
              <UserIcon
                size="small"
                initials={user.initials}
                background="neutral"
              />
            }
          />,
        ]
      : []),
    <Button
      key="preferences"
      id="preferences"
      label={messages.preferences.label}
      Icon={<Gear />}
      shortcut={messages.preferences.shortcut({ platform })}
      onClick={showModal}
    />,
    <Link
      key="account"
      id="account"
      label={messages.account.label}
      Icon={<UserSquare />}
      href={dashboardUrl}
    />,
    <Link
      key="helpCentre"
      id="helpCentre"
      label={messages.helpCentre.label}
      Icon={<Question />}
      href={helpSiteUrl}
    />,
    ...(canSubmitFeedback
      ? [
          <Button
            key="feedback"
            id="feedback"
            label={messages.feedback.label}
            Icon={<ChatCenteredText />}
            shortcut={null}
            onClick={submitFeedback}
          />,
        ]
      : []),
    <Button
      key="logOut"
      id="logOut"
      label={messages.logOut.label}
      Icon={<SignOut />}
      shortcut={null}
      onClick={logOut}
    />,
  ];

  return (
    <>
      <Menu renderTrigger={renderTrigger} items={items} />
      <PreferencesModal modalRef={modalRef}>
        {preferencesFields}
      </PreferencesModal>
    </>
  );
};
