import type { RootState } from "@talktype/store";
import type { DrawerProps } from "@talktype/ui/src/Drawer";

import { connect } from "react-redux";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { selectIsLibraryDrawerOpen } from "@talktype/preferences/src/reducers/preferences/selectors";
import { Drawer as Component } from "@talktype/ui/src/Drawer";

import { Library } from "./Library";

const children = <Library />;

const mapStateToProps = (state: RootState): DrawerProps => ({
  border: "none",
  children,
  isOpen: selectIsLibraryDrawerOpen(state),
  label: "Library",
  selector: createSelectorClassName("library-drawer", "section"),
  width: "regular",
});

export const LibraryDrawer = connect(mapStateToProps, null)(Component);
