import type { DeviceInfo, Frequencies } from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice, createSelector } from "@reduxjs/toolkit";

type AudioState = {
  inputDevices: DeviceInfo[];
  permissionState: PermissionState;
  selectedInputDeviceId: string | null;
  volume: number;
  frequencies: Frequencies;
};

type CombinedState = {
  [sliceName]: AudioState;
};

const frequencies: Frequencies = {
  subBass: 0,
  bass: 0,
  lowMids: 0,
  mids: 0,
  highMids: 0,
  presence: 0,
};

const initialState: AudioState = {
  frequencies,
  inputDevices: [],
  permissionState: "prompt",
  selectedInputDeviceId: null,
  volume: 0,
};

type Reducer<T = void> = (
  state: AudioState,
  { payload }: PayloadAction<T>
) => void;

const setPermissionStateReducer: Reducer<PermissionState> = (
  state,
  { payload }
): void => {
  state.permissionState = payload;
};

const setInputDevicesReducer: Reducer<DeviceInfo[]> = (
  state,
  { payload }
): void => {
  state.inputDevices = payload;
};

const setSelectedInputDeviceIdReducer: Reducer<string | null> = (
  state,
  { payload }
): void => {
  state.selectedInputDeviceId = payload;
};

const setVolumeReducer: Reducer<number> = (state, { payload }): void => {
  state.volume = payload;
};

const setFrequenciesReducer: Reducer<Frequencies> = (
  state,
  { payload }
): void => {
  state.frequencies = payload;
};

const resetFrequenciesReducer: Reducer = (state): void => {
  state.frequencies = frequencies;
};

const slice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    resetFrequencies: resetFrequenciesReducer,
    setFrequencies: setFrequenciesReducer,
    setInputDevices: setInputDevicesReducer,
    setPermissionState: setPermissionStateReducer,
    setSelectedInputDeviceId: setSelectedInputDeviceIdReducer,
    setVolume: setVolumeReducer,
  },
});

export const {
  resetFrequencies,
  setFrequencies,
  setInputDevices,
  setPermissionState,
  setSelectedInputDeviceId,
  setVolume,
} = slice.actions;

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const selectInputDevices = (state: CombinedState): DeviceInfo[] =>
  state[sliceName].inputDevices;

export const selectPermissionState = (state: CombinedState): PermissionState =>
  state[sliceName].permissionState;

export const selectIsMicrophonePermissionGranted = createSelector(
  [selectPermissionState],
  (permissionState): boolean => permissionState === "granted"
);

export const selectSelectedInputDevice = (
  state: CombinedState
): string | null => state[sliceName].selectedInputDeviceId;

export const selectSelectedInputDeviceName = createSelector(
  [selectInputDevices, selectSelectedInputDevice],
  (inputDevices, selectedDeviceId): string | null => {
    const selectedDevice =
      selectedDeviceId !== null
        ? inputDevices.find((device) => device.deviceId === selectedDeviceId)
        : null;
    return selectedDevice?.label ?? null;
  }
);

export const selectVolume = (state: CombinedState): number =>
  state[sliceName].volume;

export const selectFrequencies = (state: CombinedState): Frequencies =>
  state[sliceName].frequencies;
