import type { StateProps } from "@carescribe/types/src/utils/connect";
import type { RootState } from "@talktype/store";
import type { EditorContainerProps } from "@talktype/ui/src/RegularLayout/EditorContainer";

import { connect } from "react-redux";

import {
  editorScrollPositionChange,
  selectScrollPositionForCurrentDocument,
} from "@talktype/editor";
import { EditorContainer as Component } from "@talktype/ui/src/RegularLayout/EditorContainer";

import { Editor } from ".";

const children = <Editor />;

const mapStateToProps = (
  state: RootState
): StateProps<EditorContainerProps> => ({
  children,
  scrollTop: selectScrollPositionForCurrentDocument(state),
});

const mapDispatchToProps = {
  handleScroll: editorScrollPositionChange,
};

export const EditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
