import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  clearRecorder,
  requestBroadcastRecorder,
  broadcastedRecorder,
  setRecorder,
} from "./actions";

export const setUpTrackRecorder = function* (): SagaIterator<void> {
  let recorder: MediaRecorder | null = null;

  yield takeEvery(setRecorder, function* ({ payload: newRecorder }) {
    recorder = newRecorder;
  });

  yield takeEvery(clearRecorder, function* () {
    recorder = null;
  });

  yield takeEvery(requestBroadcastRecorder, function* () {
    yield put(broadcastedRecorder(recorder));
  });
};
