import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { pauseTransitions, resumeTransitions } from "./actions";

/**
 * Responds to requests to pause and resume app transitions.
 *
 * `pauseTransitions`:
 * A `reduce` class is applied to the `<body>` element, allowing child elements
 * to be styled accordingly.
 *
 * `resumeTransitions`:
 * The `reduce` class is removed from the `<body>` element.
 */
export const manageMotion = function* (): SagaIterator<void> {
  yield takeEvery(pauseTransitions, function* () {
    document.documentElement.classList.add("reduce");
  });

  yield takeEvery(resumeTransitions, function* () {
    document.documentElement.classList.remove("reduce");
  });
};
