import type { AppDispatch } from "@talktype/store";
import type { InteractionEvent } from "@talktype/types";
import type { LibraryCloseButtonProps } from "@talktype/ui/src/Library/LibraryCloseButton";

import { connect } from "react-redux";

import { closeLibraryDrawer } from "@talktype/preferences";
import { LibraryCloseButton as Component } from "@talktype/ui/src/Library/LibraryCloseButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapDispatchToProps = (
  dispatch: AppDispatch
): LibraryCloseButtonProps => ({
  onClick: (event: InteractionEvent<HTMLButtonElement>) =>
    dispatch(
      closeLibraryDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const LibraryCloseButton = connect(null, mapDispatchToProps)(Component);
