import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, takeEvery, put } from "redux-saga/effects";

import {
  selectSelectedInputDevice,
  setInputDevices,
  setSelectedInputDeviceId,
} from "../reducer";

/**
 * Listens out for changes to the list of input devices and checks that the
 * selected device is still available. If it is not, it will fallback to the
 * default or first device in the list.
 */
export const setUpSelectedInputDeviceFallback =
  function* (): SagaIterator<void> {
    yield takeEvery(setInputDevices, function* ({ payload: devices }) {
      const selectedDeviceId: SagaReturnType<typeof selectSelectedInputDevice> =
        yield select(selectSelectedInputDevice);

      const selectedDeviceIsAvailable = devices.some(
        (device) => device.deviceId === selectedDeviceId
      );

      if (selectedDeviceIsAvailable) {
        return;
      }

      const firstDeviceId = devices.at(0)?.deviceId;
      const defaultDeviceId = devices.find(
        (device) => device.isDefault
      )?.deviceId;

      const fallbackDeviceId = defaultDeviceId ?? firstDeviceId ?? null;
      yield put(setSelectedInputDeviceId(fallbackDeviceId));
    });
  };
