import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects";

import { getBlockTypeAtSelection } from "@talktype/utilities/src/slate/getBlockTypeAtSelection";
import { getInlineStyleSelectionMarks } from "@talktype/utilities/src/slate/getInlineStyleSelectionMarks";

import {
  setActiveStyles,
  clearActiveStyles,
  setActiveBlock,
} from "../../reducers";
import { createEditorFormattingChangeChannel } from "../../utils/createEditorFormattingChangeChannel";
import { editorLoaded } from "../actions";

const updateMarks = function* (editor: EditorType): SagaIterator<void> {
  const marks: SagaReturnType<typeof getInlineStyleSelectionMarks> = yield call(
    getInlineStyleSelectionMarks,
    editor
  );

  if (marks) {
    yield put(setActiveStyles(marks));
  } else {
    yield put(clearActiveStyles());
  }
};

const updateBlocks = function* (editor: EditorType): SagaIterator<void> {
  const type: SagaReturnType<typeof getBlockTypeAtSelection> = yield call(
    getBlockTypeAtSelection,
    editor
  );

  yield put(setActiveBlock(type));
};

/**
 * Store Selection Styles in Redux
 *
 * Detects any changes in the editor and stores the styles at the user's
 * selection in the Redux state. This is used to style the formatting bar's
 * buttons to indicate whether a style is active or not
 */
export const storeSelectionFormattingInRedux =
  function* (): SagaIterator<void> {
    yield takeLatest(
      editorLoaded,
      function* ({ payload: editor }): SagaIterator<void> {
        const channel: SagaReturnType<
          typeof createEditorFormattingChangeChannel
        > = yield call(createEditorFormattingChangeChannel, editor);

        yield takeEvery(channel, function* (): SagaIterator<void> {
          yield all(
            [updateMarks, updateBlocks].map((update) => call(update, editor))
          );
        });
      }
    );
  };
