import type { Document } from "../types";

import { v4 as uuid } from "uuid";

import { emptyHistory } from "./entities/emptyHistory";
import { newDocumentChildren } from "./entities/newDocumentChildren";

type CreateDocument = (document?: Partial<Document>) => Document;

const getDefaults = (): Document => ({
  children: newDocumentChildren,
  history: emptyHistory,
  selection: null,
  id: uuid(),
  createdAt: new Date().toISOString(),
  modifiedAt: new Date().toISOString(),
});

export const createDocument: CreateDocument = (document) => ({
  ...getDefaults(),
  ...document,
});
