import type { RootState } from "@talktype/store";
import type { CompactLayoutProps } from "@talktype/ui/src/CompactLayout";

import { connect } from "react-redux";

import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { CompactLayout as Component } from "@talktype/ui/src/CompactLayout";

import { DictateAnywhereCloseWindowButton } from "./DictateAnywhereCloseWindowButton";
import { DictationButton } from "./DictationButton";
import { ExitDictateAnywhereButton } from "./ExitDictateAnywhereButton";
import { Notifications } from "./Notifications";

const dictationControls = <DictationButton disableTooltip />;
const closeWindowButton = <DictateAnywhereCloseWindowButton />;
const exitDictateAnywhereButton = <ExitDictateAnywhereButton />;
const notifications = <Notifications />;

const mapStateToProps = (
  state: RootState
): Pick<
  CompactLayoutProps,
  | "platform"
  | "dictationControls"
  | "closeWindowButton"
  | "exitDictateAnywhereButton"
  | "notifications"
> => ({
  platform: selectPlatform(state),
  dictationControls,
  closeWindowButton,
  exitDictateAnywhereButton,
  notifications,
});

export const CompactLayout = connect(mapStateToProps)(Component);
