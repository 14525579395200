import * as clear from "./clear";
import * as deletion from "./deletion";
import * as desktop from "./desktop";
import * as easterEggs from "./easter-eggs";
import * as formatting from "./formatting";
import * as history from "./history";
import * as insertion from "./insertion";
import { deleteDocument } from "./library/deleteDocument";
import { newDocument } from "./library/newDocument";
import * as navigation from "./navigation";
import * as selection from "./selection";

export const commands = {
  ...clear,
  ...deletion,
  ...desktop,
  ...easterEggs,
  ...formatting,
  ...history,
  ...insertion,
  ...navigation,
  ...selection,
  newDocument,
  deleteDocument,
};
