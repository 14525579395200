import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, take } from "redux-saga/effects";

import {
  requestProcessResult,
  processedResult,
  requestFinaliseDictation,
  finalisedDictation,
} from "@talktype/actions";
import {
  requestClearInProgressContent,
  clearedInProgressContent,
} from "@talktype/editor/src/sagas/actions";
import {
  finalisedText,
  requestFinaliseText,
} from "@talktype/editor/src/sagas/results/actions";

import {
  assembledTranscript,
  assignedResult,
  categorisedResult,
  requestAssembleTranscript,
  requestAssignResult,
  requestCategoriseResult,
  requestReshapeResult,
  requestSendResult,
  reshapedResult,
  resultSent,
  requestFinaliseInProgressResult,
  finalisedInProgressResult,
} from "./actions";
import { setInProgressResult } from "../state";

/**
 * Manages the processing of a result.
 *
 * The result is processed in a pipeline of effects:
 * - Assign target app
 * - Categorise
 * - Finalise in-progress result if subsequent new result is received
 * - Clear in-progress editor content
 * - Track in-progress result
 * - Reshape
 * - Assemble
 * - Add to editor
 */
export const resultsPipeline = function* (): SagaIterator<void> {
  yield takeEvery(requestProcessResult, function* ({ payload: initialResult }) {
    yield put(requestAssignResult(initialResult));
    const { payload: result }: SagaReturnType<typeof assignedResult> =
      yield take(assignedResult);

    yield put(requestCategoriseResult(result));
    const {
      payload: { isStale, isNew },
    }: SagaReturnType<typeof categorisedResult> = yield take(categorisedResult);

    if (isStale) {
      yield put(processedResult({ ...result, transcript: [] }));
      return;
    }

    if (isNew) {
      yield put(requestFinaliseInProgressResult());
      yield take(finalisedInProgressResult);
    }

    yield put(requestClearInProgressContent(result));
    yield take(clearedInProgressContent);

    // Categorise -> Track in-progress result
    yield put(setInProgressResult(result.isFinal ? null : result));

    // Reshape
    yield put(requestReshapeResult(result));
    const {
      payload: resultAfterReshaping,
    }: SagaReturnType<typeof reshapedResult> = yield take(reshapedResult);

    // Assemble
    yield put(requestAssembleTranscript(resultAfterReshaping));
    const {
      payload: transformedResult,
    }: SagaReturnType<typeof assembledTranscript> = yield take(
      assembledTranscript
    );

    /**
     * When dictating to other apps:
     *
     * Finalise if non-text segments are present.
     *
     * When it comes to non-text segments, we cannot guarantee how the target
     * app has interpreted them. Therefore, removing stale content from such
     * in-progress results is not currently possible.
     *
     * Example:
     *
     * Line break segments are inserted by pressing the "Enter" + "Shift" keys.
     * Normally, most apps would interpret this as a new line. However, in a
     * small number of cases, the app may interpret this differently
     * (e.g form submission) meaning we can't simply delete characters to
     * remove the stale content.
     */
    const nonTextSegmentPresentInDictatingToApp =
      transformedResult.targetApp.isSelf === false &&
      transformedResult.isFinal === false &&
      transformedResult.transcript.some((segment) => segment.type !== "text");

    if (nonTextSegmentPresentInDictatingToApp) {
      yield put(requestFinaliseDictation());
      yield take(finalisedDictation);
      return;
    }

    const transcriptIsNotEmpty = transformedResult.transcript.length > 0;
    if (transcriptIsNotEmpty) {
      yield put(requestSendResult(transformedResult));
      yield take(resultSent);
    }

    const { isFinal, targetApp } = transformedResult;
    const shouldFinaliseEditorText = isFinal && targetApp.isSelf;
    if (shouldFinaliseEditorText) {
      yield put(requestFinaliseText({ documentUUID: targetApp.documentUUID }));
      yield take(finalisedText);
    }

    yield put(processedResult(transformedResult));
  });
};
