import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpAddDocument } from "./setUpAddDocument";
import { setUpDeleteDocument } from "./setUpDeleteDocument";
import { setUpTrackCurrentDocument } from "./setUpTrackCurrentDocument";

export const setUpLibrary = function* (): SagaIterator<void> {
  yield all(
    [setUpTrackCurrentDocument, setUpAddDocument, setUpDeleteDocument].map(
      retry
    )
  );
};
