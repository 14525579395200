import { useState, useEffect } from "react";

/**
 * Subscribes to a media query and returns the current value.
 */
export const useMediaQuery = (query: string): boolean => {
  const mediaQueryList = window.matchMedia(query);

  const [matches, setMatches] = useState(mediaQueryList.matches);

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent): void =>
      setMatches(event.matches);

    mediaQueryList.addEventListener("change", onChange);

    return () => mediaQueryList.removeEventListener("change", onChange);
  }, [mediaQueryList]);

  return matches;
};
