import type { ThemeConfig } from "@carescribe/ui/src/types";
import type { ColourScheme } from "@talktype/types/src/ColourScheme";

import { DarkPreview } from "@talktype/icons/src/ThemePreviews/DarkPreview";
import { LightPreview } from "@talktype/icons/src/ThemePreviews/LightPreview";
import { SystemPreview } from "@talktype/icons/src/ThemePreviews/SystemPreview";

import { messages } from "./messages";

export const colourSchemes: ThemeConfig<ColourScheme>[] = [
  {
    display: messages.colourSchemes.system,
    id: "normal",
    img: <SystemPreview />,
  },
  {
    display: messages.colourSchemes.light,
    id: "light",
    img: <LightPreview />,
  },
  {
    display: messages.colourSchemes.dark,
    id: "dark",
    img: <DarkPreview />,
  },
];
