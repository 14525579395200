import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select } from "redux-saga/effects";

import { selectCurrentDocumentId, setInitialDocument } from "../reducers";
import { createDocument } from "../utils/createDocument";

export const setUpInitialState = function* (): SagaIterator<void> {
  const currentDocumentUUID: SagaReturnType<typeof selectCurrentDocumentId> =
    yield select(selectCurrentDocumentId);

  /**
   * Document loaded from indexDB, so don't need to setup
   */
  if (currentDocumentUUID) {
    return;
  }

  const document = createDocument();
  yield put(setInitialDocument(document));
};
