import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type ListingState = {
  searchTerm: string;
};

const initialState: ListingState = {
  searchTerm: "",
};

const setSearchTermReducer: CaseReducer<ListingState, PayloadAction<string>> = (
  state,
  { payload: searchTerm }
) => {
  state.searchTerm = searchTerm;
};

const slice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    setSearchTerm: setSearchTermReducer,
  },
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const { setSearchTerm } = slice.actions;
