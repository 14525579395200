import type { DeviceInfo } from "../types";
import type { Seconds } from "@carescribe/types/src/Units";

import { createLogger } from "@carescribe/utilities/src/log";
import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import config from "./config.json";

export const { log, logError } = createLogger("Audio");

export const getMicrophonePermissionState = (): Promise<PermissionState> =>
  navigator.permissions
    .query({ name: "microphone" as PermissionName })
    .then((status) => status.state)
    .catch((e) => {
      console.error(`Microphone Permission Error: ${e}`);
      return "denied";
    });

export const getUserMedia = async (
  deviceId: string | null
): Promise<
  { stream: MediaStream; error?: never } | { stream?: never; error: Error }
> => {
  const audioConstraints = deviceId === null ? true : { deviceId };

  try {
    return {
      stream: await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      }),
    };
  } catch (error) {
    return {
      error:
        error instanceof Error
          ? error
          : new Error(`unknown error: ${JSON.stringify(error)}`),
    };
  }
};

export const getUserDevices = (): Promise<MediaDeviceInfo[]> =>
  navigator.mediaDevices.enumerateDevices();

type CreateMediaRecorderConfig = {
  stream: MediaStream;
  listener: (blobEvent: BlobEvent) => void;
  frequency: Seconds;
};

export const createMediaRecorder = ({
  stream,
  listener,
  frequency,
}: CreateMediaRecorderConfig): MediaRecorder => {
  const recorder = new MediaRecorder(stream);

  recorder.addEventListener("dataavailable", listener);

  recorder.start(secondsToMilliseconds(frequency));

  return recorder;
};

/**
 * Stops a MediaRecorder and resolves when the stop event is fired.
 */
export const stopMediaRecorder = (recorder: MediaRecorder): Promise<void> => {
  return new Promise((resolve) => {
    const controller = new AbortController();
    const onStop = (): void => {
      resolve();
      controller.abort();
    };
    recorder.addEventListener("stop", onStop, controller);
    recorder.stop();
  });
};

export const stopStream = (stream: MediaStream): void => {
  stream.getTracks().forEach((track) => track.stop());
};

export const mediaStreamHasAudio = (mediaStream: MediaStream): boolean =>
  mediaStream.getAudioTracks().length > 0;

/*
    filterDevices:
    This util filters out any audio drivers.
    Most browsers: audio drivers have the "Virtual" in the label
    Safari: no Virtual label, so we need to filter any known drivers out by name
  */

/**
 * Takes in a list of media devices and filters out:
 *
 * - Non-audio devices
 * - Virtual devices
 * - Known audio drivers
 * - The pseudo "default" device
 *   Some browsers may designate a device as the default device,
 *   listing it twice: once with its unique device ID and again
 *   with a device ID of "default". This flagrant violation of
 *   normalisation can lead to utter chaos when unleashed upon
 *   the application code.
 *
 *   Imagine: a device with the "default" ID is disconnected,
 *   yet it appears as still connected because the browser has
 *   reassigned the "default" ID to another remaining device - madness!
 *
 *   To avert this, each device is listed once with its unique device ID,
 *   and a `isDefault` property indicating whether it is the chosen default
 *   device.
 */
export const filterDevices = (
  mediaDevices: MediaDeviceInfo[]
): DeviceInfo[] => {
  const defaultDeviceLabel = mediaDevices.find(
    (device) => device.deviceId === "default"
  )?.label;
  let defaultDeviceAssigned = false;
  const filteredDevices: DeviceInfo[] = [];

  for (const { deviceId, label, kind } of mediaDevices) {
    const shouldAddDevice =
      kind === "audioinput" &&
      !label.includes("(Virtual)") &&
      !config.knownAudioDrivers.includes(label) &&
      deviceId !== "default";

    if (!shouldAddDevice) {
      continue;
    }

    const isDefault = defaultDeviceLabel
      ? defaultDeviceLabel.includes(label) && !defaultDeviceAssigned
      : false;

    filteredDevices.push({ deviceId, label, isDefault });

    if (isDefault) {
      defaultDeviceAssigned = true;
    }
  }

  return filteredDevices;
};
