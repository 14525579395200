import type { SagaIterator } from "redux-saga";

import { call, put } from "redux-saga/effects";

import { pongReceived } from "@carescribe/transcriber-connection/src";

import { requestStopDictating } from "@talktype/actions";

import { receivedReadySignal, receivedResult } from "./actions";
import { log, logError } from "./log";
import {
  isErrorMessage,
  isPingPongMessage,
  isReadyMessage,
  isResultMessage,
} from "../types/Responses";

/**
 * Handle Message
 *
 * Handles messages from the transcriber once they have been transformed from
 * JSON into something else.
 */
export const handleMessage = function* (message: unknown): SagaIterator<void> {
  // Any message is a pong message: they all confirm that we're connected
  yield put(pongReceived());

  if (isPingPongMessage(message)) {
    return;
  }

  if (isReadyMessage(message)) {
    yield put(receivedReadySignal());
    return;
  }

  if (isResultMessage(message)) {
    yield put(receivedResult(message.payload));
    return;
  }

  if (!isErrorMessage(message)) {
    yield call(
      logError,
      "unexpected format received from transcriber:",
      message
    );

    return;
  }

  if (message.payload.code === "silence-detected") {
    yield put(requestStopDictating());
    yield call(
      log,
      "Disconnection due to silence detected. Reason:",
      message.payload.reason
    );
    return;
  }

  yield call(
    logError,
    `Socket Error: code - ${message.payload.code}; reason - ${message.payload.reason}.`
  );
};
