import type { SagaIterator } from "redux-saga";

import { put, delay, takeEvery } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { addToast } from "@talktype/toasts/src/sagas/actions";

import { loginComplete } from "./actions";

/**
 * Set Up SSO Actions
 */
export const setUpSSO = function* (): SagaIterator<void> {
  yield takeEvery(loginComplete, function* ({ payload: me }) {
    if (me.organisation && me.organisation.ssoEnabled) {
      yield delay(500);
      yield put(
        addToast({
          id: "sso_login",
          type: "sso_login",
          organisation: me.organisation.name,
          order: 0,
          dismissConfig: { after: toSeconds(3), notBefore: null },
        })
      );
    }
  });
};
