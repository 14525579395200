import type { Document, PreviewableDocument } from "../types";
import type { UUID } from "@carescribe/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  Block,
  DictationMode,
  DictationStatus,
  InlineStyles,
} from "@talktype/types";

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";

import { extractText } from "@carescribe/slate";

import { defaultBlock } from "@talktype/config/src/defaultBlock";

import { documentIsEmpty } from "./isNewDocument";
import { startDictating } from "../sagas/actions";
import { convertToPreviewableDocument } from "../utils/convertToPreviewableDocument";
import { emptyChildren } from "../utils/entities";
import { finaliseChildren } from "../utils/finaliseChildren";

// Enable Immer to support Map and Set
enableMapSet();

export type EditorState = {
  documents: Map<UUID, Document>;
  scrollPositions: Map<UUID, number>;
  currentDocumentId: UUID | null;
  dictationMode: DictationMode;
  dictationStatus: DictationStatus;
  block: Block;
  styles: Record<InlineStyles, boolean>;
};

export type CombinedState = {
  [sliceName]: EditorState;
};

const initialState: EditorState = {
  documents: new Map([]),
  scrollPositions: new Map([]),
  currentDocumentId: null,
  dictationMode: "talktype",
  dictationStatus: "inactive",

  block: defaultBlock,

  styles: {
    bold: false,
    italic: false,
    underline: false,
  },
};

const setInitialDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Document>
): void => {
  state.currentDocumentId = document.id;
  state.documents.set(document.id, document);
};

const setScrollPositionForCurrentDocumentReducer = (
  state: EditorState,
  { payload: position }: PayloadAction<number>
): void => {
  if (state.currentDocumentId) {
    state.scrollPositions.set(state.currentDocumentId, position);
  }
};

const setDocumentHistoryReducer = (
  state: EditorState,
  { payload: { id, history } }: PayloadAction<Pick<Document, "id" | "history">>
): void => {
  const document = state.documents.get(id);
  if (!document) {
    return;
  }

  state.documents.set(id, { ...document, history });
};

const setDocumentChildrenReducer = (
  state: EditorState,
  {
    payload: { id, children },
  }: PayloadAction<Pick<Document, "id" | "children">>
): void => {
  const document = state.documents.get(id);
  if (!document) {
    return;
  }

  state.documents.set(id, {
    ...document,
    children,
    modifiedAt: new Date().toISOString(),
  });
};

const setDocumentSelectionReducer = (
  state: EditorState,
  {
    payload: { id, selection },
  }: PayloadAction<Pick<Document, "id" | "selection">>
): void => {
  const document = state.documents.get(id);
  if (!document) {
    return;
  }

  state.documents.set(id, { ...document, selection });
};

const addDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Document>
): void => {
  state.documents.set(document.id, document);
  state.currentDocumentId = document.id;
};

const updateCurrentDocument = (
  state: EditorState,
  targetDocumentId: UUID
): void => {
  const documentIds = Array.from(state.documents.keys()).reverse();
  const targetIndex = documentIds.indexOf(targetDocumentId);
  const isLastDocument = targetIndex === documentIds.length - 1;

  const previousDocumentId = documentIds.at(targetIndex - 1);
  const nextDocumentId = documentIds.at(targetIndex + 1);

  state.currentDocumentId =
    (isLastDocument ? previousDocumentId : nextDocumentId) ?? null;
};

/**
 * Deletes the target document.
 *
 * If there is only one document, it will not be deleted.
 *
 * If the target document is the current document, it will be set to the
 * next document, unless it is the last document: in such cases the previous
 * document is used as it is the closest alternative.
 */
const deleteDocumentReducer = (
  state: EditorState,
  { payload: targetDocumentId }: PayloadAction<UUID>
): void => {
  const hasMoreThanOneDocument = state.documents.size > 1;
  if (!hasMoreThanOneDocument) {
    return;
  }

  if (targetDocumentId === state.currentDocumentId) {
    updateCurrentDocument(state, targetDocumentId);
  }

  state.documents.delete(targetDocumentId);
};

/**
 * Normally, the in-progress nodes are finalised directly via the editor.
 * When the editor is not mounted, however, this is not possible.
 * This reducer allows us to finalise the document directly in the
 * state in such cases.
 */
const finaliseDocumentReducer = (
  state: EditorState,
  { payload: { documentUUID } }: PayloadAction<{ documentUUID: UUID | null }>
): void => {
  const currentDocumentUUID = state.currentDocumentId;
  const documentUUIDToFinalise = documentUUID ?? currentDocumentUUID;

  if (!documentUUIDToFinalise) {
    return;
  }

  const document = state.documents.get(documentUUIDToFinalise);
  if (!document) {
    return;
  }

  const children = finaliseChildren(document.children);

  state.documents.set(document.id, { ...document, children });
};

const setCurrentDocumentIdReducer = (
  state: EditorState,
  { payload }: PayloadAction<string>
): void => {
  state.currentDocumentId = payload;
};

const dictationLoadingReducer = (state: EditorState): void => {
  state.dictationStatus = "loading";
};

const dictationStartedReducer = (state: EditorState): void => {
  state.dictationStatus = "active";
};

const dictationStoppedReducer = (state: EditorState): void => {
  state.dictationStatus = "inactive";
};

const setDictationModeReducer = (
  state: EditorState,
  { payload }: { payload: DictationMode }
): void => {
  state.dictationMode = payload;
};

const setActiveStylesReducer = (
  state: EditorState,
  { payload }: PayloadAction<Partial<Record<InlineStyles, boolean>>>
): void => {
  state.styles = { ...state.styles, ...payload };
};

const setActiveBlockReducer = (
  state: EditorState,
  { payload }: PayloadAction<Block>
): void => {
  state.block = payload;
};

const clearActiveStylesReducer = (state: EditorState): void => {
  state.styles = {
    bold: false,
    italic: false,
    underline: false,
  };
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setScrollPositionForCurrentDocument:
      setScrollPositionForCurrentDocumentReducer,
    addDocument: addDocumentReducer,
    clearActiveStyles: clearActiveStylesReducer,
    deleteDocument: deleteDocumentReducer,
    dictationStarted: dictationStartedReducer,
    finaliseDocument: finaliseDocumentReducer,
    setActiveBlock: setActiveBlockReducer,
    setActiveStyles: setActiveStylesReducer,
    setCurrentDocumentId: setCurrentDocumentIdReducer,
    setDictationMode: setDictationModeReducer,
    setDocumentChildren: setDocumentChildrenReducer,
    setDocumentHistory: setDocumentHistoryReducer,
    setDocumentSelection: setDocumentSelectionReducer,
    setInitialDocument: setInitialDocumentReducer,
    stopDictating: dictationStoppedReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(startDictating, dictationLoadingReducer);
  },
});

const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const {
  actions: {
    addDocument,
    clearActiveStyles,
    deleteDocument,
    dictationStarted,
    finaliseDocument,
    setActiveBlock,
    setActiveStyles,
    setCurrentDocumentId,
    setDictationMode,
    setDocumentChildren,
    setDocumentHistory,
    setDocumentSelection,
    setInitialDocument,
    setScrollPositionForCurrentDocument,
    stopDictating,
  },
} = slice;

export const selectDictationStatus = (state: CombinedState): DictationStatus =>
  state[sliceName].dictationStatus;

export const selectDictationMode = (state: CombinedState): DictationMode =>
  state[sliceName].dictationMode;

export const selectCurrentDocumentId = (state: CombinedState): string | null =>
  state[sliceName].currentDocumentId;

const selectScrollPositions = (state: CombinedState): Map<UUID, number> =>
  state[sliceName].scrollPositions;

export const selectScrollPositionForCurrentDocument = createSelector(
  [selectScrollPositions, selectCurrentDocumentId],
  (scrollPositions, uuid): number => (uuid ? scrollPositions.get(uuid) ?? 0 : 0)
);

export const selectDocuments = (state: CombinedState): Map<string, Document> =>
  state[sliceName].documents;

export const selectDocumentsCount = createSelector(
  [selectDocuments],
  (documents) => documents.size
);

export const selectPreviewableDocuments = createSelector(
  [selectDocuments],
  (documents): PreviewableDocument[] =>
    Array.from(documents.values())
      .sort((a, b) => b.modifiedAt.localeCompare(a.modifiedAt))
      .map(convertToPreviewableDocument)
);

export const selectCurrentDocument = createSelector(
  [selectCurrentDocumentId, selectDocuments],
  (id, documents) => (id === null ? null : documents.get(id) ?? null)
);

export const selectEditorInitialValue = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.children : emptyChildren)
);

export const selectDocumentLength = createSelector(
  selectCurrentDocument,
  (document) => extractText(document).length
);

export const selectDocumentIsEmpty = createSelector(
  [selectCurrentDocument],
  (document) => (document ? documentIsEmpty(document) : true)
);

export const selectDocumentHistory = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.history : null)
);

export const selectDocumentIsUndoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.undos.length > 0 : false)
);

export const selectDocumentIsRedoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.redos.length > 0 : false)
);

export const selectDocumentSelection = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.selection : null)
);

export const selectDictating = createSelector(
  [selectDictationStatus],
  (dictationStatus) => dictationStatus === "active"
);

export const editorIsActive = createSelector(
  [selectDocumentIsEmpty, selectDictating],
  (empty, dictating) => !empty || dictating
);

const selectStyles = (state: CombinedState): EditorState["styles"] =>
  state[sliceName].styles;

export const selectInlineStyleEnabled = createSelector(
  [selectStyles, (_, id: InlineStyles): InlineStyles => id],
  (styles, id): boolean => styles[id]
);

const selectBlock = (state: CombinedState): EditorState["block"] =>
  state[sliceName].block;

export const selectBlockEnabled = createSelector(
  [selectBlock, (_, target: Block): Block => target],
  (block, target): boolean => {
    if (target === "heading" && block === "title") {
      return true;
    }

    return block === target;
  }
);
