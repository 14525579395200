import type { StateProps } from "@carescribe/types/src/utils/connect";
import type { DictationControlsProps } from "@talktype/ui/src/DictationControls";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { DictationControls as Component } from "@talktype/ui/src/DictationControls";

import { DictationButton } from "./DictationButton";
import { DictationModeMenu } from "./DictationModeMenu";

const isDesktop = getAppContext() === "desktop";
const dictationButton = <DictationButton />;
const dictationModeMenu = <DictationModeMenu />;

const mapStateToProps = (): StateProps<DictationControlsProps> => ({
  isDesktop,
  dictationButton,
  dictationModeMenu,
});

export const DictationControls = connect(mapStateToProps)(Component);
