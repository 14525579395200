import type { P } from "ts-pattern";

import { isMatching } from "ts-pattern";

export { P } from "ts-pattern";

export type GuardType<T> = T extends (x: unknown) => x is infer U ? U : never;

/**
 * Create Guard
 *
 * Creates a type guard using ts-pattern. The guard will check if the input
 * matches the shape of the provided type.
 *
 * @example
 * type User = {
 *   name: string | null,
 *   id: number,
 *   address: {
 *     street: string,
 *     city: string,
 *   }
 * }
 *
 * const validUser = {
 *   name: P.union(P.string, P.nullish),
 *   id: P.number,
 *   address: {
 *     street: P.string,
 *     city: P.string,
 *   }
 * }
 *
 * const isUser = createGuard<User>(validUser);
 *
 * const user: unknown = getUser();
 *
 * if (isUser(user)) {
 *   user.name; // string | null
 *   user.address.street; // string
 * } else {
 *   user; // unknown
 * }
 */
export const createGuard = <T>(
  pattern: P.Pattern<T>
): ((value: unknown) => value is T) =>
  isMatching(pattern as P.Pattern<unknown>);
