import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type FeatureFlagsState = {
  dictateToApp: boolean;
  chameleon: boolean;
  librarySystem: boolean;
};

const initialState: FeatureFlagsState = {
  dictateToApp: false,
  chameleon: false,
  librarySystem: false,
};

const setDictateToAppIsEnabledReducer = (
  state: FeatureFlagsState,
  { payload }: PayloadAction<boolean>
): void => {
  state.dictateToApp = payload;
};

const setChameleonIsEnabledReducer = (
  state: FeatureFlagsState,
  { payload }: PayloadAction<boolean>
): void => {
  state.chameleon = payload;
};

const setLibrarySystemIsEnabledReducer = (
  state: FeatureFlagsState,
  { payload }: PayloadAction<boolean>
): void => {
  state.librarySystem = payload;
};

const slice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setDictateToAppIsEnabled: setDictateToAppIsEnabledReducer,
    setChameleonIsEnabled: setChameleonIsEnabledReducer,
    setLibrarySystemIsEnabled: setLibrarySystemIsEnabledReducer,
  },
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const {
  setDictateToAppIsEnabled,
  setChameleonIsEnabled,
  setLibrarySystemIsEnabled,
} = slice.actions;
