import type { EditorType } from "../editor";
import type { EventChannel } from "redux-saga";
import type { Operation } from "slate";

import { eventChannel } from "redux-saga";

import { noOp } from "@carescribe/utilities/src/fp";

export const createEditorHistoryChangeChannel = (
  editor: EditorType
): EventChannel<true> => {
  let { children: previousChildren, marks: previousMarks } = editor;
  const { onChange: originalOnChange } = editor;

  return eventChannel((emit) => {
    editor.onChange = (options?: { operation?: Operation }): void => {
      const { children, marks } = editor;

      const hasChanged =
        previousChildren !== children || previousMarks !== marks;

      if (hasChanged) {
        emit(true);
        previousChildren = children;
        previousMarks = marks;
      }

      originalOnChange(options);
    };

    return noOp;
  });
};
