import type { AppDispatch } from "@talktype/store";
import type { UpdateErrorToast as UpdateErrorToastType } from "@talktype/types";
import type { UpdateErrorToastProps } from "@talktype/ui/src/UpdateErrorToast";

import { connect } from "react-redux";

import { UpdateErrorToast as Component } from "@talktype/ui/src/UpdateErrorToast";
import { requestRetryUpdateStep } from "@talktype/updater";

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { details }: UpdateErrorToastType
): Pick<UpdateErrorToastProps, "onClick"> => ({
  onClick: () => dispatch(requestRetryUpdateStep(details)),
});

export const UpdateErrorToast = connect(null, mapDispatchToProps)(Component);
