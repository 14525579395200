import type { Platform } from "@carescribe/types/src/Platform";
import type { Messages } from "@talktype/types/src/Messages";

const quickNav: Record<
  "voiceCommands" | "customWords" | "shortcuts",
  Messages<{ platform: Platform }>
> = {
  voiceCommands: ({ platform }) => [
    "Voice commands",
    platform === "mac" ? "⌥ + 1" : "Alt + 1",
  ],
  customWords: ({ platform }) => [
    "Custom words",
    platform === "mac" ? "⌥ + 2" : "Alt + 2",
  ],
  shortcuts: ({ platform }) => [
    "Shortcuts",
    platform === "mac" ? "⌥ + 3" : "Alt + 3",
  ],
};

export const messages = {
  shortcuts: "Text Shortcuts",
  punctuation: "Punctuation",
  commands: "Notes Controls",

  quickNav,

  colourSchemes: {
    system: "System",
    light: "Light",
    dark: "Dark",
  },

  dateFormats: {
    off: "Off",
    DD_MM_YYYY: "DD/MM/YYYY",
  },

  dictationLanguages: {
    enGB: "English (UK)",
    enUS: "English (US)",
  },

  numberFormats: {
    automatic: "Automatic",
    numerals: "Numerals",
  },

  tray: {
    startDictation: "Start Dictation",
    stopDictation: "Stop Dictation",
    open: "Open TalkType",
    help: "Help Centre",
    quit: "Quit TalkType",
    tooltip: "TalkType",
  },
};
