import type { CombinedState } from "../types";
import type { Seconds } from "@carescribe/types";

import {
  subtractSeconds,
  millisecondsToSeconds,
} from "@carescribe/utilities/src/timing";

import { sliceName } from "..";

export const selectSecondsSinceLastConnection = (
  state: CombinedState
): Seconds =>
  subtractSeconds(
    millisecondsToSeconds(performance.now()),
    state[sliceName].lastConnectionTimestamp
  );
