import type { MicrophoneListener } from "../types";
import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import {
  race,
  takeEvery,
  take,
  fork,
  cancel,
  call,
  put,
  select,
} from "redux-saga/effects";

import {
  audioConnected,
  broadcastedIsRecording,
  broadcastedRecorder,
  broadcastedStream,
  clearRecorder,
  clearStream,
  createdAudioStream,
  createdMediaRecorder,
  disconnectedAudio,
  mediaStreamError,
  requestBroadcastIsRecording,
  requestBroadcastRecorder,
  requestBroadcastStream,
  requestConnectAudio,
  requestDisconnectAudio,
  setRecorder,
  setStream,
  trackStartedRecording,
  trackStoppedRecording,
} from "./actions";
import { SEND_MEDIA_FREQUENCY } from "./setUpSendAudioOverSocket";
import {
  createMediaRecorder,
  getUserMedia,
  log,
  logError,
  stopMediaRecorder,
  stopStream,
} from "./utils";
import {
  selectSelectedInputDevice,
  setSelectedInputDeviceId,
} from "../reducer";

const setUp = function* (listener: MicrophoneListener): SagaIterator<void> {
  yield call(log, "connecting to audio");

  try {
    const deviceId: SagaReturnType<typeof selectSelectedInputDevice> =
      yield select(selectSelectedInputDevice);

    const { stream, error }: SagaReturnType<typeof getUserMedia> = yield call(
      getUserMedia,
      deviceId
    );

    if (error) {
      throw error;
    }

    yield put(createdAudioStream(stream));
    yield call(log, "stream started");

    const recorder: MediaRecorder = yield call(createMediaRecorder, {
      stream,
      listener,
      frequency: SEND_MEDIA_FREQUENCY,
    });

    yield put(createdMediaRecorder(recorder));
    yield call(log, "recorder started");

    yield put(setStream(stream));
    yield put(setRecorder(recorder));
    yield put(trackStartedRecording(listener));
    yield put(audioConnected());
  } catch (error) {
    yield call(logError, "unable to create stream", error);
    yield put(mediaStreamError());
  }
};

const tearDown = function* (): SagaIterator<void> {
  yield call(log, "disconnecting from audio");

  yield put(requestBroadcastRecorder());
  const { payload: recorder }: SagaReturnType<typeof broadcastedRecorder> =
    yield take(broadcastedRecorder);

  if (recorder) {
    yield call(stopMediaRecorder, recorder);
    yield call(log, "recorder stopped");
    yield put(clearRecorder());
  }

  yield put(requestBroadcastStream());
  const { payload: stream }: SagaReturnType<typeof broadcastedStream> =
    yield take(broadcastedStream);

  if (stream) {
    yield call(stopStream, stream);
    yield call(log, "stream stopped");
    yield put(clearStream());
  }

  yield put(trackStoppedRecording());
};

export const setUpAudioConnection = function* (): SagaIterator<void> {
  let task: Task | null = null;

  yield takeEvery(requestConnectAudio, function* ({ payload: newListener }) {
    if (task) {
      yield put(requestDisconnectAudio());
      yield take(disconnectedAudio);
    }

    task = yield fork(setUp, newListener);

    try {
      yield race([take(requestConnectAudio), take(requestDisconnectAudio)]);
    } finally {
      if (task) {
        yield cancel(task);
        yield fork(tearDown);
      }
      task = null;
      yield put(disconnectedAudio());
    }
  });

  // Reconnect on audio device change
  yield takeEvery(setSelectedInputDeviceId, function* () {
    yield call(
      log,
      "audio device change detected, attempting to reconnect audio"
    );
    yield put(requestBroadcastIsRecording());
    const { payload: listener }: SagaReturnType<typeof broadcastedIsRecording> =
      yield take(broadcastedIsRecording);

    if (listener === null) {
      yield call(log, "no existing listener found, skipping reconnection");
      return;
    }

    yield call(log, "disconnecting audio for reconnection");
    yield put(requestDisconnectAudio());
    yield take(disconnectedAudio);
    yield call(log, "reconnecting audio with new listener");
    yield put(requestConnectAudio(listener));
  });
};
