import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import { setDocumentChildren } from "../../reducers";
import { createEditorChildrenChangeChannel } from "../../utils/createEditorChildrenChangeChannel";
import { editorLoaded } from "../actions";

export const persistChildren = function* (): SagaIterator<void> {
  yield takeLatest(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      const { documentUUID: id } = editor;

      if (!id) {
        return;
      }

      const childrenChangeEventChannel: SagaReturnType<
        typeof createEditorChildrenChangeChannel
      > = yield call(createEditorChildrenChangeChannel, editor);

      yield takeEvery(childrenChangeEventChannel, function* () {
        const { children } = editor;
        yield put(setDocumentChildren({ id, children }));
      });
    }
  );
};
