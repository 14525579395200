import type {
  DispatchProps,
  StateProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { InteractionEvent } from "@talktype/types";
import type { LibraryAddDocumentButtonProps } from "@talktype/ui/src/Library/LibraryAddDocumentButton";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { requestAddDocument } from "@talktype/editor/src/sagas/actions";
import { selectPlatform } from "@talktype/system/src/reducers/system/selectors/selectPlatform";
import { LibraryAddDocumentButton as Component } from "@talktype/ui/src/Library/LibraryAddDocumentButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapStateToProps = (
  state: RootState
): StateProps<LibraryAddDocumentButtonProps> => ({
  context: getAppContext(),
  platform: selectPlatform(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<LibraryAddDocumentButtonProps> => ({
  onClick: (event: InteractionEvent<HTMLButtonElement>) =>
    dispatch(
      requestAddDocument({
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const LibraryAddDocumentButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
