import type { InteractionEvent } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { X } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./drawerHeader.module.scss";
import { StandardButton } from "../StandardButton";
import { Tooltip } from "../Tooltip";

const close = "Close";
const messages = { visible: [close] };

export type DrawerHeaderProps = {
  title: string;
  children?: ReactNode;
  onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const DrawerHeader = ({
  children,
  title,
  onClose,
}: DrawerHeaderProps): ReactElement => (
  <header className={styles.header}>
    <div className={styles.headerInner}>
      <h2
        className={classNames(
          styles.title,
          createSelectorClassName("title", "element")
        )}
      >
        {title}
      </h2>

      <Tooltip messages={messages} placement={{ x: "center", y: "bottom" }}>
        <StandardButton
          colour="neutral"
          hierarchy="link"
          size="xl"
          style="icon-only"
          label={close}
          mainIcon={<X />}
          elementProps={{
            onClick: onClose,
            className: createSelectorClassName("close", "interactive"),
          }}
        />
      </Tooltip>
    </div>

    {children}
  </header>
);
