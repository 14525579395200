import type {
  CustomShortcut,
  CustomWord,
  PartialPreferences,
} from "@talktype/types";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-preferences");

export const requestLegacyPreferences = action<
  void,
  "request-legacy-preferences"
>("request-legacy-preferences");

export const gotLegacyPreferences = action<
  string | null,
  "got-legacy-preferences"
>("got-legacy-preferences");

export const requestReshapeLegacyPreferences = action<
  string,
  "request-reshape-legacy-preferences"
>("request-reshape-legacy-preferences");

export const reshapedLegacyPreferences = action<
  null | PartialPreferences,
  "reshaped-legacy-preferences"
>("reshaped-legacy-preferences");

export const requestAddCustomShortcut = action<
  Omit<CustomShortcut, "id">,
  "request-add-custom-shortcut"
>("request-add-custom-shortcut");

export const requestEditCustomShortcut = action<
  CustomShortcut,
  "request-edit-custom-shortcut"
>("request-edit-custom-shortcut");

export const requestAddCustomWord = action<
  Omit<CustomWord, "id">,
  "request-add-custom-word"
>("request-add-custom-word");

export const requestEditCustomWord = action<
  CustomWord,
  "request-edit-custom-word"
>("request-edit-custom-word");

export const requestDeleteCustomShortcut = action<
  CustomShortcut["id"],
  "request-delete-custom-shortcut"
>("request-delete-custom-shortcut");

export const requestDeleteCustomWord = action<
  CustomWord["id"],
  "request-delete-custom-word"
>("request-delete-custom-word");

export const requestSetSelectedInputDeviceId = action<
  string,
  "request-set-selected-input-device-id"
>("request-set-selected-input-device-id");
