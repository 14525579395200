import { matchLongestString } from "./matchLongestString";

/**
 * Maps the longest matching string from the text to its corresponding value
 * in the targets map.
 *
 * @param text - The text to search within.
 * @param targets - The map of target strings to their corresponding values.
 * @returns The value corresponding to the longest matching string or `null`
 * if no match is found.
 *
 * @example
 * const targets = new Map([['twenty', 20], ['five', 5], ['twenty five', 25]]);
 * matchLongestMap('I have twenty five apples', targets);
 * // Returns 25
 *
 * @example
 * const targets = new Map([['twenty', 20], ['five', 5]]);
 * matchLongestMap('I have five apples', targets);
 * // Returns 5
 *
 * @example
 * const targets = new Map([['twenty', 20], ['five', 5]]);
 * matchLongestMap('I have ten apples', targets);
 * // Returns null
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const matchLongestMap = <Targets extends Map<string, any>>(
  text: string,
  targets: Targets
): (Targets extends Map<string, infer Target> ? Target : never) | null => {
  const targetKeys = [...targets.keys()];
  const longestMatch = matchLongestString(text, targetKeys);

  if (longestMatch === null) {
    return null;
  }

  const value = targets.get(longestMatch);

  return value ?? null;
};
