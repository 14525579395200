import type { SelectProps } from "../../Select";
import type { ReactElement } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { Field } from "../../Field";
import { Select } from "../../Select";

export type MicrophoneFieldProps = SelectProps;

export const MicrophoneField = ({
  disabled,
  ...selectProps
}: MicrophoneFieldProps): ReactElement => (
  <Field
    label={{ text: "Microphone", visible: true }}
    explainer={null}
    error={null}
    className={createSelectorClassName("microphone-field", "interactive")}
    disabled={disabled}
  >
    {disabled ? (
      <Select
        {...selectProps}
        options={[{ value: "", display: "Microphone permission required" }]}
        value={null}
        disabled
      />
    ) : (
      <Select {...selectProps} />
    )}
  </Field>
);
