import type { AppContext } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { Plus } from "@phosphor-icons/react";
import { useMemo } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { ToggleableButton } from "../../ToggleableButton";

const getMessages = ({
  platform,
  context,
}: {
  platform: Platform;
  context: AppContext;
}): { visible: string[] } => ({
  visible: [
    "Create a document",
    ...(context === "desktop"
      ? [platform === "mac" ? "⌘ + ⇧ + N" : "Ctrl + ⇧ + N"]
      : []),
    `"TalkType, New Document"`,
  ],
});
export type LibraryAddDocumentButtonProps = {
  context: AppContext;
  platform: Platform;
  onClick: (event: InteractionEvent<HTMLButtonElement>) => void;
};

export const LibraryAddDocumentButton = ({
  context,
  platform,
  onClick,
}: LibraryAddDocumentButtonProps): ReactElement => {
  const messages = useMemo(
    () => getMessages({ platform, context }),
    [platform, context]
  );

  return (
    <ToggleableButton
      buttonProps={{
        onClick,
        Icon: Plus,
        active: false,
        highlight: false,
        className: createSelectorClassName(
          "library-add-document-button",
          "interactive"
        ),
      }}
      tooltipProps={{ messages, placement: { x: "center", y: "bottom" } }}
    />
  );
};
