import type {
  DispatchProps,
  StateProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { ColourScheme } from "@talktype/types/src/ColourScheme";
import type { ThemeFieldProps } from "@talktype/ui/src/PreferencesModal/ThemeField";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { setColourScheme } from "@talktype/preferences";
import { ThemeField as Component } from "@talktype/ui/src/PreferencesModal/ThemeField";

const mapStateToProps = ({
  preferences: { colourScheme },
}: RootState): StateProps<ThemeFieldProps> => ({ selectedTheme: colourScheme });

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<ThemeFieldProps> => ({
  onChange: (value: ColourScheme): void => {
    dispatch(broadcast(setColourScheme(value)));
  },
});

export const ThemeField = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
