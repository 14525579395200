import type { MicrophoneListener } from "../types";

import { createSagaAction } from "@carescribe/utilities/src/saga";

export const requestConnectAudio = createSagaAction<
  MicrophoneListener,
  "request-connect-audio"
>("request-connect-audio");

export const requestDisconnectAudio = createSagaAction<
  void,
  "request-audio-disconnect"
>("request-audio-disconnect");

export const disconnectedAudio = createSagaAction<void, "disconnected-audio">(
  "disconnected-audio"
);

export const createdAudioStream = createSagaAction<
  MediaStream,
  "created-audio-stream"
>("created-audio-stream");

export const createdMediaRecorder = createSagaAction<
  MediaRecorder,
  "created-media-recorder"
>("created-media-recorder");

export const requestInputDevices = createSagaAction<
  void,
  "request-input-devices"
>("request-input-devices");

export const requestedInputDevices = createSagaAction<
  void,
  "requested-input-devices"
>("requested-input-devices");

export const requestPromptMicrophonePermissions = createSagaAction<
  void,
  "request-prompt-microphone-permissions"
>("request-prompt-microphone-permissions");

export const promptedMicrophonePermissions = createSagaAction<
  void,
  "prompted-microphone-permissions"
>("prompted-microphone-permissions");

export const requestRefreshPermissions = createSagaAction<
  void,
  "request-refresh-permissions"
>("request-refresh-permissions");

export const audioConnected = createSagaAction<void, "audio-connected">(
  "audio-connected"
);

export const notFoundUserMedia = createSagaAction<void, "not-found-user-media">(
  "not-found-user-media"
);

export const mediaStreamError = createSagaAction<void, "media-stream-error">(
  "media-stream-error"
);

export const setStream = createSagaAction<MediaStream, "set-stream">(
  "set-stream"
);

export const clearStream = createSagaAction<void, "clear-stream">(
  "clear-stream"
);

export const requestBroadcastStream = createSagaAction<
  void,
  "request-broadcast-stream"
>("request-broadcast-stream");

export const broadcastedStream = createSagaAction<
  MediaStream | null,
  "broadcasted-stream"
>("broadcasted-stream");

export const setRecorder = createSagaAction<MediaRecorder, "set-recorder">(
  "set-recorder"
);

export const clearRecorder = createSagaAction<void, "clear-recorder">(
  "clear-recorder"
);

export const requestBroadcastRecorder = createSagaAction<
  void,
  "request-broadcast-recorder"
>("request-broadcast-recorder");

export const broadcastedRecorder = createSagaAction<
  MediaRecorder | null,
  "broadcasted-recorder"
>("broadcasted-recorder");

export const requestBroadcastIsRecording = createSagaAction<
  void,
  "request-broadcast-is-recording"
>("request-broadcast-is-recording");

export const broadcastedIsRecording = createSagaAction<
  MicrophoneListener | null,
  "broadcasted-is-recording"
>("broadcasted-is-recording");

export const trackStartedRecording = createSagaAction<
  MicrophoneListener,
  "track-started-recording"
>("track-started-recording");

export const trackStoppedRecording = createSagaAction<
  void,
  "track-stopped-recording"
>("track-stopped-recording");
