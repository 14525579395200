import type { DictationStatus } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { Microphone, MicrophoneSlash } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { Spinner } from "@talktype/icons/src/Spinner";

import styles from "./statusIndicator.module.scss";
import { IconContainer } from "../../IconContainer";

type StatusIndicatorProps = {
  status: DictationStatus;
  miniSoundEqualiser: ReactNode;
};

export const StatusIndicator = ({
  status,
  miniSoundEqualiser,
}: StatusIndicatorProps): ReactElement => (
  <div
    className={classNames(
      createSelectorClassName(status, "element"),
      styles[status],
      styles.container
    )}
  >
    <IconContainer className={styles.inactiveIcon}>
      <Microphone />
    </IconContainer>

    <IconContainer className={styles.loadingIcon}>
      <Spinner className={styles.spinner} />
    </IconContainer>

    <IconContainer className={styles.activeIcon}>
      <MicrophoneSlash />
    </IconContainer>

    <div className={styles.miniSoundEqualiser}>{miniSoundEqualiser}</div>
  </div>
);
