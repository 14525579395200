import type { SagaIterator } from "redux-saga";

import { takeEvery, call } from "redux-saga/effects";

import { scrollCursorIntoView } from "../../utils/scrollCursorIntoView";
import { editorSelectionChanged } from "../actions";

export const keepCursorWithinBounds = function* (): SagaIterator<void> {
  yield takeEvery(editorSelectionChanged, function* () {
    yield call(scrollCursorIntoView);
  });
};
