import type { PayloadAction } from "@reduxjs/toolkit";
import type { InteractionMethod } from "@talktype/analytics";
import type { Preferences } from "@talktype/types";

type CloseLibraryDrawer = (
  state: Preferences,
  action: PayloadAction<{ interactionMethod: InteractionMethod }>
) => void;

export const closeLibraryDrawer: CloseLibraryDrawer = (state) => {
  state.isLibraryDrawerOpen = false;
};
