import type { DispatchProps } from "@carescribe/types/src/utils/connect";
import type { AppDispatch } from "@talktype/store";
import type { InteractionEvent } from "@talktype/types";
import type { LibraryOpenButtonProps } from "@talktype/ui/src/Library/LibraryOpenButton";

import { connect } from "react-redux";

import { openLibraryDrawer } from "@talktype/preferences";
import { LibraryOpenButton as Component } from "@talktype/ui/src/Library/LibraryOpenButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<LibraryOpenButtonProps> => ({
  onClick: (event: InteractionEvent<HTMLButtonElement>) =>
    dispatch(
      openLibraryDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const LibraryOpenButton = connect(null, mapDispatchToProps)(Component);
