import type { AppDispatch } from "@talktype/store";
import type { UpdateAvailableToast as UpdateAvailableToastType } from "@talktype/types";
import type { UpdateAvailableToastProps } from "@talktype/ui/src/UpdateAvailableToast";

import { connect } from "react-redux";

import { UpdateAvailableToast as Component } from "@talktype/ui/src/UpdateAvailableToast";
import { requestInstallUpdate } from "@talktype/updater";

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { update }: UpdateAvailableToastType
): Pick<UpdateAvailableToastProps, "onClick"> => ({
  onClick: () => dispatch(requestInstallUpdate({ type: update.type })),
});

export const UpdateAvailableToast = connect(
  null,
  mapDispatchToProps
)(Component);
