import type { CombinedState } from "../CombinedState";

import { sliceName } from "../index";

/**
 * Selects PKCE from state.
 */
export const selectPkce = (
  state: CombinedState
): CombinedState[typeof sliceName] => state[sliceName];
