import type { FeatureFlagsState } from ".";

import { createSelector } from "@reduxjs/toolkit";

import { selectParsedAppVersion } from "@talktype/system/src/reducers/system/selectors/selectParsedAppVersion";

import { sliceName } from ".";

export type CombinedState = {
  [sliceName]: FeatureFlagsState;
};

export const selectFeatureFlags = (state: CombinedState): FeatureFlagsState =>
  state[sliceName];

export const selectDictateToAppIsEnabled = createSelector(
  selectFeatureFlags,
  (state) => state.dictateToApp
);

export const selectChameleonIsEnabled = createSelector(
  selectFeatureFlags,
  (state) => state.chameleon
);

export const selectCompactLayoutIsEnabled = createSelector(
  selectParsedAppVersion,
  (appVersion) =>
    appVersion ? appVersion.major >= 3 && appVersion.minor >= 3 : false
);
