import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpAudioConnection } from "./setUpAudioConnection";
import { setUpInputDevices } from "./setUpInputDevices";
import { setUpRequestMicrophonePermissions } from "./setUpRequestMicrophonePermissions";
import { setUpSelectedInputDeviceFallback } from "./setUpSelectedInputDeviceFallback";
import { setUpTrackFrequencies } from "./setUpTrackFrequencies";
import { setUpTrackIsRecording } from "./setUpTrackIsRecording";
import { setUpTrackMicrophonePermissions } from "./setUpTrackMicrophonePermissions";
import { setUpTrackRecorder } from "./setUpTrackRecorder";
import { setUpTrackStream } from "./setUpTrackStream";
import { setUpTrackVolume } from "./setUpTrackVolume";

export const initialiseAudio = function* (): SagaIterator<void> {
  yield all(
    [
      setUpAudioConnection,
      setUpInputDevices,
      setUpRequestMicrophonePermissions,
      setUpSelectedInputDeviceFallback,
      setUpTrackFrequencies,
      setUpTrackIsRecording,
      setUpTrackMicrophonePermissions,
      setUpTrackRecorder,
      setUpTrackStream,
      setUpTrackVolume,
    ].map(retry)
  );
};
