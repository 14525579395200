import { createGuard, P } from "@carescribe/utilities/src/guards/createGuard";

export type UnauthorisedErrorResponse = { status: 401; error: string };

export const validUnauthorizedResponse = {
  status: 401,
  error: P.string,
} as const;

export const isUnauthorisedResponse = createGuard<UnauthorisedErrorResponse>(
  validUnauthorizedResponse
);
