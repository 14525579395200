import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { requestOpenMicrophonePrivacySettings } from "./actions";

export const setUpOpenMicrophonePrivacySettings =
  function* (): SagaIterator<void> {
    yield takeEvery(requestOpenMicrophonePrivacySettings, function* () {
      const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

      const openMicrophonePrivacySettings =
        ipc?.system?.openMicrophonePrivacySettings?.request?.v1;

      if (!openMicrophonePrivacySettings) {
        return;
      }

      yield call(openMicrophonePrivacySettings);
    });
  };
