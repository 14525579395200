import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./field.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

type FieldProps = {
  label: { text: string; visible: boolean };
  explainer: { text: string } | null;
  error: { text: string; props?: React.HTMLAttributes<HTMLSpanElement> } | null;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
};

export const Field = ({
  label,
  explainer,
  error,
  children,
  className,
  disabled,
}: FieldProps): ReactElement => (
  <label className={classNames(styles.field, className)}>
    {label.visible ? (
      <span className={classNames(styles.label, [disabled, styles.disabled])}>
        {label.text}
      </span>
    ) : (
      <ScreenReaderOnly tag="span">{label.text}</ScreenReaderOnly>
    )}

    <span className={styles.input}>{children}</span>

    {explainer && <span className={styles.explainer}>{explainer.text}</span>}

    {error && (
      <span
        {...error?.props}
        aria-live="polite"
        className={classNames(styles.error, error.props?.className)}
        dangerouslySetInnerHTML={{ __html: error.text }}
      />
    )}
  </label>
);
