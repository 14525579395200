import type { RefObject } from "react";

/**
 * These values roughly follow CSS's `<position-area>` model,
 * making it easier to convert to once CSS anchoring has widespread
 * browser support.
 *
 * See {@link https://developer.mozilla.org/en-US/docs/Web/CSS/position-area#description}
 *
 * @example
 * ```
 * +-------+-------+-------+
 * | Top   | Top   | Top   |
 * | Left  | Center| Right |
 * +-------+-------+-------+
 * | Center| Anchor| Center|
 * | Left  |       | Right |
 * +-------+-------+-------+
 * | Bottom| Bottom| Bottom|
 * | Left  | Center| Right |
 * +-------+-------+-------+
 * ```
 *
 * Additionally, it supports the "auto" value, which uses the anchor
 * element's axis directly to determine the placement of the anchoree instead
 * of relying on anchoring areas.
 */
export type Placement = {
  x: "left" | "center" | "right" | "auto";
  y: "top" | "center" | "bottom" | "auto";
  xOffset?: number;
  yOffset?: number;
};

const calculatePosition = (
  anchorRect: DOMRect,
  anchoreeRect: DOMRect,
  placement: Placement
): { top: number; left: number } => {
  const top =
    placement.y === "auto"
      ? anchorRect.bottom
      : placement.y === "top"
      ? anchorRect.top - anchoreeRect.height
      : placement.y === "bottom"
      ? anchorRect.bottom
      : anchorRect.top + (anchorRect.height - anchoreeRect.height) / 2;

  const left =
    placement.x === "auto"
      ? anchorRect.left
      : placement.x === "left"
      ? anchorRect.left - anchoreeRect.width
      : placement.x === "right"
      ? anchorRect.right
      : anchorRect.left + (anchorRect.width - anchoreeRect.width) / 2;

  const xOffset = placement.xOffset ?? 0;
  const yOffset = placement.yOffset ?? 0;

  return { top: top + yOffset, left: left + xOffset };
};

/**
 * Anchors a popover element to its target anchor element.
 *
 * This is especially useful in the case of popovers created with the
 * Popover API, where they are positioned relative to the viewport.
 * When CSS anchoring becomes available in Safari, it may then be preferable
 * to use that instead (see {@link https://caniuse.com/css-anchor-positioning}).
 *
 * @params anchorRef - The reference to the anchor element
 * @params popoverRef - The reference to the popover element
 * @params placement - The placement of the popover relative to the anchor
 *
 * @example
 * ```
 * const anchorRef = useRef<HTMLElement>(null);
 * const popoverRef = useRef<HTMLElement>(null);
 * anchor({ anchorRef, popoverRef, placement: { x: "center", y: "top" } });
 * ```
 */
export const anchor = <
  AnchorElement extends Element,
  AnchoreeElement extends HTMLElement
>({
  anchorRef,
  anchoreeRef,
  placement,
}: {
  anchorRef: RefObject<AnchorElement>;
  anchoreeRef: RefObject<AnchoreeElement>;
  placement: Placement;
}): void => {
  const anchor = anchorRef.current;
  const anchoree = anchoreeRef.current;
  if (!anchor || !anchoree) {
    return;
  }

  const anchorRect = anchor.getBoundingClientRect();
  const anchoreeRect = anchoree.getBoundingClientRect();

  const { left, top } = calculatePosition(anchorRect, anchoreeRect, placement);

  anchoree.style.top = `${top}px`;
  anchoree.style.left = `${left}px`;
};
