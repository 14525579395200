import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, takeEvery } from "redux-saga/effects";

import { isChildren } from "@talktype/store/src/persistence/versioned/v1/guards";

import { selectCurrentDocument, setDocumentChildren } from "../../reducers";
import { emptyChildren } from "../../utils/entities/emptyChildren";
import { logError } from "../../utils/log";
import { recoverCorruptedChildren } from "../../utils/recoverCorruptedChildren";
import { editorErrored } from "../actions";

/**
 * Whenever an error in the editor is detected, this saga
 * revalidates the current document:
 *  - attempts recovery of text contents if it is corrupted
 *  - reverts to an empty text otherwise
 *  - history and selection are always reset
 */
export const revalidateDocumentOnError = function* (): SagaIterator<void> {
  yield takeEvery(
    editorErrored,
    function* ({ payload: cause }): SagaIterator<void> {
      yield call(logError, new Error("Editor error detected", { cause }));

      const document: SagaReturnType<typeof selectCurrentDocument> =
        yield select(selectCurrentDocument);

      if (document === null) {
        return;
      }

      const { id, children } = document;

      const isValidChildren: SagaReturnType<typeof isChildren> = yield call(
        isChildren,
        document.children
      );

      if (isValidChildren) {
        /**
         * While children found to be valid, the source of the error may relate
         * to the history or selection.
         *
         * And so, we reset the document state (including history, selection),
         * while retaining only the children.
         */
        yield put(setDocumentChildren({ id, children }));

        return;
      }

      yield call(logError, new Error("Document is corrupted", { cause }));

      const recoveredChildren: SagaReturnType<typeof recoverCorruptedChildren> =
        yield call(recoverCorruptedChildren, children);

      yield put(
        setDocumentChildren({
          id,
          children: recoveredChildren ?? emptyChildren,
        })
      );
    }
  );
};
