import type { ReactElement, ReactNode } from "react";

import styles from "./libraryHeader.module.scss";

export type LibraryHeaderProps = { children: ReactNode };

export const LibraryHeader = ({
  children,
}: LibraryHeaderProps): ReactElement => (
  <header className={styles.header}>{children}</header>
);
