import type { RootState } from "../types";
import type { Middleware } from "@reduxjs/toolkit";

import { replaceState } from "../actions";
import { logError } from "../log";
/**
 * Creates a middleware that persists some of the application state to
 * IndexedDB.
 *
 * - When the user id changes, the state is re-hydrated, pulling in
 *   that user's data from storage.
 * - Saving of state happens whenever the state changes as a result of an
 *   action, this is throttled to 250ms.
 * - Prevents the user from leaving the page while saving.
 */
export const createPersistenceMiddleware =
  ({
    initialState,
    persistState,
    checkShouldRehydrate,
    rehydrateUserState,
  }: {
    initialState: RootState;
    persistState: (state: RootState) => Promise<void>;
    checkShouldRehydrate: (
      previousState: RootState,
      newState: RootState
    ) => boolean;
    rehydrateUserState: ({
      state,
      initialState,
    }: {
      state: RootState;
      initialState: RootState;
    }) => Promise<Partial<RootState>>;
  }): Middleware =>
  ({ dispatch, getState }) =>
  (next) => {
    let cleanup: (() => void) | null = null;
    let isNotPersisted = false;

    // Prevent the user from leaving the page while there is unpersisted data
    window.addEventListener(
      "beforeunload",
      (event) => isNotPersisted && event.preventDefault()
    );

    return async (action) => {
      const previousState: RootState = getState();
      const result = next(action);
      const state: RootState = getState();
      const stateChanged = previousState !== state;
      const shouldRehydrate = checkShouldRehydrate(previousState, state);

      if (!stateChanged) {
        return result;
      }

      if (shouldRehydrate) {
        try {
          const state = getState();
          const newState = await rehydrateUserState({ state, initialState });
          dispatch(replaceState(newState));
        } catch (cause) {
          logError(new Error("Failed to re-hydrate user state", { cause }));
        }
        return next(action);
      }

      cleanup?.();
      isNotPersisted = true;

      const persist = async (): Promise<void> => {
        try {
          await persistState(state);
          isNotPersisted = false;
        } catch (cause) {
          logError(new Error("Failed to persist state", { cause }));
        }
      };

      const THROTTLE_SAVE_MS = 250;
      const timeoutId = setTimeout(persist, THROTTLE_SAVE_MS);

      cleanup = (): void => clearTimeout(timeoutId);

      return result;
    };
  };
