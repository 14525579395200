import type {
  StateProps,
  DispatchProps,
} from "@carescribe/types/src/utils/connect";
import type { AppDispatch, RootState } from "@talktype/store";
import type { MicrophoneFieldProps } from "@talktype/ui/src/PreferencesModal/MicrophoneField";

import { connect } from "react-redux";

import {
  selectSelectedInputDevice,
  selectIsMicrophonePermissionGranted,
} from "@carescribe/audio/src/reducer";

import { requestSetSelectedInputDeviceId } from "@talktype/preferences/src/sagas/actions";
import { MicrophoneField as Component } from "@talktype/ui/src/PreferencesModal/MicrophoneField";

import { selectInputDeviceOptions } from "./selectInputDeviceOptions";

const mapStateToProps = (
  state: RootState
): StateProps<MicrophoneFieldProps> => ({
  value: selectSelectedInputDevice(state),
  options: selectInputDeviceOptions(state),
  disabled: !selectIsMicrophonePermissionGranted(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): DispatchProps<MicrophoneFieldProps> => ({
  onChange: ({ currentTarget: { value } }): void => {
    dispatch(requestSetSelectedInputDeviceId(value));
  },
});

export const MicrophoneField = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
