import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { setInProgressResult as setInProgressResultReducer } from "./reducers";

const slice = createSlice({
  name: "results",
  initialState,
  reducers: { setInProgressResult: setInProgressResultReducer },
});

export const sliceName = slice.name;

export const reducer = { [sliceName]: slice.reducer };

export const { setInProgressResult } = slice.actions;
