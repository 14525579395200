import type { RootState } from "@talktype/store";
import type { AppControlsProps } from "@talktype/ui/src/AppControls";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectIsLibraryDrawerOpen } from "@talktype/preferences/src/reducers/preferences/selectors";
import { AppControls as Component } from "@talktype/ui/src/AppControls";

import { LibraryAddDocumentButton } from "./LibraryAddDocumentButton";
import { LibraryOpenButton } from "./LibraryOpenButton";
import { QuickNav } from "./QuickNav";

const openLibraryButton = <LibraryOpenButton />;
const quickNav = <QuickNav />;
const libraryAddDocumentButton = <LibraryAddDocumentButton />;

const mapStateToProps = (state: RootState): AppControlsProps => ({
  isLibraryDrawerOpen: selectIsLibraryDrawerOpen(state),
  isRounded: getAppContext() === "desktop",
  libraryAddDocumentButton,
  openLibraryButton,
  quickNav,
});

export const AppControls = connect(mapStateToProps)(Component);
