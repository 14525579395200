import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./appControls.module.scss";

export type AppControlsProps = {
  isLibraryDrawerOpen: boolean;
  isRounded: boolean;
  libraryAddDocumentButton: ReactNode;
  openLibraryButton: ReactNode;
  quickNav: ReactNode;
};

export const AppControls = ({
  isLibraryDrawerOpen,
  isRounded,
  libraryAddDocumentButton,
  openLibraryButton,
  quickNav,
}: AppControlsProps): ReactElement => {
  const libraryVisibility = isLibraryDrawerOpen
    ? styles.libraryOpen
    : styles.libraryClosed;

  return (
    <div className={classNames(styles.container, [isRounded, styles.rounded])}>
      <div className={classNames(styles.controls, libraryVisibility)}>
        <div className={styles.openLibraryButton}>{openLibraryButton}</div>
        <div className={styles.addDocumentButton}>
          {libraryAddDocumentButton}
        </div>
      </div>

      {quickNav}
    </div>
  );
};
