import type { InitialResult } from "@talktype/types";

import { createGuard, P } from "@carescribe/utilities/src/guards/createGuard";

export type ReadyMessage = {
  responseType: "ready";
};

export const isReadyMessage = createGuard<ReadyMessage>({
  responseType: "ready",
});

export type ResultMessage = {
  responseType: "dictation";
  payload: InitialResult;
};

export const isResultMessage = createGuard<ResultMessage>({
  responseType: "dictation",
  payload: {
    resultId: P.string.minLength(1),
    transcript: P.string,
    isFinal: P.boolean,
  },
});

export type PingPongMessage = {
  responseType: "pong";
};

export const isPingPongMessage = createGuard<PingPongMessage>({
  responseType: "pong",
});

export type StreamStoppedError = {
  code: "stream-stopped";
  reason: string;
};

export type SilenceDetected = {
  code: "silence-detected";
  reason: string;
};

export type AudioProcessingError = {
  code: "audio-processing-error";
  reason: string;
};

export type Error = StreamStoppedError | SilenceDetected | AudioProcessingError;

export type ErrorMessage = {
  responseType: "error";
  payload: Error;
};

export const isErrorMessage = createGuard<ErrorMessage>({
  responseType: "error",
  payload: {
    code: P.string,
    reason: P.string,
  },
});

export type Message =
  | ReadyMessage
  | ResultMessage
  | PingPongMessage
  | ErrorMessage;
