import type { Platform } from "@carescribe/types/src/Platform";

declare global {
  interface Navigator {
    userAgentData?: {
      brands: {
        brand: "Microsoft Edge" | "Chromium";
        version: `${number}`;
      }[];
      mobile: boolean;
    };
  }
}

export const getQueryStringValue = (queryKey: string): string | null => {
  const queryParameters = new URLSearchParams(window.location.search);
  return queryParameters.get(queryKey);
};

export const loadURL = (url: string): void => {
  console.info(`Redirecting to ${url}`);
  window.location.assign(url);
};

const hasBrowserBrand = (brand: "Microsoft Edge" | "Chromium"): boolean => {
  /*
   * Detecting Edge via useragent string is unreliable.
   * `navigator.userAgentData` is preferred, however is unavailable in Safari
   * https://learn.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-guidance
   */
  if (!("userAgentData" in navigator && !!navigator.userAgentData)) {
    return false;
  }

  return navigator.userAgentData.brands.some((b) => b.brand === brand);
};

export const isEdge = (): boolean => hasBrowserBrand("Microsoft Edge");

export const isChrome = (): boolean => hasBrowserBrand("Chromium") && !isEdge();

export const isSafari = (): boolean => {
  return /Safari/i.test(navigator.userAgent) && !isChrome();
};

export const isAndroid = (): boolean => /Android/i.test(navigator.userAgent);

export const isMobile = (): boolean =>
  /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isAppleDevice = (): boolean =>
  /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);

export const isTouchDevice = (): boolean => {
  return window.matchMedia("(pointer:coarse)").matches;
};

export const usersBrowserName = (): "Edge" | "Chrome" | "Safari" | null => {
  switch (true) {
    case isEdge():
      return "Edge";
    case isChrome():
      return "Chrome";
    case isSafari():
      return "Safari";
  }
  return null;
};

export const isDesktopChromium = (): boolean => {
  return getPlatform() != null && (isChrome() || isEdge());
};

export const getPlatform = (): Platform => {
  const userAgent = window.navigator.userAgent;

  const osRegex =
    /(Windows NT|Windows Phone|iPhone|iPad|Android|Mac OS X|Linux)[^)]*/;

  const osMatch = userAgent.match(osRegex);

  let os = "";

  if (osMatch && osMatch.length > 0) {
    os = osMatch[0].trim();
  }

  if (os.indexOf("Mac") !== -1) {
    return "mac";
  }

  if (os.indexOf("Windows") !== -1) {
    return "win";
  }

  if (os.indexOf("Linux") !== -1) {
    return "linux";
  }

  return null;
};

export const reloadPage = (): void => window.location.reload();

export const getStorageDirectory: typeof navigator.storage.getDirectory = () =>
  navigator.storage.getDirectory();

export const createObjectURL: typeof URL.createObjectURL = (object) =>
  URL.createObjectURL(object);

export const createMediaRecorder = (
  mediaStream: MediaStream,
  options: MediaRecorderOptions
): MediaRecorder => new MediaRecorder(mediaStream, options);
