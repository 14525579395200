import type { ColourScheme } from "@talktype/types/src/ColourScheme";
import type { ReactElement } from "react";

import { ThemeSelector } from "@carescribe/ui/src/components/ThemeSelector";

import { colourSchemes } from "@talktype/config";

import { RadioGroup } from "../../RadioGroup";

export type ThemeFieldProps = {
  onChange: (theme: ColourScheme) => void;
  selectedTheme: ColourScheme;
};

export const ThemeField = ({
  selectedTheme,
  onChange,
}: ThemeFieldProps): ReactElement => (
  <RadioGroup legend={{ text: "Theme", visible: true }} layout="horizontal">
    <ThemeSelector<ColourScheme>
      selectedTheme={selectedTheme}
      setTheme={onChange}
      themes={colourSchemes}
    />
  </RadioGroup>
);
