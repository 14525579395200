import type { ReactElement, ReactNode } from "react";

import { useEffect, useRef } from "react";

import styles from "./regularLayout.module.scss";

export type EditorContainerProps = {
  children: ReactNode;
  handleScroll: (scrollTop: number) => void;
  scrollTop: number;
};

export const EditorContainer = ({
  children,
  handleScroll,
  scrollTop,
}: EditorContainerProps): ReactElement => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /**
     * If we don't defer this, sometimes the scroll doesn't happen
     * on slower machines
     */
    requestAnimationFrame(() => {
      container.current?.scrollTo({ top: scrollTop });
    });
  }, [scrollTop]);

  const onScroll = (): void => {
    if (container.current) {
      handleScroll(container.current.scrollTop);
    }
  };

  return (
    <div ref={container} onScroll={onScroll} className={styles.editor}>
      {children}
    </div>
  );
};
