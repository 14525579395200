import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { processedResult } from "@talktype/actions/src";

import {
  requestCancelTask,
  requestTrackPrompt,
  trackedPrompt,
} from "./actions";
import { performCommand } from "./performCommand";
import { taskRef } from "./task/trackTask";
import { isPromptWithCommand } from "../guards/isPromptWithCommand";

/**
 * This saga listens for any final results and performs finalisation
 * behaviours relating to voice commands.
 *
 * These include:
 *
 * - performing the action
 * - cancelling the task
 */
export const finaliseVoiceCommands = function* (): SagaIterator<void> {
  const { payload: task }: SagaReturnType<typeof taskRef> = yield take(taskRef);

  yield takeEvery(
    processedResult,
    function* ({ payload: { isFinal, resultId, targetApp } }) {
      if (!task.current.prompt) {
        // Order of this condition and the next is important. If we return on
        // final results without cancelling the task, it can cause the
        // listening UI to persist forever.
        yield put(requestCancelTask());
        return;
      }

      if (!isFinal) {
        return;
      }

      if (isPromptWithCommand(task.current.prompt)) {
        /*
         * Crucial that we grab a copy of the prompt and clear out the task
         * right away PRIOR to performing the command action.
         *
         * It's very possible that while a command is being actioned, another
         * result comes in, causing that same command to be actioned a second
         * time.
         */
        const prompt = task.current.prompt;
        yield put(requestTrackPrompt(null));
        yield take(trackedPrompt);

        yield call(performCommand, { resultId, prompt, targetApp });
      }

      yield put(requestCancelTask());
    }
  );
};
