import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setCurrentDocumentId } from "../../reducers";

export const setUpTrackCurrentDocument = function* (): SagaIterator<void> {
  yield takeEvery(setCurrentDocumentId, function* () {
    yield put(
      requestTrackEvent({
        name: "Document Changed",
        data: null,
      })
    );
  });
};
