import type { CommandConfig } from "../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { deleteDocument as performCommand } from "../../../sagas/commands/library/deleteDocument";
import { createCommandDefinition } from "../utils/createCommandDefinition";

export const deleteDocument: CommandConfig = createCommandDefinition({
  id: "deleteDocument",
  matchers: ["delete document", "delete the document"],
  deadline: toSeconds(0.5),
  messages: {
    successful: "Deleted the document",
    failed: "Sorry, couldn't delete the document",
  },
  performCommand: {
    talktype: performCommand(),
    app: null,
  },
  input: ["Delete document"],
  track: null,
});
