import type { SagaIterator } from "redux-saga";

import { all, put, take } from "redux-saga/effects";

import { audioConnected } from "@carescribe/audio/src/sagas/actions";

import { dictationStarted } from "@talktype/editor/src/reducers";
import { receivedReadySignal } from "@talktype/transcriber/src/sagas/actions";

/**
 * Lets the editor know when dictation is ready.
 */
export const manageDictationStatus = function* (): SagaIterator<void> {
  while (true) {
    yield all([take(receivedReadySignal), take(audioConnected)]);
    yield put(dictationStarted());
  }
};
