import type { ReactElement } from "react";

import { Editor as Component } from "./Editor";
import { ErrorBoundary } from "./ErrorBoundary";

const editor = <Component />;

export const Editor = (): ReactElement => (
  <ErrorBoundary>{editor}</ErrorBoundary>
);
