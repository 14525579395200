import type { UUID } from "@carescribe/types/src/UUID";
import type { UnreleasedFeatureFlag } from "@talktype/feature-flags/src/unreleased";

import { createGuard, P } from "@carescribe/utilities/src/guards/createGuard";

export const validMeResponse = {
  created_at: P.string,
  email: P.string,
  id: P.number,
  updated_at: P.string,
  uuid: P.string,
  simple_initials: P.string,
  full_name: P.string,
  release_toggles: P.array(P.string),
  organisation: P.union(null, {
    has_sso_configuration: P.boolean,
    name: P.string,
  }),
};

export const isMeResponse = createGuard<MeResponse>(validMeResponse);

export type MeResponse = {
  created_at: string;
  email: string;
  id: number;
  updated_at: string;
  uuid: UUID;
  simple_initials: string;
  full_name: string;
  release_toggles: UnreleasedFeatureFlag[];
  organisation: null | {
    has_sso_configuration: boolean;
    name: string;
  };
};
