import type { Listing } from "@talktype/types/src/Listing";

import { isUndefined } from "@carescribe/utilities/src/isUndefined";

export const createMatchers = (arrays: string[][]): string[] => {
  if (arrays.length === 1 && !isUndefined(arrays[0])) {
    return arrays[0];
  }

  const [first, ...rest] = arrays;
  const restCombined = createMatchers(rest);

  if (!first) {
    return [];
  }

  return first.flatMap((a) =>
    restCombined.map((b) => `${a} ${b}`.replace(/\s+/, " ").trim())
  );
};

const removeSpacesBefore = true;

const removeSpacesAfter = true;

const addSpaceAfter = true;

const capitalize = true;

export type Rule = Listing & {
  id: string;
  matchers: string[];
  removeSpacesAfter?: true;
  removeSpacesBefore?: true;
  removeSpacesAfterIfFollowedBy?: RegExp;
  capitalize?: true;
  requireBefore?: RegExp;
  addSpaceAfter?: true;
};

export const fullStop: Rule = {
  id: "fullStop",
  output: {
    value: ".",
    pronunciation: "a full stop character",
  },
  matchers: ["full stop", "period", "fullstop"],
  input: ["Full stop", "Period"],
  removeSpacesBefore,
  capitalize,
};

export const openDoubleQuote: Rule = {
  id: "openDoubleQuotes",
  output: {
    value: "“",
    pronunciation: "an opening double quotation mark character",
  },
  matchers: createMatchers([
    ["open", "opened", "opening"],
    ["double", ""],
    [
      "quotes",
      "quote",
      "inverted commas",
      "inverted comma",
      "quotation marks",
      "quotation mark",
      "quotation",
    ],
  ]),
  input: ["Open double quotes"],
  removeSpacesAfter,
};

export const closeDoubleQuote: Rule = {
  id: "closeDoubleQuotes",
  output: {
    value: "”",
    pronunciation: "a closing double quotation mark character",
  },
  matchers: createMatchers([
    ["close", "closed", "closing"],
    ["double", ""],
    [
      "quotes",
      "quote",
      "inverted commas",
      "inverted comma",
      "quotation marks",
      "quotation mark",
      "quotation",
    ],
  ]),
  input: ["Close double quotes"],
  removeSpacesBefore,
};

export const openSingleQuote: Rule = {
  id: "openSingleQuotes",
  output: {
    value: "‘",
    pronunciation: "an opening single quotation mark character",
  },
  input: ["Open single quotes"],
  matchers: createMatchers([
    ["open", "opened", "opening"],
    ["single"],
    [
      "quotes",
      "quote",
      "inverted commas",
      "inverted comma",
      "quotation marks",
      "quotation mark",
      "quotation",
    ],
  ]),
  removeSpacesAfter,
};

export const closeSingleQuote: Rule = {
  id: "closeSingleQuotes",
  output: {
    value: "’",
    pronunciation: "a closing single quotation mark character",
  },
  input: ["Close single quotes"],
  matchers: createMatchers([
    ["close", "closed", "closing"],
    ["single"],
    [
      "quotes",
      "quote",
      "inverted commas",
      "inverted comma",
      "quotation marks",
      "quotation mark",
      "quotation",
    ],
  ]),
  removeSpacesBefore,
};

export const apostrophe: Rule = {
  id: "apostrophe",
  output: {
    value: "'",
    pronunciation: "an apostrophe character",
  },
  input: ["Apostrophe"],
  matchers: ["apostrophe"],
  removeSpacesBefore,
};

export const comma: Rule = {
  id: "comma",
  output: {
    value: ",",
    pronunciation: "a comma character",
  },
  input: ["Comma"],
  matchers: ["comma"],
  removeSpacesBefore,
};

export const questionMark: Rule = {
  id: "questionMark",
  output: {
    value: "?",
    pronunciation: "a question mark character",
  },
  input: ["Question mark"],
  matchers: ["question mark"],
  removeSpacesBefore,
  capitalize,
};

export const atSign: Rule = {
  id: "atSign",
  output: {
    value: "@",
    pronunciation: "an at character",
  },
  input: ["At sign"],
  matchers: createMatchers([["at"], ["symbol", "sign"]]),
  removeSpacesBefore,
  removeSpacesAfter,
};

export const exclamationMark: Rule = {
  id: "exclamationMark",
  output: {
    value: "!",
    pronunciation: "an exclamation mark character",
  },
  input: ["Exclamation mark"],
  matchers: createMatchers([
    ["exclamation", "explanation"],
    ["mark", "point"],
  ]),
  removeSpacesBefore,
  capitalize,
};

export const ampersand: Rule = {
  id: "ampersand",
  output: {
    value: "&",
    pronunciation: "an ampersand character",
  },
  input: ["Ampersand"],
  matchers: ["ampersand"],
};

export const nDash: Rule = {
  id: "en dash",
  output: {
    value: "–",
    pronunciation: "an en dash character",
  },
  input: ["En dash"],
  matchers: createMatchers([["short", "e n", "n", "en"], ["dash"]]),
  removeSpacesAfter,
  removeSpacesBefore,
};

export const mDash: Rule = {
  id: "em dash",
  output: {
    value: "—",
    pronunciation: "an em dash character",
  },
  input: ["Em dash"],
  matchers: createMatchers([["long", "e m", "m", "em"], ["dash"]]),
  removeSpacesAfter,
  removeSpacesBefore,
};

export const hyphen: Rule = {
  id: "hyphen",
  output: {
    value: "-",
    pronunciation: "a hyphen character",
  },
  input: ["Hyphen", "Dash"],
  matchers: ["hyphen", "dash"],
  removeSpacesAfter,
  removeSpacesBefore,
};

export const percent: Rule = {
  id: "percent",
  output: {
    value: "%",
    pronunciation: "a percent character",
  },
  input: ["Percent"],
  matchers: createMatchers([
    ["percent", "precentage"],
    ["sign", "symbol", ""],
  ]),
  removeSpacesBefore,
};

export const asterisk: Rule = {
  id: "asterisk",
  output: {
    value: "*",
    pronunciation: "an asterisk character",
  },
  input: ["Asterisk"],
  matchers: ["asterisk", "star", "asterix"],
};

export const backSlash: Rule = {
  id: "backslash",
  output: {
    value: "\\",
    pronunciation: "a backslash character",
  },
  input: ["Backslash"],
  matchers: ["backslash"],
};

export const forwardSlash: Rule = {
  id: "forwardSlash",
  output: {
    value: "/",
    pronunciation: "a forward slash character",
  },
  input: ["Slash"],
  matchers: ["forward slash", "slash", "forwards slash"],
};

export const degree: Rule = {
  id: "degree",
  output: {
    value: "\u00b0",
    pronunciation: "a degree character",
  },
  input: ["Degree symbol"],
  matchers: createMatchers([
    ["degree", "degrees"],
    ["sign", "symbol"],
  ]),
  removeSpacesBefore,
};

export const hash: Rule = {
  id: "hash",
  output: {
    value: "#",
    pronunciation: "a hash character",
  },
  input: ["Hash"],
  matchers: ["hashtag", "hash"],
  removeSpacesAfter,
};

export const numberSign: Rule = {
  id: "numberSign",
  output: {
    value: "#",
    pronunciation: "a hash character",
  },
  input: ["Number sign"],
  matchers: ["number sign", "number symbol"],
  removeSpacesBefore,
};

export const semicolon: Rule = {
  id: "semicolon",
  output: {
    value: ";",
    pronunciation: "a semicolon character",
  },
  input: ["Semicolon"],
  matchers: ["semi-colon", "semi colon", "semicolon"],
  removeSpacesBefore,
};

export const colon: Rule = {
  id: "colon",
  output: {
    value: ":",
    pronunciation: "a colon character",
  },
  input: ["Colon"],
  matchers: ["colon"],
  removeSpacesBefore,
};

export const tilde: Rule = {
  id: "tilde",
  output: {
    value: "~",
    pronunciation: "a tilde character",
  },
  input: ["Tilde symbol"],
  matchers: createMatchers([["tilde"], ["symbol", "sign", ""]]),
  removeSpacesAfter,
};

export const openParenthesis: Rule = {
  id: "openParenthesis",
  output: {
    value: "(",
    pronunciation: "an opening parenthesis character",
  },
  input: ["Open brackets"],
  matchers: createMatchers([
    ["open", "opening", "opened"],
    ["brackets", "bracket", "parens", "paren", "parentheses", "parenthesis"],
  ]),
  removeSpacesAfter,
};

export const closeParenthesis: Rule = {
  id: "closeParenthesis",
  output: {
    value: ")",
    pronunciation: "a closing parenthesis character",
  },
  input: ["Close brackets"],
  matchers: createMatchers([
    ["close", "closing", "closed"],
    ["brackets", "bracket", "parens", "paren", "parentheses", "parenthesis"],
  ]),
  removeSpacesBefore,
};

export const openBrace: Rule = {
  id: "openBrace",
  output: {
    value: "{",
    pronunciation: "an opening brace character",
  },
  input: ["Open curly brackets"],
  matchers: [
    ...createMatchers([
      ["open", "opening", "opened"],
      ["curly"],
      ["brackets", "bracket", "brace", "braces"],
    ]),
    ...createMatchers([
      ["open", "opening", "opened"],
      ["brace", "braces"],
    ]),
  ],
  removeSpacesAfter,
};

export const closeBrace: Rule = {
  id: "closeBrace",
  output: {
    value: "}",
    pronunciation: "a closing brace character",
  },
  input: ["Close curly brackets"],
  matchers: [
    ...createMatchers([
      ["close", "closing", "closed"],
      ["curly"],
      ["brackets", "bracket", "brace", "braces"],
    ]),
    ...createMatchers([
      ["close", "closing", "closed"],
      ["brace", "braces"],
    ]),
  ],
  removeSpacesBefore,
};

export const openBracket: Rule = {
  id: "openBracket",
  output: {
    value: "[",
    pronunciation: "an opening square bracket character",
  },
  input: ["Open square brackets"],
  matchers: createMatchers([
    ["open", "opening", "opened"],
    ["square"],
    ["bracket", "brackets"],
  ]),
  removeSpacesAfter,
};

export const closeBracket: Rule = {
  id: "closeBracket",
  output: {
    value: "]",
    pronunciation: "a closing square bracket character",
  },
  input: ["Close square brackets"],
  matchers: createMatchers([
    ["close", "closing", "closed"],
    ["square"],
    ["bracket", "brackets"],
  ]),
  removeSpacesBefore,
};

export const openAngleBracket: Rule = {
  id: "openAngleBracket",
  output: {
    value: "<",
    pronunciation: "an opening angle bracket character",
  },
  input: ["Open angle brackets"],
  matchers: createMatchers([
    ["open", "opening", "opened"],
    ["angle", "angled"],
    ["bracket", "brackets"],
  ]),
  removeSpacesAfter,
};

export const closeAngleBracket: Rule = {
  id: "closeAngleBracket",
  output: {
    value: ">",
    pronunciation: "a closing angle bracket character",
  },
  input: ["Close angle brackets"],
  matchers: createMatchers([
    ["close", "closing", "closed"],
    ["angle", "angled"],
    ["bracket", "brackets"],
  ]),
  removeSpacesBefore,
};

export const lessThan: Rule = {
  id: "lessThan",
  output: {
    value: "<",
    pronunciation: "a less than character",
  },
  input: ["Less than sign"],
  matchers: ["less than sign"],
};

export const greaterThan: Rule = {
  id: "greaterThan",
  output: {
    value: ">",
    pronunciation: "a greater than character",
  },
  input: ["Greater than sign"],
  matchers: ["greater than sign", "more than sign"],
};

export const caret: Rule = {
  id: "caret",
  output: {
    value: "^",
    pronunciation: "a caret character",
  },
  input: ["Caret symbol"],
  matchers: createMatchers([["caret"], ["sign", "symbol", ""]]),
  removeSpacesAfter,
};

export const exponent: Rule = {
  id: "exponent",
  output: { value: "^", pronunciation: "an exponentiation character" },
  input: ["Exponent symbol"],
  matchers: createMatchers([
    ["exponent", "exponentiation"],
    ["sign", "symbol"],
  ]),
  removeSpacesBefore,
  removeSpacesAfter,
};

export const plusMinus: Rule = {
  id: "plusMinus",
  output: {
    value: "±",
    pronunciation: "a plus-minus character",
  },
  input: ["Plus or minus sign"],
  matchers: createMatchers([
    ["plus or minus", "plus-minus", "plus minus", "plusminus"],
    ["sign", "symbol"],
  ]),
};

export const plus: Rule = {
  id: "plus",
  output: {
    value: "+",
    pronunciation: "a plus character",
  },
  input: ["Plus sign"],
  matchers: createMatchers([["plus"], ["sign", "symbol"]]),
};

export const minus: Rule = {
  id: "minus",
  output: {
    value: "−",
    pronunciation: "a minus character",
  },
  input: ["Minus sign"],
  matchers: createMatchers([["minus"], ["sign", "symbol"]]),
};

export const multiplication: Rule = {
  id: "multiplication",
  output: {
    value: "×",
    pronunciation: "a multiplication character",
  },
  input: ["Multiplication sign"],
  matchers: createMatchers([["multiplication"], ["sign", "symbol"]]),
};

export const division: Rule = {
  id: "division",
  output: {
    value: "÷",
    pronunciation: "a division character",
  },
  input: ["Division sign"],
  matchers: createMatchers([["division"], ["sign", "symbol"]]),
};

export const equals: Rule = {
  id: "equalsSign",
  output: {
    value: "=",
    pronunciation: "an equals character",
  },
  input: ["Equals sign"],
  matchers: createMatchers([
    ["equals", "equal"],
    ["sign", "symbol"],
  ]),
};

export const euro: Rule = {
  id: "euro",
  output: {
    value: "€",
    pronunciation: "a euro character",
  },
  input: ["Euro symbol"],
  matchers: createMatchers([["euro"], ["sign", "symbol"]]),
  removeSpacesAfterIfFollowedBy: /\d/,
};

export const dollar: Rule = {
  id: "dollar",
  output: {
    value: "$",
    pronunciation: "a dollar character",
  },
  input: ["Dollar sign"],
  matchers: createMatchers([["dollar"], ["sign", "symbol"]]),
  removeSpacesAfterIfFollowedBy: /\d/,
};

export const pound: Rule = {
  id: "pound",
  output: {
    value: "£",
    pronunciation: "a pound character",
  },
  input: ["Pound sign"],
  matchers: createMatchers([["pound"], ["sign", "symbol"]]),
  removeSpacesAfterIfFollowedBy: /\d/,
};

export const underscore: Rule = {
  id: "underscore",
  output: {
    value: "_",
    pronunciation: "an underscore character",
  },
  input: ["Underscore"],
  matchers: ["underscore"],
  removeSpacesBefore,
  removeSpacesAfter,
};

export const pipe: Rule = {
  id: "pipe",
  output: {
    value: "|",
    pronunciation: "a vertical pipe character",
  },
  input: ["Vertical", "Pipe"],
  matchers: ["vertical", "pipe"],
};

export const point: Rule = {
  id: "point",
  output: {
    value: ".",
    pronunciation: "a point character",
  },
  input: ["Dot", "Point"],
  matchers: ["dot", "point"],
  removeSpacesBefore,
  removeSpacesAfter,
  requireBefore: /\S*\d+/,
};

export const bulletPoint: Rule = {
  id: "bulletPoint",
  output: {
    value: "•",
    pronunciation: "a bullet point character on a new line",
  },
  input: ["Bullet point"],
  matchers: ["bullet point", "bullet points", "bulletpoint", "bulletpoints"],
  capitalize,
  removeSpacesBefore,
  addSpaceAfter,
};

export const rules: Rule[] = [
  fullStop,
  openDoubleQuote,
  closeDoubleQuote,
  openSingleQuote,
  closeSingleQuote,
  apostrophe,
  comma,
  questionMark,
  atSign,
  exclamationMark,
  ampersand,
  nDash,
  mDash,
  hyphen,
  percent,
  asterisk,
  backSlash,
  forwardSlash,
  degree,
  hash,
  numberSign,
  semicolon,
  colon,
  tilde,
  openParenthesis,
  closeParenthesis,
  openBrace,
  closeBrace,
  openBracket,
  closeBracket,
  openAngleBracket,
  closeAngleBracket,
  lessThan,
  greaterThan,
  caret,
  plusMinus,
  plus,
  minus,
  multiplication,
  division,
  equals,
  euro,
  dollar,
  pound,
  underscore,
  pipe,
  point,
  bulletPoint,
];

export const rulesExcludingDot = rules.filter(
  (r) => !r.matchers.includes("dot")
);

const isDefined = (rule: string | undefined): rule is string =>
  !isUndefined(rule);

export const symbolsWithSpaceAfter = rulesExcludingDot
  .filter((rule) => !rule.removeSpacesAfter && !rule.addSpaceAfter)
  .map((rule) => rule.output?.value)
  .filter(isDefined);

export const symbolsWithNoSpaceBefore = rulesExcludingDot
  .filter((rule) => rule.removeSpacesBefore)
  .map((rule) => rule.output?.value)
  .filter(isDefined);

export const symbolsWithNoSpaceAfter = rulesExcludingDot
  .filter((rule) => rule.removeSpacesAfter)
  .map((rule) => rule.output?.value)
  .filter(isDefined);

export const symbolsWithCapitalAfter = rulesExcludingDot
  .filter((rule) => rule.capitalize)
  .map((rule) => rule.output?.value)
  .filter(isDefined);

export const symbolsWithAddSpaceAfter = rulesExcludingDot
  .filter((rule) => rule.addSpaceAfter)
  .map((rule) => rule.output?.value)
  .filter(isDefined);

export const flatPunctuationRules = rules
  .flatMap(({ matchers: words, ...rule }) =>
    words.map((word) => ({ word, ...rule }))
  )
  .filter((rule) => !isUndefined(rule.output));
