import type { SagaIterator, Task } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { uniqueCommandProcessed, voiceCommandComplete } from "./actions";

/**
 * Maximum number of IDs to track for command associations, to prevent memory
 * leaks if the app runs indefinitely.
 */
const maxIdCount = 50;

/**
 * Filter Commands for Uniqueness
 *
 * Responsible for processing every voiceCommandComplete action and determining
 * whether it has been "run from the user's perspective". Results in a single
 * uniqueCommandProcessed action generated for each time the user speaks a
 * command.
 */
export const filterCommandsForUniqueness = function* (): SagaIterator<void> {
  const state: { task: Task | null; completedIds: Set<string> } = {
    task: null,
    completedIds: new Set(),
  };

  yield takeEvery(
    voiceCommandComplete,
    function* ({ payload: { id, ...payload } }) {
      const stringifiedId = `${id.resultId}--${id.commandIndex}`;

      if (state.completedIds.has(stringifiedId)) {
        return;
      }

      state.completedIds.add(stringifiedId);

      // Ensure the set does not grow indefinitely
      if (state.completedIds.size >= maxIdCount) {
        const oldestId = state.completedIds.values().next().value;
        if (oldestId) {
          state.completedIds.delete(oldestId);
        }
      }

      yield put(uniqueCommandProcessed(payload));
    }
  );
};
