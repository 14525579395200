import mixpanel from "mixpanel-browser";

export const setUpMixpanelWithSessionReplay = (
  {
    token,
    recordSessionPercent,
  }: { token: string; recordSessionPercent: number },
  analytics: SegmentAnalytics.AnalyticsJS
) => {
  // Near entry of your product, init Mixpanel
  mixpanel.init(token, {
    // Session Replay settings
    record_sessions_percent: recordSessionPercent,
    record_mask_text_selector: "[data-hj-suppress]",
  });

  /**
   * Copied from:
   * https://docs.mixpanel.com/docs/session-replay/session-replay-web#segment-analyticsjs
   */
  // @ts-ignore not in the types for reasons
  analytics.addSourceMiddleware(({ payload, next }) => {
    if (payload.obj.type === "track" || payload.obj.type === "page") {
      const segmentDeviceId = payload.obj.anonymousId;
      mixpanel.register({
        $device_id: segmentDeviceId,
        distinct_id: segmentDeviceId,
      });

      const sessionReplayProperties =
        mixpanel.get_session_recording_properties();
      payload.obj.properties = {
        ...payload.obj.properties,
        ...sessionReplayProperties,
      };
    }

    if (payload.obj.type === "identify") {
      const userId = payload.obj.userId;
      mixpanel.identify(userId);
    }

    next(payload);
  });
};
