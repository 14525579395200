import type { RootState } from "@talktype/store";
import type { DrawerPage } from "@talktype/types";
import type { DrawerProps } from "@talktype/ui/src/Drawer";
import type { ReactNode } from "react";

import { connect } from "react-redux";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import {
  selectDrawerIsOpen,
  selectDrawerPage,
} from "@talktype/preferences/src/reducers/";
import { Drawer as Component } from "@talktype/ui/src/Drawer";

import { CustomWords } from "./CustomWords";
import { Shortcuts } from "./Shortcuts";
import { VoiceCommands } from "./VoiceCommands";

const drawerPages: Record<DrawerPage, ReactNode> = {
  voiceCommands: <VoiceCommands />,
  shortcuts: <Shortcuts />,
  customWords: <CustomWords />,
};

const mapStateToProps = (state: RootState): DrawerProps => {
  const page = selectDrawerPage(state);

  return {
    border: "left",
    children: drawerPages[page],
    isOpen: selectDrawerIsOpen(state),
    label: "Resource centre",
    selector: createSelectorClassName("drawer", "section"),
    width: "large",
  };
};

export const Drawer = connect(mapStateToProps, null)(Component);
