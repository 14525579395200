import type { EditorType } from "../editor";
import type { EventChannel } from "redux-saga";
import type { Operation } from "slate";

import { eventChannel } from "redux-saga";

import { noOp } from "@carescribe/utilities/src/fp";

export const createEditorFormattingChangeChannel = (
  editor: EditorType
): EventChannel<true> => {
  let {
    children: previousChildren,
    marks: previousMarks,
    selection: previousSelection,
  } = editor;
  const { onChange: originalOnChange } = editor;

  return eventChannel((emit) => {
    editor.onChange = (options?: { operation?: Operation }): void => {
      const { children, marks, selection } = editor;

      const hasChanged =
        previousChildren !== children ||
        previousMarks !== marks ||
        previousSelection !== selection;

      if (hasChanged) {
        emit(true);
        previousChildren = children;
        previousMarks = marks;
        previousSelection = selection;
      }

      originalOnChange(options);
    };

    return noOp;
  });
};
