import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { takeEvery, call, put } from "redux-saga/effects";

import {
  notFoundUserMedia,
  requestInputDevices,
  requestedInputDevices,
} from "./actions";
import { filterDevices, getUserDevices, getUserMedia } from "./utils";
import { setInputDevices, setPermissionState } from "../reducer";

const createDevicesChangedEventChannel = (): EventChannel<Event> =>
  eventChannel((emit) => {
    const controller = new AbortController();

    navigator.mediaDevices.addEventListener("devicechange", emit, controller);

    return () => controller.abort();
  });

const refreshDevices = function* (): SagaIterator<void> {
  const mediaDevices: SagaReturnType<typeof getUserDevices> = yield call(
    getUserDevices
  );

  const filteredDevices: SagaReturnType<typeof filterDevices> = yield call(
    filterDevices,
    mediaDevices
  );

  yield put(setInputDevices(filteredDevices));
};

const requestAndRefreshDevices = function* (): SagaIterator<void> {
  const { stream, error }: SagaReturnType<typeof getUserMedia> = yield call(
    getUserMedia,
    null
  );

  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
  } else {
    yield put(setPermissionState("denied"));
    if (error.name === "NotFoundError") {
      yield put(notFoundUserMedia());
    }
  }

  yield call(refreshDevices);

  yield put(requestedInputDevices());
};

/**
 * Keeps the list of input devices up to date.
 *
 * These are refreshed whenever:
 * - The user grants or revokes microphone permissions
 * - Devices are connected or disconnected
 */
export const setUpInputDevices = function* (): SagaIterator<void> {
  const devicesChangedEventChannel: SagaReturnType<
    typeof createDevicesChangedEventChannel
  > = yield call(createDevicesChangedEventChannel);

  yield takeEvery(requestInputDevices, requestAndRefreshDevices);
  yield takeEvery(devicesChangedEventChannel, refreshDevices);
  yield takeEvery(setPermissionState, refreshDevices);
};
