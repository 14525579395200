import type { Me } from "@talktype/user/src/types/Me";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { createSelector } from "@reduxjs/toolkit";
import { all, select } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { selectMe } from "@talktype/user/src/reducer/selectors/selectMe";

/*
 * This selector should *only* be used inside this saga
 * Should use selectors on the features reducer internally
 */
const selectUserReleaseFlags = createSelector(
  [selectMe],
  (me: Me | null): string[] | null => {
    return me?.releaseToggles ?? null;
  }
);

/* eslint-disable @typescript-eslint/no-unused-vars */
const isReleased = function* (flag: string): SagaIterator<boolean> {
  const flags: SagaReturnType<typeof selectUserReleaseFlags> = yield select(
    selectUserReleaseFlags
  );

  return flags ? flags.includes(flag) : false;
};
/* eslint-enable @typescript-eslint/no-unused-vars */

export const setUpReleaseFlags = function* (): SagaIterator<void> {
  yield all([].map(retry));
};
