import type { LibraryRowProps } from "./LibraryRow";
import type { UUID } from "@carescribe/types";
import type { PreviewableDocument } from "@talktype/editor/src/types";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./library.module.scss";
import { LibraryRow } from "./LibraryRow";

export type LibraryProps = {
  header: ReactNode;
  documents: PreviewableDocument[];
  currentDocumentId: UUID | null;
  onChangeDocument: LibraryRowProps["onChangeDocument"];
  onDeleteDocument: LibraryRowProps["onDeleteDocument"];
};

export const Library = ({
  header,
  documents,
  currentDocumentId,
  onChangeDocument,
  onDeleteDocument,
}: LibraryProps): ReactElement => (
  <>
    {header}
    <fieldset className={styles.container}>
      <ul
        className={classNames(
          createSelectorClassName("library-documents", "section"),
          styles.list
        )}
      >
        {documents.map((document) => (
          <LibraryRow
            key={document.id}
            id={document.id}
            title={document.title}
            preview={document.preview}
            highlight={document.id === currentDocumentId}
            onChangeDocument={onChangeDocument}
            onDeleteDocument={onDeleteDocument}
          />
        ))}
      </ul>
    </fieldset>
  </>
);
