import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, takeEvery, take } from "redux-saga/effects";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";

import { requestTrackEvent } from "@talktype/analytics";
import { editorLoaded } from "@talktype/editor";

import {
  selectDocumentsCount,
  deleteDocument,
  selectCurrentDocumentId,
} from "../../reducers";
import { requestDeleteDocument, requestClearEditorContents } from "../actions";

/**
 * Handles requests to delete a document.
 * - Dispatches a tracking event
 * - If there is only one document, clears the editor contents otherwise
 *   deletes the target document
 * - If the target document is the current document, focuses the editor once
 *   the editor has loaded up a new document
 */
export const setUpDeleteDocument = function* (): SagaIterator<void> {
  yield takeEvery(
    requestDeleteDocument,
    function* ({ payload: { id: targetId, interactionMethod } }) {
      yield put(
        requestTrackEvent({
          name: "Document Deleted",
          data: { interactionMethod },
        })
      );

      const documentsCount: SagaReturnType<typeof selectDocumentsCount> =
        yield select(selectDocumentsCount);

      if (documentsCount <= 1) {
        yield put(requestClearEditorContents());
        return;
      }

      const currentDocumentId: SagaReturnType<typeof selectCurrentDocumentId> =
        yield select(selectCurrentDocumentId);

      yield put(deleteDocument(targetId));

      const isCurrentDocumentDeleted = currentDocumentId === targetId;
      if (!isCurrentDocumentDeleted) {
        return;
      }

      // Must wait for the editor to load up before focusing
      const { payload: editor }: SagaReturnType<typeof editorLoaded> =
        yield take(editorLoaded);

      if (editor) {
        yield call(focusEditor, editor);
      }
    }
  );
};
