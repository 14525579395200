import type { WindowLayout } from "../types/WindowLayout";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-system");

export const pressedWindowControlButton = action<
  "minimise" | "maximise" | "unmaximise" | "close",
  "pressed-window-control-button"
>("pressed-window-control-button");

export const trayAdded = action<void, "tray-added">("tray-added");

export const requestOpenHelp = action<void, "request-open-help">(
  "request-open-help"
);

export const requestFocusTalkType = action<void, "request-focus-talktype">(
  "request-focus-talktype"
);

export const requestQuitTalkType = action<void, "request-quit-talktype">(
  "request-quit-talktype"
);

export const requestLoadWindowLayout = action<
  WindowLayout["id"],
  "request-load-window-layout"
>("request-load-window-layout");

export const pauseTransitions = action<void, "pause-transitions">(
  "pause-transitions"
);

export const resumeTransitions = action<void, "resume-transitions">(
  "resume-transitions"
);

export const layoutChanged = action<void, "layout-changed">("layout-changed");

export const requestOpenMicrophonePrivacySettings = action<
  void,
  "request-open-microphone-privacy-settings"
>("request-open-microphone-privacy-settings");
