import { createGuard, P } from "@carescribe/utilities/src/guards/createGuard";

import { LICENCE_KEY_MIGRATION_STATUS } from "../config/web";

export type LicenceKeyRedemptionStatusResponse = {
  [LICENCE_KEY_MIGRATION_STATUS]: "unredeemed" | "redeemed" | "unknown";
};

export const validLicenceKeyRedemptionStatusResponse = {
  [LICENCE_KEY_MIGRATION_STATUS]: P.union("unredeemed", "redeemed", "unknown"),
};

export const isLicenceKeyRedemptionStatusResponse =
  createGuard<LicenceKeyRedemptionStatusResponse>(
    validLicenceKeyRedemptionStatusResponse
  );
